import React from 'react';
import * as MUI from '@material-ui/core';
import * as MIcon from '@material-ui/icons';
import { DropzoneDialog } from 'material-ui-dropzone';
import PrettyBytes from 'react-pretty-bytes';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../FileViewMain';
import { uploadFile, addPageData } from '../../../api/v1Api';
import {
  postUploadSummarySelector,
  setPostUploadSummary,
  resetCurrentSession,
  getPageData,
  addNewPageDataToCurrentSectionData,
  sessionSelector
} from '../fileSlice';
import { getThumbnail } from 'src/libs/files';
import AutomaticThumbnail from './AutomaticThumbnail';
import PreviousImageList from './PreviousImageList';
import { convertToDateFromat, materialDateInput } from './helper';

export default function FileUploader({ fileType, page, section, callback }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [incomplete, setIncomplete] = React.useState(true);
  const [file, setfile] = React.useState(null);
  const [autoAssignThumbnail, setautoAssignThumbnail] = React.useState(null);

  const postUploadSummary = useSelector(postUploadSummarySelector);
  const thisUploadSession = useSelector(sessionSelector);

  const handleFileSave = (files) => {
    setfile(files[0]);
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const response = {};

    const progressListener = (e) => {
      setProgress(Math.round((e.loaded * 100) / e.total));
    };

    uploadFile({ file: file, tag: { key: fileType.name } }, progressListener)
      .then((res) => {
        let link = res.body;
        let attribs = {};
        setautoAssignThumbnail(link);
        response.link = {
          absolute: link
        };

        data.forEach((attrib, key) => {
          if (key === "date") {
            attribs[key] = convertToDateFromat(attrib);
          } else {
            attribs[key] = attrib;
          }
        });

        var newPageData = {
          pageID: page.id,
          type: fileType.id,
          links: [
            {
              link: link,
              section: section.id,
              linkText: 'Download',
              // thumbnail: getThumbnail(file, link),
              thumbnail: thisUploadSession.thumbnail
                ? thisUploadSession.thumbnail
                : getThumbnail(file, null, null, link),
              target: 'not root',
              external: 'true',
              urlType: fileType.name === 'generic' ? 'file' : undefined,
              ...attribs
            }
          ]
        };

        addPageData(newPageData)
          .then((res) => {
            setProgress(0);
            response.message = 'File Successfully Uploaded.';
            dispatch(setPostUploadSummary(response));
            dispatch(getPageData(page.id));
            dispatch(addNewPageDataToCurrentSectionData(newPageData));
            if (callback && callback.call) {
              callback(newPageData);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        setProgress(0);
        dispatch(
          setPostUploadSummary({
            error: e,
            message: e.message || 'File Upload Failed'
          })
        );
      });
  };

  React.useEffect(() => {
    if (fileType && page && file) {
      setIncomplete(false);
    } else {
      setIncomplete(true);
    }
  }, [fileType, file, page]);

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        {fileType &&
          fileType.attributes &&
          fileType.attributes.map((i, k) => {
            if (
              i.name === 'link' ||
              i.name === 'section' ||
              i.name === 'linkText' ||
              i.name === 'thumbnail' ||
              i.name === 'target' ||
              i.name === 'external' ||
              i.name === 'urlType'
            ) {
              return;
            } else {
              if (i.name === 'date') {
                return (
                  <MUI.TextField
                    id="date"
                    label={
                      i.name.replace(/\b(\w)/g, (s) => s.toUpperCase()) || ''
                    }
                    name={i.name}
                    key={`fattrib-${i.id}-${i.name}`}
                    type="date"
                    defaultValue={materialDateInput()}
                    className={classes.attributeInputs}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ inputProps: { max: `${materialDateInput()}` } }}
                    required
                  />
                );
              } else {
                return (
                  <MUI.TextField
                    className={classes.attributeInputs}
                    name={i.name}
                    key={`fattrib-${i.id}-${i.name}`}
                    label={
                      i.name.replace(/\b(\w)/g, (s) => s.toUpperCase()) || ''
                    }
                    required
                  />
                );
              }
            }
          })}
        <MUI.Button
          className={classes.selectFileButton}
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
          disabled={!!file}
        >
          Select File
        </MUI.Button>

        {fileType && file && !!!postUploadSummary && (
          <MUI.Card variant="outlined" className={classes.filePreviewCard}>
            <MUI.CardHeader title={'Pre Upload Summary'}></MUI.CardHeader>
            <MUI.CardContent>
              <MUI.Grid container>
                <MUI.Grid md={6}>
                  <p>
                    <b>Type</b> : {fileType.name}
                  </p>
                  <p style={!!!page ? { color: 'red' } : null}>
                    <b>Parent Page</b> :{' '}
                    {page ? page.title : 'Parent Page Required'}
                  </p>
                  <p>
                    <b>Parent Section</b>:{' '}
                    {section && section.label !== '' ? section.label : 'Root'}
                  </p>
                </MUI.Grid>
                <MUI.Grid md={6}>
                  <p>
                    <b>Filename</b> : {file.name}
                  </p>
                  <p>
                    <b>File Format</b> : {file.type}
                  </p>
                  <p>
                    <b>File Size</b>: <PrettyBytes bytes={file.size || 0} />
                  </p>
                </MUI.Grid>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <b>Thumbnail</b>: */}
                  {/* <AutomaticThumbnail
                    file={file}
                    thumbnail={thisUploadSession.thumbnail}
                  /> */}
                  {/* <PreviousImageList /> */}
                  {/* <ImageUpload /> */}
                </p>
              </MUI.Grid>
            </MUI.CardContent>
            <MUI.CardActions>
              <MUI.Button
                variant="outlined"
                size="small"
                onClick={() => setfile(null)}
              >
                Remove File
              </MUI.Button>
              <MUI.Button
                variant="contained"
                color="primary"
                size="small"
                disabled={incomplete}
                type="submit"
              >
                Upload File
              </MUI.Button>
            </MUI.CardActions>
            <MUI.LinearProgress variant="determinate" value={progress} />
          </MUI.Card>
        )}
        {postUploadSummary && (
          <MUI.Card variant="outlined" className={classes.filePreviewCard}>
            <MUI.CardHeader title={'Post Upload Summary'}></MUI.CardHeader>
            <MUI.CardContent>
              {!!postUploadSummary.link && (
                <p>
                  <b>Link to File</b> :
                  {/* <a href={postUploadSummary.link.absolute} target="_blank"> */}
                  {/* <a href={'https://kh1h04ieka.execute-api.us-east-1.amazonaws.com' + '/file/' + postUploadSummary.link.absolute} target="_blank">
                    &nbsp; {postUploadSummary.link.absolute}
                    <MIcon.Launch fontSize="small" />
                  </a> */}
                  <a href={process.env.REACT_APP_API_BASE + '/file/' + postUploadSummary.link.absolute} target="_blank">
                    &nbsp; {postUploadSummary.link.absolute}
                    <MIcon.Launch fontSize="small" />
                  </a>
                </p>
              )}
              <p
                style={
                  postUploadSummary.error
                    ? { color: 'red' }
                    : { color: 'Green' }
                }
              >
                <b>Message</b> : {postUploadSummary.message}{' '}
              </p>
            </MUI.CardContent>
            <MUI.CardActions>
              <MUI.Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setfile(null);
                  dispatch(resetCurrentSession());
                }}
              >
                Upload Another File
              </MUI.Button>
            </MUI.CardActions>
          </MUI.Card>
        )}
      </form>

      <DropzoneDialog
        open={open}
        onClose={() => setOpen(false)}
        filesLimit={1}
        maxFileSize={1000000000}
        onSave={handleFileSave}
        showPreviews={true}
      />
    </>
  );
}
