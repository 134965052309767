import React from 'react';
import { makeStyles, Paper, Grid } from "@material-ui/core";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme=>({
    paper: {
        padding:theme.spacing(2),
    }
}))

export default function PostProperties({
    createdBy,createdOn,updatedBy,updatedOn,publishedBy,publishedOn,status
}){
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Grid item>
                <Typography variant="overline">Created By:</Typography>
                <Typography variant="caption">&nbsp; {createdBy?createdBy:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline">Created On:</Typography>
                <Typography variant="caption">&nbsp; {createdOn?createdOn:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline">Last Updated By:</Typography>
                <Typography variant="caption">&nbsp; {updatedBy?updatedBy:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline">Last Updated On:</Typography>
                <Typography variant="caption">&nbsp; {updatedOn?updatedOn:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline">Post State:</Typography>
                <Typography variant="caption">&nbsp; {status?"PUBLISHED":'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline">Published By:</Typography>
                <Typography variant="caption">&nbsp; {publishedBy?publishedBy:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline">Published On:</Typography>
                <Typography variant="caption">&nbsp; {publishedOn?publishedOn:'N/A'}</Typography>
            </Grid>
        </Paper>
    )
}