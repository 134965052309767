import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useHistory } from 'react-router';
import api from '../../api/posts-api';
import AlertBox from 'src/components/common/AlertBox';
import { Button } from '@material-ui/core';
import { ButtonGroup } from '@material-ui/core';
import ProjectMapFeaturedPhoto from './components/projectMapFeaturedPhoto';
import { getMaxError } from 'src/wrappers/dev/helper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function ProjectMapEditorView() {
    const history = useHistory();
    const [editMode, setMode] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");
    const [severity, setSeverity] = useState("success");
    let currentDate = new Date().toJSON().slice(0, 10);
    const [_phyProgress, setPhyProgress] = useState(0);
    const [projectMapState, setProjectMapState] = useState({
        district: 'Ampara',
        tankName: '',
        locationName: '',
        iwwComponent: '',
        agency: '',
        projectValue: '',
        contractPeriod: '',
        intendDateOfCompletion: '',
        description: '',
        projectStatus: 'Initial Stage',
        lastUpdatedDate: currentDate,
        phyProgress: '',
        projectMapDetailsList: {
            image_name: '',
            image_path: ''
        }
    });

    const districtOptions = [
        { value: "Ampara", label: "Ampara" },
        { value: "Anuradhapura", label: "Anuradhapura" },
        { value: "Badulla", label: "Badulla" },
        { value: "Batticaloa", label: "Batticaloa" },
        { value: "Colombo", label: "Colombo" },
        { value: "Galle", label: "Galle" },
        { value: "Gampaha", label: "Gampaha" },
        { value: "Hambantota", label: "Hambantota" },
        { value: "Jaffna", label: "Jaffna" },
        { value: "Kalutara", label: "Kalutara" },
        { value: "Kandy", label: "Kandy" },
        { value: "Kegalle", label: "Kegalle" },
        { value: "Kilinochchi", label: "Kilinochchi" },
        { value: "Kurunegala", label: "Kurunegala" },
        { value: "Mannar", label: "Mannar" },
        { value: "Matale", label: "Matale" },
        { value: "Matara", label: "Matara" },
        { value: "Moneragala", label: "Moneragala" },
        { value: "Mullaitivu", label: "Mullaitivu" },
        { value: "NuwaraEliya", label: "NuwaraEliya" },
        { value: "Polonnaruwa", label: "Polonnaruwa" },
        { value: "Puttalam", label: "Puttalam" },
        { value: "Rathnapura", label: "Rathnapura" },
        { value: "Trincomalee", label: "Trincomalee" },
        { value: "Vavuniya", label: "Vavuniya" }
    ];

    const projectStatusOptions = [
        { value: "Initial Stage", label: "Initial Stage" },
        { value: "Construction in progress", label: "Construction in progress" },
        { value: "Procurement Stage", label: "Procurement Stage" },
        { value: "In Progress", label: "In Progress" },
        { value: "Completed", label: "Completed" },
        { value: "Terminated", label: "Terminated" }
    ];

    useEffect(() => {
        if (history.location.pathname === "/project-map/edit") {
            setMode(true);
            getProjectMaps(window.localStorage.getItem("ProjectMap-ID"));
        }
    }, []);

    const getProjectMaps = (id) => {
        api.getProjectMapById(id)
            .then(res => {
                if (res) {
                    let [day, month, year] = [];
                    let intendCompletionDate = '';
                    if (res.intendDateOfCompletion.includes(".")) {
                        [day, month, year] = res.intendDateOfCompletion.split('.');
                        intendCompletionDate = `${year}-${month}-${day}`;
                    }
                    else {
                        intendCompletionDate = res.intendDateOfCompletion;
                    }
                    setProjectMapState({
                        district: res.district,
                        tankName: res.tankName,
                        locationName: res.locationName,
                        iwwComponent: res.iwwComponent,
                        agency: res.agency,
                        projectValue: res.projectValue,
                        contractPeriod: res.contractPeriod,
                        intendDateOfCompletion: intendCompletionDate,
                        description: res.description,
                        projectStatus: res.projectStatus,
                        lastUpdatedDate: res.lastUpdatedDate,
                        phyProgress: res.phyProgress.split("%")[0],
                        projectMapDetailsList: {
                            image_name: res.image_name,
                            image_path: res.image_path
                        }
                    })
                } else {
                    history.push('/404');
                }
            })
            .catch(console.error)
    };

    const handleChangeDistrict = (value) => {
        setProjectMapState((state) => ({
            ...state,
            district: value
        }));
    };

    const handleChangeTankName = (value) => {
        setProjectMapState((state) => ({
            ...state,
            tankName: value
        }));
    };

    const handleChangeLocationName = (value) => {
        setProjectMapState((state) => ({
            ...state,
            locationName: value
        }));
    };

    const handleChangeIwwComponent = (value) => {
        setProjectMapState((state) => ({
            ...state,
            iwwComponent: value
        }));
    };

    const handleChangeAgency = (value) => {
        setProjectMapState((state) => ({
            ...state,
            agency: value
        }));
    };

    const handleChangeProjectValue = (value) => {
        setProjectMapState((state) => ({
            ...state,
            projectValue: value
        }));
    };

    const handleChangeContractPeriod = (value) => {
        setProjectMapState((state) => ({
            ...state,
            contractPeriod: value
        }));
    };

    const handleChangeIntendDateofCompletion = (value) => {
        setProjectMapState((state) => ({
            ...state,
            intendDateOfCompletion: value
        }));
    };

    const handleChangeDescription = (value) => {
        setProjectMapState((state) => ({
            ...state,
            description: value
        }));
    };

    const handleChangeProjectStatus = (value) => {
        setProjectMapState((state) => ({
            ...state,
            projectStatus: value
        }));
    };

    const handleChangePhyProgress = (value) => {
        setProjectMapState((state) => ({
            ...state,
            phyProgress: value
        }));
        setPhyProgress(value);
    };

    const handleSave = async () => {
        if (editMode) {
            const data = {
                projectMapId: window.localStorage.getItem("ProjectMap-ID"),
                district: projectMapState.district ? projectMapState.district : '',
                tankName: projectMapState.tankName ? projectMapState.tankName : '',
                locationName: projectMapState.locationName ? projectMapState.locationName : '',
                iwwComponent: projectMapState.iwwComponent ? projectMapState.iwwComponent : 0,
                agency: projectMapState.agency ? projectMapState.agency : '',
                projectValue: projectMapState.projectValue ? projectMapState.projectValue : 0,
                contractPeriod: projectMapState.contractPeriod ? projectMapState.contractPeriod : '',
                intendDateOfCompletion: projectMapState.intendDateOfCompletion ? projectMapState.intendDateOfCompletion : '',
                description: projectMapState.description ? projectMapState.description : '',
                projectStatus: projectMapState.projectStatus ? projectMapState.projectStatus : '',
                lastUpdatedDate: projectMapState.lastUpdatedDate,
                phyProgress: projectMapState.phyProgress ? projectMapState.phyProgress+"%" : ''
            };
            let saveRes = await api.updateProjectMap(data);
            if (saveRes === 'Successfully updated') {
                setAlertOpen(true);
                setMessage("Project Map has been Updated");
                setSeverity("success");
                history.push('/project-map');
                window.localStorage.removeItem("ProjectMap-ID");
            }
        }
        else {
            try {
                const data = {
                    district: projectMapState.district ? projectMapState.district : '',
                    tankName: projectMapState.tankName ? projectMapState.tankName : '',
                    locationName: projectMapState.locationName ? projectMapState.locationName : '',
                    iwwComponent: projectMapState.iwwComponent ? projectMapState.iwwComponent : 0,
                    agency: projectMapState.agency ? projectMapState.agency : '',
                    projectValue: projectMapState.projectValue ? projectMapState.projectValue : 0,
                    contractPeriod: projectMapState.contractPeriod ? projectMapState.contractPeriod : '',
                    intendDateOfCompletion: projectMapState.intendDateOfCompletion ? projectMapState.intendDateOfCompletion : '',
                    description: projectMapState.description ? projectMapState.description : '',
                    projectStatus: projectMapState.projectStatus ? projectMapState.projectStatus : '',
                    lastUpdatedDate: projectMapState.lastUpdatedDate,
                    phyProgress: projectMapState.phyProgress ? projectMapState.phyProgress+"%" : '',
                    projectMapDetailsList: [{
                        image_name: projectMapState.projectMapDetailsList.image_name,
                        image_path: projectMapState.projectMapDetailsList.image_path
                    }]
                };
                let saveRes = await api.createProjectMap(data);
                if (saveRes === 'Successfully added') {
                    setAlertOpen(true);
                    setMessage("Project Map has been Added");
                    setSeverity("success");
                    history.push('/project-map');
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    };

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    return (
        <Grid container direction="column" spacing={2}>
            <AlertBox
                open={alertOpen}
                message={message}
                handleClose={handleCloseAlert}
                duration={3000}
                severity={severity}
            />
            <Grid item container spacing={3}>
                <Grid item md={9}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder='Select District'
                        fullWidth
                        value={projectMapState.district}
                        onChange={(e) => handleChangeDistrict(e.target.value)}
                    >
                        {districtOptions.map((states) => (
                            <MenuItem key={states.value} value={states.value}>
                                {states.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        placeholder="Tank Name"
                        fullWidth
                        onChange={(e) => handleChangeTankName(e.target.value)}
                        value={projectMapState.tankName}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Location Name"
                        fullWidth
                        onChange={(e) => handleChangeLocationName(e.target.value)}
                        value={projectMapState.locationName}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="IWWRMP Component"
                        type="number"
                        fullWidth
                        onChange={(e) => handleChangeIwwComponent(e.target.value)}
                        value={projectMapState.iwwComponent}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Agency"
                        fullWidth
                        onChange={(e) => handleChangeAgency(e.target.value)}
                        value={projectMapState.agency}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Project Value"
                        type="number"
                        fullWidth
                        onChange={(e) => handleChangeProjectValue(e.target.value)}
                        value={projectMapState.projectValue}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Contract Period"
                        fullWidth
                        onChange={(e) => handleChangeContractPeriod(e.target.value)}
                        value={projectMapState.contractPeriod}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Intend Date Of Completion"
                        type="date"
                        fullWidth
                        inputProps={{ min: currentDate }}
                        onChange={(e) => handleChangeIntendDateofCompletion(e.target.value)}
                        value={projectMapState.intendDateOfCompletion}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Description"
                        fullWidth
                        onChange={(e) => handleChangeDescription(e.target.value)}
                        value={projectMapState.description}
                        style={{ paddingTop: '20px' }}
                    />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder='Select Project Status'
                        fullWidth
                        value={projectMapState.projectStatus}
                        onChange={(e) => handleChangeProjectStatus(e.target.value)}
                        style={{ paddingTop: '20px' }}
                    >
                        {projectStatusOptions.map((states) => (
                            <MenuItem key={states.value} value={states.value}>
                                {states.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        disabled={true}
                        placeholder="Last Updated Date"
                        type="date"
                        fullWidth
                        value={projectMapState.lastUpdatedDate}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Physical Progress"
                        type="number"
                        fullWidth
                        inputProps={{ maxLength: 100 }}
                        error={_phyProgress === '' || _phyProgress > 100}
                        helperText={_phyProgress === '' ? 'Empty Physical Progress!' : _phyProgress > 100? getMaxError() :' '}
                        onChange={(e) => handleChangePhyProgress(e.target.value)}
                        value={projectMapState.phyProgress}
                        style={{ paddingTop: '20px' }}
                    />
                </Grid>
                {/* {editMode === false && (
                    <Grid container item direction={"row"} md={12}>
                        <Grid item>
                            <ProjectMapFeaturedPhoto
                                url={projectMapState.image_path ? process.env.REACT_APP_API_BASE + '/file/' + projectMapState.image_path : null}
                                setProjectMapState={setProjectMapState}
                            />
                        </Grid>
                    </Grid>
                )} */}
                <Grid item md={3}>
                    <ButtonGroup
                        fullWidth
                        variant="contained"
                        color="primary"
                        aria-label="contained primary button group"
                    >
                        <Button onClick={handleSave}>{editMode ? 'Update' : 'Save'}</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Grid>
    );
};