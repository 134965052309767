import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AlertTemplate from 'react-alert-template-basic';
import {
  positions,
  Provider as AlertProvider,
} from 'react-alert';

import './styles.css';
import App from './components/App';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persister } from './store';
import api from './api';

const rootElement = document.getElementById('root');

store.subscribe(() => {
  const state = store.getState();
  const tokens = {
    token: state.auth.token,
    refresh: state.auth.refresh
  }
  api.setTokens(tokens);
})
const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persister}>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>,
  ,
  rootElement
);
