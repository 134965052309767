import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  uploadFile,
  getLinkTypesWithAttribs,
  getPagesByType,
  getPageDataByID,
  deletePageDataByID
} from '../../api/v1Api';

const initState = {
  fileTypes: [],
  pages: [],
  sections: [],
  pageData: [],
  thumbnails: null,
  thisUploadSession: {
    fileType: null,
    file: null,
    page: null,
    section: null,
    sectionData: null,
    fileAttribValues: {},
    postUploadSummary: null,
    thumbnail: null
  },
  loading: false,
  error: null
};

export const getFileTypes = createAsyncThunk('file/getFileTypes', async () => {
  try {
    return await getLinkTypesWithAttribs();
  } catch (e) {
    const message = e.message ? e.message : 'Error!';
    return message;
  }
});

export const getPagesByFileType = createAsyncThunk(
  'file/getPagesByFileType',
  async (type) => {
    try {
      return await getPagesByType(type);
    } catch (e) {
      const message = e.message ? e.message : 'Error!';
      return message;
    }
  }
);

export const getPageData = createAsyncThunk('file/getPageData', async (id) => {
  try {
    return await getPageDataByID(id);
  } catch (e) {
    const message = e.message ? e.message : 'Error!';
    return message;
  }
});

export const deletePageData = createAsyncThunk(
  'file/deletePageData',
  async (id, { dispatch }) => {
    try {
      dispatch(removePageDataFromCurrentSectionData(id));
      return await deletePageDataByID(id);
    } catch (e) {
      const message = e.message ? e.message : 'Error!';
      return message;
    }
  }
);

export const getSiteFileImages = createAsyncThunk(
  'file/getThumbnails',
  async (id,{dispatch}) => {
    try {
      return dispatch(getPageData(id))
    } catch (e) {
      const message = e.message ? e.message : 'Error!';
      return message;
    }
  }
);

function compare(a, b) {
  return a.label < b.label ? 1 : a.label > b.label ? -1 : 0;
}

const fileSlice = createSlice({
  name: 'file',
  initialState: initState,
  reducers: {
    setCurrentType(state, { payload }) {
      state.thisUploadSession.fileType = payload;
    },
    setCurrentFile(state, { payload }) {
      state.thisUploadSession.file = payload;
    },
    setCurrentPage(state, { payload }) {
      state.thisUploadSession.page = payload;
      state.sections = payload ? payload.sections.slice().sort(compare) : null;
    },
    setCurrentSection(state, { payload }) {
      state.thisUploadSession.section = payload;
    },
    setCurrentSectionData(state, { payload }) {
      state.thisUploadSession.sectionData = payload;
    },
    setPostUploadSummary(state, { payload }) {
      state.thisUploadSession.postUploadSummary = payload;
    },
    resetCurrentSession(state) {
      state.thisUploadSession = {
        fileType: null,
        file: null,
        page: null,
        section: null,
        sectionData: null,
        fileAttribValues: {},
        postUploadReponse: null,
        thumbnail: null
      };
    },
    addNewPageDataToCurrentSectionData(state, { payload }) {
      const temp = [
        payload['links'][0],
        ...current(state.thisUploadSession.sectionData)
      ];
      state.thisUploadSession.sectionData = temp;
    },
    removePageDataFromCurrentSectionData(state, { payload }) {
      const temp = [...current(state.thisUploadSession.sectionData)];
      const newArray = temp.filter((pd) => pd.linkID != payload);
      state.thisUploadSession.sectionData = newArray;
    },
    setCurrentThumbnail(state, { payload }) {
      state.thisUploadSession.thumbnail = payload;
    }
  },
  extraReducers: {
    [getFileTypes.pending]: (state) => {
      state.loading = true;
    },
    [getFileTypes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.fileTypes = payload.body;
    },
    [getFileTypes.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload || [];
    },
    [getPagesByFileType.pending]: (state) => {
      state.loading = true;
    },
    [getPagesByFileType.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pages = payload || [];
    },
    [getPagesByFileType.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getPageData.pending]: (state) => {
      state.loading = true;
    },
    [getPageData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.pageData = payload || [];
    },
    [getPageData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [deletePageData.pending]: (state) => {
      state.loading = true;
    },
    [deletePageData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
    },
    [deletePageData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getSiteFileImages.pending]: (state) => {
      state.loading = true;
    },
    [getSiteFileImages.fulfilled]: (state, {payload}) => {
      state.loading = false;
      state.thumbnails = payload['payload'] || [];
      state.error = null;
    },
    [getSiteFileImages.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    }
  }
});

// actions
export const {
  setCurrentType,
  setCurrentFile,
  setCurrentPage,
  setCurrentSection,
  setCurrentSectionData,
  setPostUploadSummary,
  setCurrentThumbnail,
  resetCurrentSession,
  addNewPageDataToCurrentSectionData,
  removePageDataFromCurrentSectionData
} = fileSlice.actions;

// selectors
export const typeSelector = (store) => {
  return store.files.fileTypes;
};
export const errorSelector = (store) => {
  return store.files.error;
};

export const pageSelector = (store) => {
  return store.files.pages;
};
export const sectionSelector = (store) => {
  return store.files.sections;
};
export const pageDataSelector = (store) => {
  return store.files.pageData;
};

export const postUploadSummarySelector = (store) => {
  return store.files.thisUploadSession.postUploadSummary;
};
export const thumbnailSelector = (store) => {
  return store.files.thumbnails;
};

// current upload session selector
export const sessionSelector = (store) => {
  return store.files.thisUploadSession;
};

export default fileSlice.reducer;
