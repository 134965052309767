import React, { useEffect } from 'react';
import RoleDataTable from './RoleDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { addRole, editRole, getRoles, removeRole, rolesSelector } from './roleSlice';

export default function RoleListView(_props) {

  const roles = useSelector(rolesSelector);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const addItem = (role) => {
    dispatch(addRole(role));
  };

  const removeItem = (id) => {
    dispatch(removeRole(id));
  };

  const editItem = (id, role) => {
    dispatch(editRole({ id, role }));
  };

  return (
    <RoleDataTable items={roles} addItem={addItem} removeItem={removeItem} editItem={editItem}/>
  );

}
