import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import { getThumbnail } from 'src/libs/files';

const AutomaticThumbnail = ({file, thumbnail, currentLink}) => {
  return (
    <Box component="span" style={{ padding:'0px 10px' }}>
      <img
        style={{borderRadius:'5px'}}
        width={25}
        src={getThumbnail(file, thumbnail, currentLink)}
      />
    </Box>
  );
};

export default AutomaticThumbnail;
