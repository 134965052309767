import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { CloudUpload, DashboardSharp, PagesOutlined } from '@material-ui/icons';
import WorkIcon from '@material-ui/icons/Work';
import PeopleIcon from '@material-ui/icons/People';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import MenuBook from '@material-ui/icons/MenuBook';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MapIcon from '@material-ui/icons/Map';

import { useLocation } from 'react-router-dom';
import Header from './Header';
import SideDrawer from './SideDrawer';
import {  PostAdd } from '@material-ui/icons';
import { hasPermission, isLoggedSelector, loggedUserSelector, getLoggedUser } from '../features/auth/authSlice';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

function Dashboard(props) {
  const { window, children, title } = props;
  const classes = useStyles({ drawerWidth });

  const dispatch = useDispatch();
  
  
  // redux selectors
  // const darkMode = useSelector(darkModeSelector);
  const isLogged = useSelector(isLoggedSelector);

  const [mobileOpen, setMobileOpen] = useState(false);

  const hasUserViewPermission = useSelector(hasPermission('USER_READ'));
  const hasApprovalViewPermission = useSelector(hasPermission('APPROVAL_READ'));

  const user = useSelector(loggedUserSelector);

  const [hasPermissionUpdateIndicators, setHasPermissionUpdateIndicators] = useState(false);
  const [hasPermissionApproval, setHasPermissionApproval] = useState(false);
  const [hasSuperAdminPermission, setHasSuperAdminPermission] = useState(true);
  const [hasSuperAdminAndAdminPermission, setHasSuperAdminAndAdminPermission] = useState(true);
  const location = useLocation();

   useEffect(() => {
    dispatch(getLoggedUser);
  });

  /**
   * Hide the side navigation fields form other users
   * 
   */
  useEffect(() => {
    if(user){
      
      if(user.role.name === "SUPER_ADMIN"){
        setHasSuperAdminPermission(false);
        setHasSuperAdminAndAdminPermission(false);
      }

      if(user.role.name === "ADMIN"){
        setHasSuperAdminAndAdminPermission(false);
      }

    }
  }, [user]);


  /**
   * The links in the dashboard left drawer
   * @type Array
   */
  const sidebarItems = [
    {
      items: [
        {
          text: 'Dashboard',
          icon: <DashboardSharp />,
          to: '/dashboard'
        },
        {
          hide: `${hasSuperAdminPermission}`,
          text: 'Audit',
          icon: <MenuBook />,
          to: '/audit'
        },
        // {
        //   text: 'Roles',
        //   icon: <WorkIcon />,
        //   to: '/roles'
        // },
        {
          hide: `${hasSuperAdminAndAdminPermission}`,
          text: 'Indicators',
          icon: <MonetizationOnIcon/>,
          to: '/ecconomic-indicators'
        },
        {
          hide: `${hasSuperAdminPermission}`,
          text: 'Users',
          icon: <PeopleIcon />,
          to: '/users'
        },
        // {
        //   hide: `${hasSuperAdminAndAdminPermission}`,
        //   text: 'Latest News',
        //   icon: <PostAdd/>,
        //   to:'/posts/'
        // },
        {
          text: 'Site Pages',
          icon: <PagesOutlined />,
          to: '/pages'
        },
        {
          text: 'File Upload',
          icon: <CloudUpload />,
          to: '/file'
        },
        // {
        //   text: 'Procurement',
        //   icon: <NoteAddIcon />,
        //   to: '/notices'
        // },
        {
          hide: `${hasSuperAdminAndAdminPermission}`,
          text: 'Hero Slider',
          icon: <AddToPhotosIcon />,
          to: '/sliders'
        },
        {
          hide: `${hasSuperAdminAndAdminPermission}`,
          text: 'Newsline',
          icon: <LibraryBooksIcon />,
          to: '/newsline'
        },
        {
          hide: `${hasSuperAdminAndAdminPermission}`,
          text: 'Chart',
          icon: <EqualizerIcon />,
          to: '/chart'
        },
        {
          hide: `${hasSuperAdminAndAdminPermission}`,
          text: 'Project Map',
          icon: <MapIcon />,
          to: '/project-map'
        }
      ]
    }
  ];

  // const sidebarItems = [ // ** FOR REFERENCE ** //
  //   {
  //     title: 'XXY',
  //     items: [
  //       {
  //         text: 'Dashboard',
  //         icon: <DashboardSharp/>
  //       }
  //     ]
  //   },
  //   {
  //     title: 'XXX',
  //     items: [
  //       {
  //         type: 'collapsable',
  //         text: 'Dashboard',
  //         icon: <DashboardSharp/>,
  //         list: [
  //           {
  //             text: 'Item 1',
  //             icon: <DashboardSharp/>,
  //             to: '/'
  //           }
  //         ]
  //       }
  //     ]
  //   }
  // ];

  /**
   * The links in the right end of the header except for the avatar
   * @type Array
   */
  const headerItems = [
    {
      title: 'Notifications',
      icon: <NotificationsIcon />
      // title: `${(darkMode ? 'Light' : 'Dark')} Mode`,
      // icon: <FormControlLabel control={<Switch />} label="Uncontrolled" />,
      // // count: 0,
      // // component: Link,
      // // to: "/"
      // onClick: () => {
      //   dispatch(setDarkMode(!darkMode));
      //   // setDarkMode(!darkMode)
      // }
    }
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  
  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  return (
    <div className={classes.root}>
      {isLogged ? (
        <Header
          drawerWidth={drawerWidth}
          title={title}
          handleDrawerToggle={handleDrawerToggle}
          headerItems={headerItems}
        />
      ) : null}
      {isLogged ? (
        <SideDrawer
          drawerWidth={drawerWidth}
          window={window}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          itemList={sidebarItems}
        />
      ) : null}


      {isLogged ? <Header drawerWidth={drawerWidth} title={title} handleDrawerToggle={handleDrawerToggle}
                          headerItems={headerItems}/> : null}
      {isLogged ?
        <SideDrawer drawerWidth={drawerWidth} window={window} mobileOpen={mobileOpen}
                    handleDrawerToggle={handleDrawerToggle} itemList={sidebarItems}/> : null}
      <main className={classes.content}>
        {isLogged ? <div className={classes.toolbar} /> : null}
        {children}
      </main>
    </div>
  );
}

export default Dashboard;
