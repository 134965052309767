import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';


/**
 * DrawerItem generates a ListItem from the given props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default (props) => {
  const { to, onClick, icon, text, ...rest } = props;
  let Wrapper;
  if (!!to) {
    Wrapper = ({ children, ...props }) => {
      return (
        <ListItem button component={Link} to={to} {...props}>
          {children}
        </ListItem>
      );
    };
  } else if (!!onClick) {
    Wrapper = ({ children, ...props }) => {
      return (
        <ListItem button onClick={onClick} {...props}>
          {children}
        </ListItem>
      );
    };
  } else {
    Wrapper = ({ children, ...props }) => {
      return (
        <ListItem button {...props}>
          {children}
        </ListItem>
      );
    };
  }
  return (
    <Wrapper {...rest}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={text}/>
    </Wrapper>
  );
};
