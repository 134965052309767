import React, {useState, useEffect} from 'react';
import { 
    makeStyles,
    Paper,
    Typography,
    Button } from '@material-ui/core';
import { CropImage } from '../../../components/common/CropImage';
import api from '../../../api';
import AlertBox from 'src/components/common/AlertBox';

const useStyles = makeStyles(theme=>({
    paper: {
        padding:theme.spacing(2),
    },
    input: {
        display:'none'
    }
}))


export default function HeroSliderFeaturedPhoto({
    url,
    setSliderState,
  }) {
    const classes = useStyles();

    const [openCrop,setOpenCrop] = useState(false); 
    const [cropped,setCropped] = useState(null);
    const [image, setImage] = useState(null);
    const [alertOpen,setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");
    const [severity, setSeverity] = useState("success");
    
    const handleSelectImage = (event) => {
        if(event.target.files&&event.target.files[0]){
            let fileReader = new FileReader();
            
            fileReader.onload = ()=>{
                setImage(fileReader.result);
                setOpenCrop(true);
            }

            fileReader.readAsDataURL(event.target.files[0]);

        }else{
            return;
        }

    }

    useEffect(()=>{
        if(cropped&&cropped.blob){
            let file = new File([cropped.blob],`featured-heroslider-.${cropped.blob.type.split('/')[1]}`,{
                type:cropped.blob.type
            });

            const uploadImg = async () => {
                await api.uploadFile({file,tag:{key:'hero_slider'}})
                .then(res=>{
                    setSliderState(
                       state=>({
                           ...state,
                           featuredImage: res.body       
                        })
                    )
                    setAlertOpen(true)
                    setMessage("Hero Slider has been added")
                    setSeverity("success");
                })
                .catch(console.error)
            }

            uploadImg();
            
        }else{
            return;
        }
    },[cropped])

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    return (
        <>
        {/* Alerts */}
        <AlertBox 
                open={alertOpen}
                message={message}
                handleClose={handleCloseAlert}
                duration={3000}
                severity={severity}
            />
        <Paper className={classes.paper} >
            <Typography>Featured Image</Typography>
            <img src={cropped?cropped.uri:url} width={"100%"} alt={url}/>
            <label htmlFor="featured-image-file">
                <Button variant="contained" color="primary" component="span">
                    Upload
                </Button>
            </label>
            <input 
                className={classes.input}
                id="featured-image-file"
                type="file"
                accept="image/*"
                onChange={handleSelectImage}    
            />
           
        </Paper>
        <CropImage open={openCrop} setOpen={setOpenCrop} image={image} blob={true} setCropped={setCropped}/>
        </>
    )
}