import React, { Component } from 'react';
import { TableRow, TextField, TableCell } from '@material-ui/core';


class TableContent extends Component {
  state = {};

  componentDidMount() {
    const { type, onChange, section } = this.props;
    console.log({ type, onChange, section });
    this.setState({ type, onChange, section });
  }

  render() {
    const renderEditable = ({ onChange, type, section }) => {
      switch (type) {
        case '2':
          return (
            <DynamicTable onChange={onChange} type={type} section={section} />
          );
          break;
        case '1':
          return <DynamicTable onChange={onChange} type={type} section={section} />;
          break;
        case '3':
          return <DynamicTable onChange={onChange} type={type} section={section} />;
          break;
        case '4':
          return <DynamicTable onChange={onChange} type={type} section={section} />;
          break;
        case '5':
          return <DynamicTable onChange={onChange} type={type} section={section} />;
          break;

        default:
          break;
      }
    };

    return <>{renderEditable(this.props)}</>;
  }
}

export default TableContent;

const DynamicTable = ({ onChange, type, section }) => {
  return (
    <TableRow>
      <TableCell>
        <TextField
          size="small"
          variant="outlined"
          onChange={(e) => onChange(section.columns[0].rows[0], e)}
          label={section.columns[0].rows[0].rowValue}
        />
      </TableCell>
    </TableRow>
  );
};
