import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { logout, loggedUserSelector } from '../features/auth/authSlice';
import { Box } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import PersonIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import DrawerList from './drawer/DrawerList';

const useStyles = makeStyles((theme) => ({
  drawer: (props) => ({
    [theme.breakpoints.up('md')]: {
      //width: props.draweWidth; should be added
      width: '120px',
      flexShrink: 0
    }
  }),
  drawerPaper: (props) => ({
    width: props.drawerWidth
  }),
  toolbar: theme.mixins.toolbar,
  brandImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    maxHeight: theme.mixins.toolbar.minHeight
  },
  userInfoHolder: {
    textTransform: 'none',
    borderRadius: '0px'
  },
  profileMenu: {
    width: '100%'
  }
}));

const StyledMenu = withStyles({})((props) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

export default function SideDrawer(props) {

  const { drawerWidth, window, mobileOpen, handleDrawerToggle, itemList } = props;

  const [profileMenuEl, setProfileMenuEl] = useState(null);
  const isProfileMenuOpen = Boolean(profileMenuEl);
  const handleProfileMenuClose = () => {
    setProfileMenuEl(null);
  };
  const handleProfileMenuOpen = (e) => {
    setProfileMenuEl(e.currentTarget);
  };

  const dispatch = useDispatch();

  const user = useSelector(loggedUserSelector);

  const theme = useTheme();

  const classes = useStyles({ drawerWidth });

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const location = useLocation();
  useEffect(() => {
    setProfileMenuEl(null);
  }, [location]);

  const doLogout = () => {
    handleProfileMenuClose();
    dispatch(logout());
  };

  const profileMenuId = 'profile-menu';
  const renderProfileMenu = (
    <StyledMenu
      anchorEl={profileMenuEl}
      id={profileMenuId}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}>
      <StyledMenuItem component={Link} to="/profile">
        <ListItemIcon>
          <PersonIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText primary="Profile"/>
      </StyledMenuItem>
      <StyledMenuItem onClick={doLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText primary="Logout"/>
      </StyledMenuItem>
    </StyledMenu>
  );

  const userInfo = (
    <Button onClick={handleProfileMenuOpen} className={classes.userInfoHolder} variant="text"
            startIcon={<Avatar alt={"user.firstName"} src={"user.avatarLink"}/>} endIcon={<KeyboardArrowDownIcon/>} fullWidth
            disableRipple>
      {`${user.firstName} ${user.lastName}`}
    </Button>
  );

  const drawer = (
    <Box>
      <Box className={classes.toolbar}>
        <img alt="Brand Logo" src="/images/iwwrmp-logo.png"
             className={classes.brandImage}/>
      </Box>
      <Box my={2}>
        {userInfo}
      </Box>
      {itemList.map((item, i) => <DrawerList title={item.title} items={item.items} key={i}/>)}
    </Box>
  );


  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <SwipeableDrawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
      {renderProfileMenu}
    </nav>
  );
}
