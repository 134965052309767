export default function fakeBackend() {
    let fakePosts =  [
        {
            id:'0',
            slug:'',
            createdOn: '2021-02-12',
            createdBy: 'John Doe',
            updatedOn: null,
            updatedBy: null,
            publishedOn: null,
            publishedBy: null,
            status: 0,
            title: 'This is a Sample Post',
            html: '',
            heroSlideId: null,
            featuredImage: null,
            attachments : []
        },
    
        {
            id:'1',
            slug:'',
            createdOn: '2021-02-12',
            createdBy: 'John Doe',
            updatedOn: null,
            updatedBy: null,
            publishedOn: null,
            publishedBy: null,
            status: 0,
            title: 'This is a Sample Post',
            html: '',
            heroSlideId: null,
            featuredImage: null,
            attachments :[]
        },
    
        {
            id:'2',
            slug:'',
            createdOn: '2021-02-12',
            createdBy: 'John Doe',
            updatedOn: null,
            updatedBy: null,
            publishedOn: null,
            publishedBy: null,
            status: 0,
            title: 'This is a Sample Post',
            html: '',
            heroSlideId: null,
            featuredImage: 'https://via.placeholder.com/150',
            attachments : []
        }
    ] 


    const readAll = async () => {
        return new Promise((resolve)=>{
            setTimeout(()=>{
                resolve(fakePosts);
            },1000)
        })
    }

    const readById = async (id) => {
        return new Promise((resolve)=>{
            setTimeout(()=>{
                resolve(
                    fakePosts.filter(post=>{
                        return post.id === id;
                    })[0]
                )
            },1000)
        })
    }

    const save = async (data,id) => {
        let saveId = id?id:String(fakePosts.length);
        fakePosts.push({
            ...data,
            id:saveId
        });
        return new Promise(resolve=>{
            setTimeout(()=>{
                console.log(fakePosts)
                resolve({id: id?id:String(fakePosts.length-1)});
            },1000)
        })
    }

    const deleteItem = (id) => {
        return new Promise(resolve => {
            setTimeout(()=>{
                fakePosts = fakePosts.filter(post=>{
                    return post.id !== id
                });
                resolve({})
            },1000)
        })
    }

    const updateItem = async (id,data) => {
        await deleteItem(id);
        return await save(data,id);
    } 

    return {
        readAll,
        readById,
        save,
        deleteItem,
        updateItem
    }
}
