export const IMAGE_API_BASE = 'http://192.168.250.35:8080';

export const IMAGE_PAGE_ID = 'site-files';
export const IMAGE_SECTION_ID = 'KOUZ71LVRCWSAYTTWNKFW';

export function getImagePageDataTemplate(link,name){
    var newPageData = {
        pageID: IMAGE_PAGE_ID,
        type: 4,
        links: [
          {
            link: link,
            section: IMAGE_SECTION_ID,
            linkText: 'Download',
            thumbnail: '/icons/imageIcon.png',
            target: 'not root',
            external: 'true',
            urlType: 'file',
            title:name
          }
        ]
    };
    return newPageData;
}

export function getThumbnailLink(thumbnails){
    var thumbArray = [];
    if(thumbnails&&thumbnails.length){
        thumbnails.map(thumbnail => {
            const {link,title} = thumbnail;
            const path = IMAGE_API_BASE + '/file/'+link;
            thumbArray.push({id:link,title,path});
        })
    }
    return thumbArray;
}