import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

export default (props) => {
  const { fieldName, label, handleBlur, handleChange, touched, errors, values, dataset, ...rest } = props;

  const classes = useStyles();

  const error = Boolean(touched[fieldName] && errors[fieldName]);

  const lookupTable = dataset.reduce((pV, cV) => {
    pV[cV.value] = cV;
    return pV;
  }, {})

  return (
    <FormControl {...rest} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        error={error}
        name={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        onBlur={handleBlur}
        label={label}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((itemValue) => {
              const item = lookupTable[itemValue];
              return <Chip key={item.value} label={item.name} className={classes.chip}/>
            })}
          </div>
        )}
      >
        {dataset.map((item) =>
          (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{touched[fieldName] && errors[fieldName]}</FormHelperText>
    </FormControl>
  );
};
