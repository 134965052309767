import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from '@material-ui/core';
import { ButtonGroup } from '@material-ui/core';
import { useState } from 'react';
import { getMaxError } from 'src/wrappers/dev/helper';

export default function HTMLEditorContainer({
  title,
  html,
  setPostState,
  handleSave,
  handlePublish,
  published,
  republishable,
  listen
}) {
    const [_title, setTitle] = useState(" Untitled");
  const handleChangeTitle = (value) => {
    setPostState((state) => ({
      ...state,
      title: value
    }));
    setTitle(value)
  };

  const handleChangeContent = (value) => {
    console.log(value);
    setPostState((state) => ({
      ...state,
      html: value
    }));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container spacing={2}>
        <Grid item md={9}>
          <TextField
            inputProps={{ maxLength: 491 }}
            placeholder="Post Title"
            fullWidth
            onChange={(e) => handleChangeTitle(e.target.value)}
            value={title}
            error={_title === '' || _title.length > 490}
            helperText={_title === '' ? 'Empty Title!' : _title.length > 489 ? getMaxError() :' '}
          />
        </Grid>
        <Grid item md={3}>
          <ButtonGroup
            fullWidth
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
          >
            <Button onClick={handleSave}>Save</Button>
            <Button
              onClick={handlePublish}
              disabled={!republishable && published | !listen}
            >
              {published ? 'Republish' : 'Publish'}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid item>
        <Editor
          value={html}
          init={{
            // skin_url: '/skins/lightgrey',
            height: 500,
            menubar: false
          }}
          onEditorChange={handleChangeContent}
        />
      </Grid>
    </Grid>
  );
}
