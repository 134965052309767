import { combineReducers } from 'redux';
import darkModeReducer from '../features/darkMode/darkModeSlice';
import authReducer from '../features/auth/authSlice';
import permissionReducer from '../features/permission/permissionSlice';
import roleReducer from '../features/role/roleSlice';
import userReducer from '../features/user/userSlice';
import profileReducer from '../features/profile/profileSlice';
import approvalSlice from '../features/approval/approvalSlice';
import postsSlice from '../features/posts/postsSlice';
import heroSlidersSlice from '../features/hero-slider/heroSlidersSlice';
import pageSlice from '../features/cms-pages/PageSlice';
import fileSlice from '../features/file-upload/fileSlice';
import noticesSlice from '../features/procurement-notices/noticesSlice'
import pageDataSlice from '../features/cms-pages/page-data/PageDataSlice';
import ecoIndicatorReducer from './../features/economicIndicators/ecoIndicatorSlice';
import newslineSlice from '../features/newsline/newslineSlice';
import chartSlice from '../features/chart/chartSlice';
import projectMapSlice from '../features/project-map/projectMapSlice';

export default combineReducers({
  darkMode: darkModeReducer,
  auth: authReducer,
  permission: permissionReducer,
  role: roleReducer,
  user: userReducer,
  profile: profileReducer,
  approval: approvalSlice,
  post: postsSlice,
  sliders: heroSlidersSlice,
  pages: pageSlice,
  pageData: pageDataSlice,
  files: fileSlice,
  ecoIndicator: ecoIndicatorReducer,
  notice: noticesSlice,
  newsline: newslineSlice,
  chart: chartSlice,
  projectMap: projectMapSlice
});
