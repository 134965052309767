import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "src/api/procurement-notices-api";

const init = {
    loading: false,
    hesError: false,
    notices: []
}

export const getAllNotices = createAsyncThunk(
    'notices/getAllNotices',
    async () => {
        try {
            return await api.getAllNotices()
        } catch (error) {
        
        }
    }
)

const noticesSlice = createSlice({
    name: 'noticesSlice',
    initialState: init,
    extraReducers: {
        [getAllNotices.fulfilled] : (state, {payload}) => {
            state.notices = payload;
        }
    }
})


//Selectors

export const noticesSelector = store => {
    return store.notice.notices
}

export default noticesSlice.reducer;