import React from 'react';
import { useState, useEffect } from 'react';
import {
  Paper,
  makeStyles,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import { convertToDateFromat, materialDateInput } from './helper';
import api from '../../api/audit-api';
import MUIDataTable from 'mui-datatables';

const useStyles = makeStyles((theme) => {
  return {
    paper: {
      padding: '1rem',
      marginBottom: '1rem'
    },
    textField: {
      width: '250px'
    },
    error: {
      color: 'red'
    }
  };
});

export default function NoticeEditorView() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [data, setData] = useState([]);
  const [fromError, setFromError] = useState('');
  const [toError, setToError] = useState('');

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const response = await api.getAuditList();
    setData(response);
    console.log(response);
  };

  const columns = [
    {
      name: 'actionType',
      label: 'Action Type'
    },
    {
      name: 'user',
      label: 'User'
    },
    {
      name: 'dateAndTime',
      label: 'Date And Time'
    },
    {
      name: 'value',
      label: 'Value'
    },
    {
      name: 'parent',
      label: 'Parent'
    }
  ];

  useEffect(() => {
    if(fromError){
      setFromError('');
    }
    if(toError){
      setToError('');
    }
  }, [fromError, toError]);

  const searchData = async () => {
    
    if (fromDate === null && email === '') {
      setFromError('Plase select a date');
    } else {
      const data = {
        email: email,
        fromDate: fromDate,
        toDate: toDate
      };
      const array = [];
      setData([]);
      await api.searchAuditList(data).then((response) => {
        array.push(response);
        setData(response);
      });
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h5">Audit List</Typography>
        <Grid container spacing={3}>
          <Grid item>
            <InputLabel id="label-transaction-id">Email</InputLabel>
            <FormControl>
              <TextField
                id="email"
                label=""
                type="text"
                defaultValue={email}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <InputLabel id="label-transaction-id">From Date</InputLabel>
            <FormControl>
              <TextField
                id="fromDate"
                label=""
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
                InputProps={{ inputProps: { max: `${materialDateInput()}` } }}
              />
              <Typography className={classes.error}>{fromError}</Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <InputLabel id="label-transaction-id">To Date</InputLabel>
            <FormControl>
              <TextField
                id="toDate"
                label=""
                placeholder=""
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                InputProps={{ inputProps: { max: `${materialDateInput()}` } }}
              />
              <Typography className={classes.error}>{toError}</Typography>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!email && !fromDate && !toDate}
              onClick={searchData}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {<MUIDataTable title={'Audit Records'} columns={columns} data={data} />}
    </>
  );
}
