import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React, { Fragment, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import MUIDataTable from 'mui-datatables';
import CustomToolbarSelect from '../../components/common/CustomToolbarSelect';
import RoleEditDialog from './RoleEditDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions, permissionsSelector } from '../permission/permissionSlice';

export default function RoleDataTable(props) {

  const { items, addItem, removeItem, editItem } = props;

  const permissions = useSelector(permissionsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPermissions());
  }, [dispatch]);

  const editedItemTemplate = {
    id: -1,
    name: '',
    permissions: [],
    status: 'INACTIVE'
  };
  
  const [open, setOpen] = useState(false);
  const [editedItem, setEditedItem] = useState(editedItemTemplate);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        searchable: false,
        display: 'excluded'
      }
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        searchable: true
      }
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        searchable: true
      }
    },
    {
      label: 'Created By',
      name: 'createdBy',
      options: {
        searchable: true,
        display: 'excluded'
      }
    },
    {
      label: 'Created At',
      name: 'createdAt',
      options: {
        searchable: true,
        display: 'excluded'
      }
    },
    {
      name: 'permissions',
      options: {
        display: 'excluded'
      }
    }
  ];

  const getItemsByIndex = (index) => {
    return items[index];
  };

  const onEditItem = (index) => {
    const item = getItemsByIndex(index);
    setEditedItem(item);
    setOpen(true);
  };

  const onDeleteItems = (indexes) => {
    indexes.map((index) => (getItemsByIndex(index))).map((item) => {
      removeItem(item.id);
      return null;
    });
  };

  const onSaveItem = async (data) => {
    if (data.id === -1) {
      return addItem(data);
    }
    editItem(data.id, data);
  };

  const options = {
    filterType: 'checkbox',
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      return !(expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0);
    },
    rowsExpanded: [],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const permissions = rowData[5];
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Typography variant='subtitle1'>Permissions</Typography>
            <List>
              {permissions.map((perm, i) => (
                <ListItem key={i}>{perm.name}</ListItem>
              ))}
            </List>
          </TableCell>
        </TableRow>
      );
    },
    download: false,
    print: false,
    customToolbar: () => {
      return (
        <Fragment>
          <Tooltip title={'Add Item'}>
            <IconButton onClick={() => {
              setEditedItem(editedItemTemplate);
              setOpen(true);
            }}>
              <AddIcon/>
            </IconButton>
          </Tooltip>
        </Fragment>
      );
    },

    customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
      return (
        <CustomToolbarSelect selectedRows={selectedRows} setSelectedRows={setSelectedRows}
                             onEditItem={onEditItem} onDeleteItems={onDeleteItems}/>);
    },
    // serverSide: true,
    // onTableInit: (action, tableState) => {
    //   console.log(tableState);
    // },
    // onTableChange: (action, tableState) => {
    //   console.log(tableState);
    //   console.log(action);
    // }
  };

  return (
    <Fragment>
      <RoleEditDialog open={open} handleClose={() => {
        setOpen(false);
      }} handleSubmit={onSaveItem} role={editedItem} permissions={permissions}/>
      <MUIDataTable title={'Roles'} data={items} columns={columns} options={options}/>
    </Fragment>
  );
}
