import {createAsyncThunk,createSlice} from '@reduxjs/toolkit';
import api from '../../api/posts-api'

const init = {
    loading: false,
    hasError: false,
    newsline: []
}

export const getAllNews = createAsyncThunk(
    'newsline/getAllNews',
    async () => {
        try {
            const resp = await api.getAllNews()
            return resp;
        } catch (error) {
        }
    }
)

const newslineSlice = createSlice({
    name:'newslineSlice',
    initialState:init,
    extraReducers:{
        [getAllNews.fulfilled] : (state, {payload}) => {
            state.newsline = payload;
        }
    }
})

// selectors
export const newslineSelector = store => {
    return store.newsline.newsline
}


export default newslineSlice.reducer;