import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Avatar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  }
}))

export default function(props) {
  const { info } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid container item direction="column" justify="center" alignItems="center">
        <Grid item>
          <Avatar src={info.avatarLink} className={classes.avatar}/>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField value={info.firstName} fullWidth label="First Name"
                     InputProps={{ readOnly: true }}/>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField value={info.lastName} fullWidth label="Last Name"
                     InputProps={{ readOnly: true }}/>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField value={info.role.name} fullWidth label="Role" InputProps={{ readOnly: true }}/>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField value={info.mobileNumber} fullWidth label="Mobile Number"
                     InputProps={{ readOnly: true }}/>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextField value={info.email} fullWidth label="Email" InputProps={{ readOnly: true }}/>
        </Grid>
      </Grid>
    </Grid>
  );
}
