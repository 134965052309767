import React, { useEffect, useState } from 'react';
import { makeStyles, RootRef } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  currentPageDataSelector,
  currentPageSelector,
  getPageByID,
  publishPage,
  setCurrentPage,
  setSectionHTML,
  updatePage,
  setSectionOrder,
  getSectionOrder,
  sectionOrderSelector,
  orderedPageDataSelector
} from './PageSlice';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  IconButton,
  Button,
  ButtonGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  ArrowLeftSharp,
  Check,
  ExpandMore,
  Home,
  HomeOutlined,
  InfoOutlined,
  Settings,
  UpdateOutlined
} from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HTMLEditor from 'src/components/md-editor/HTMLEditor';
import PageFileList from './PageFilesList';
import Edit from '@material-ui/icons/Edit';
import PageSectionView from './PageSectionView';
import AlertBox from 'src/components/common/AlertBox';
import { useAlert, positions } from 'react-alert';
import { Backdrop } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import PageMetaView from './PageMetaView';
import PageMetaUpdate from './PageMetaUpdate';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  sections: {
    maxHeight: '60vh',
    overflowY: 'scroll'
  },
  htmlrow: {
    padding: 0
  }
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {})
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const mapItemsToOrder = (items) => {
  return items.map((item, index) => ({
    order_id: index + 1,
    section_id: item.id
  }));
};

export default function PageView() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const loading = useSelector((store) => store.pages.loading);
  const page = useSelector(currentPageSelector);
  const data = useSelector(currentPageDataSelector);
  const orderedSections = useSelector(sectionOrderSelector);
  const orderedData = useSelector(orderedPageDataSelector);

  // Current Page's Sections List (Initialized to an empty array)
  const [sections, setSections] = useState([]);
  // Current Section being Edited (Root being the page)
  const [current, setCurrent] = useState({});
  // Current HTML Value to Display in the Editor
  const [html, setHtml] = useState(current ? current.html : '');
  // Check if there're unsaved changes;
  const [isUnsaved, setIsUnsaved] = useState(false);
  // Page data filtered by Current Section
  const [filteredData, setFilteredData] = useState([]);
  // Check if the page is of a TABLE layout
  const [isTable, setIsTable] = useState(page?.layout == 'TABLE');
  // Opens the Sections Modal
  const [secOpen, setSecOpen] = useState(false);
  // Sets the Current Section to be Edited (DIFFERENT FROM THE CURRENT STATE)
  const [currSection, setCurrSection] = useState(null);
  // Sets condition if a section is being dragged
  const [isDragging, setIsDragging] = useState(false);
  // Localized file list to hold ordered + unordered page data
  const [fileListData, setFileListData] = useState([]);
  // Control the Page Metadata Dialog
  const [showMeta, setShowMeta] = useState(false);
  const [updateMeta, setUpdateMeta] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState('Successful');
  const [severity, setSeverity] = useState('success');
  // const [pageTitle,setPageTitle] = useState(page?page.title:null);
  const [pageTitle, setPageTitle] = useState(page ? page.title : null);

  const alert = useAlert();

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const itemsOld = [...sections];
    const items = reorder(
      sections,
      result.source.index,
      result.destination.index
    );
    setSections(items);
    if (window.confirm('Are you sure you want to change the Section Order?')) {
      dispatch(setSectionOrder({ id, order: mapItemsToOrder(items) }));
    } else {
      setSections(itemsOld);
    }
    setIsDragging(false);
  };

  // Create Sections as a list
  const createSectionList = (list) => {
    if (list && Array.isArray(list)) {
      return list.map((section, k) => {
        return (
          <Draggable
            key={`section-${k}-${section.id}`}
            draggableId={`section-${k}-${section.id}`}
            index={k}
          >
            {(provided, snapshot) => (
              <ListItem
                button
                className={classes.nested}
                selected={section.id === current.id}
                onClick={() =>
                  handleSectionChange({
                    ...section,
                    index: k
                  })
                }
                ContainerProps={{ ref: provided.innerRef }}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <ListItemText>
                  <Typography variant="caption">{section.label}</Typography>
                </ListItemText>
                <ListItemIcon>
                  {section.root && (
                    <Tooltip title="This section will appear before everything">
                      <Check dis color="primary" />
                    </Tooltip>
                  )}
                </ListItemIcon>
                <ListItemSecondaryAction
                  style={isDragging ? { display: 'none' } : {}}
                >
                  <IconButton onClick={() => showSectionEdit(section)}>
                    <Edit />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </Draggable>
        );
      });
    } else return [];
  };

  useEffect(() => {
    setPageTitle(page ? page.title : '');
  }, [page]);

  const goBack = () => {
    if (!isUnsaved) {
      history.goBack();
    } else {
      if ('You have unsaved changes. Are you sure you want to navigate?') {
        history.goBack();
      } else return;
    }
  };

  const handleEditorChange = (html) => {
    setHtml(html);
  };

  const handleSectionChange = (section) => {
    dispatch(
      setSectionHTML({
        id: current.id,
        index: current.index,
        html: html
      })
    );
    setCurrent(section);
  };

  const handlePageSave = () => {
    dispatch(
      setSectionHTML({
        id: current.id,
        index: current.index,
        html: html,
        title: pageTitle
      })
    );

    setAlertOpen(true);
    setMessage('Post has been Updated');
    setSeverity('success');
  };

  const handlePagePublish = () => {
    if (isUnsaved) {
      setAlertOpen(true);
      setMessage('Save your work before publishing');
      setSeverity('error');
    } else {
      dispatch(publishPage(id));
      alert.success('Post has been published!');
      // setAlertOpen(true);
      // setMessage('Post has been published');
      // setSeverity('success');
    }
  };

  const showSectionEdit = (section) => {
    setCurrSection(section);
    setSecOpen(true);
  };

  const handleSectionUpdate = (updatedSections) => {
    dispatch(
      updatePage({
        ...page,
        sections: updatedSections
      })
    );
    dispatch(getPageByID(id));
  };

  // const handleMetaUpdate = () => {
  //   setOpenBreadcrumb(true);
  // }

  useEffect(() => {
    dispatch(getPageByID(id));
  }, []);

  useEffect(() => {
    if (page && page.id === id) {
      setCurrent(current || page);
      setIsTable(page.layout === 'TABLE');
      if (isUnsaved) {
        dispatch(updatePage(page));
        setIsUnsaved(false);
      }
    }
  }, [page]);

  useEffect(() => {
    if (current) {
      setHtml(current.html ? current.html : '');
    }
  }, [current]);

  useEffect(() => {
    setIsUnsaved(true);
  }, [html, pageTitle]);

  useEffect(() => {
    if (!!!page) return;
    if (
      orderedSections &&
      orderedSections.length &&
      page.sections &&
      page.sections instanceof Array && (!!!page.sections.find((d) => d.id === page.id))
    ) {
      if (orderedSections.length === page.sections.length) {
        setSections(orderedSections);
      } else {
        let unordered = page.sections.filter((s) => {
          return !!!orderedSections.find((os) => os.id === s.id);
        });
        setSections([...orderedSections, ...unordered]);
      }
    } else {
      if(page.sections.find((d) => d.id === page.id)){
        setSections([]);  
      } else {
        setSections(page.sections || []);
      }
    }
  }, [page, orderedSections]);

  useEffect(() => {
    if (orderedData && orderedData.length) {
    
      if (orderedData.length === data.length) {
        setFileListData(orderedData);
      } else {
        let unordered = data.filter((d) => {
          return !!!orderedData.find((od) => od.linkID === d.linkID);
        });
        setFileListData([...orderedData, ...unordered]);
      }
    } else {
      setFileListData(data);
    }
  }, [data, orderedData]);

  useEffect(() => {
    if (fileListData instanceof Array && current) {
      if (current.id === id) {
        setFilteredData(
          fileListData.filter((d) => {
            return (
              d.section === 'parent' ||
              d.section === 'root' ||
              d.section === current.id
            );
          })
        );
      } else {
        setFilteredData(
          fileListData.filter((d) => {
            return d.section === current.id;
          })
        );
      }
    } else return;
  }, [current, fileListData]);

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleTitleChange = (value) => {
    setPageTitle(value);
  };

  const handleUpdateMeta = () => {
    setUpdateMeta(true);
  }

  if (!!!page) return null;
  return (
    <>
      {/* Alerts */}
      <AlertBox
        open={alertOpen}
        message={message}
        handleClose={handleCloseAlert}
        duration={3000}
        severity={severity}
      />
      {/* Loading Overlay */}
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container direction="row" alignItems="flex-start" spacing={2}>
        {/* Page Title */}
        <Grid item md={1}>
          <IconButton onClick={goBack}>
            <ArrowLeftSharp />
          </IconButton>
        </Grid>
        <Grid item md={8}>
          <TextField
            value={pageTitle}
            fullWidth
            onChange={(e) => handleTitleChange(e.target.value)}
          />
        </Grid>
        <Grid item md={3}>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={handlePageSave} disabled={!isUnsaved}>
              Save
            </Button>
            <Tooltip title="Once published, all changes saved will immediately be reflected on the public front.">
              <Button
                onClick={handlePagePublish}
                disabled={page ? page.status : false}
              >
                Publish
              </Button>
            </Tooltip>
            <Tooltip title="View Details">
              <IconButton variant="" onClick={() => setShowMeta(true)}>
                <InfoOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Change Breadcrumb">
              <IconButton variant="" onClick={() => handleUpdateMeta()}>
                <UpdateOutlined/>
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </Grid>
        {/* Section Navigation  */}
        <Grid item md={3}>
          <DragDropContext
            onDragEnd={onDragEnd}
            onBeforeCapture={() => setIsDragging(true)}
          >
            <Droppable droppableId="sectionsDroppable">
              {(provided, snapshot) => (
                <RootRef rootRef={provided.innerRef}>
                  <List className={classes.sections}>
                    <ListItem
                      button
                      className={classes.nested}
                      onClick={() => showSectionEdit(null)}
                    >
                      <ListItemText>Add Section</ListItemText>
                    </ListItem>
                    <ListItem
                      button
                      className={classes.nested}
                      selected={current.id === page.id}
                      onClick={() =>
                        handleSectionChange({ parent: true, ...page })
                      }
                    >
                      <ListItemText>
                        <Typography variant="caption">Main</Typography>
                      </ListItemText>
                    </ListItem>
                    {createSectionList(sections, provided, snapshot)}
                    {provided.placeholder}
                  </List>
                </RootRef>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>

        {/* HTML Editor */}
        <Grid item container md={9}>
          {isTable ? (
            <Grid item md={12}>
              <Alert severity="info">
                This Page does not allow changing its HTML content.
              </Alert>
            </Grid>
          ) : (
            <Grid item md={12}>
              <HTMLEditor
                height={300}
                value={html}
                onChange={handleEditorChange}
              />
            </Grid>
          )}

          <Grid item md={12}>
            <Accordion defaultExpanded={isTable}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <strong>{current.label || current.title}</strong> &nbsp;
                Attachments / Files / Links
              </AccordionSummary>
              <AccordionDetails>
                {current.label || current.title ? (
                  <PageFileList
                    files={filteredData}
                    isTable={isTable}
                    page={page}
                    current={current}
                    type={page?.type}
                  />
                ) : (
                  <Alert severity="warning">
                    Please select section before add attachments!
                  </Alert>
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>

      {/* Section Modal */}
      <PageSectionView
        open={secOpen}
        setOpen={setSecOpen}
        setAlertOpen={setAlertOpen}
        section={currSection}
        sections={sections}
        pageId={id}
        onUpdate={handleSectionUpdate}
        files={filteredData}
      />

      {/* Meta Dialog */}
      <PageMetaView open={showMeta} setOpen={setShowMeta} page={page} />

      <PageMetaUpdate  open={updateMeta} setOpen={setUpdateMeta} page={page} />
    </>
  );
}
