import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from '@material-ui/core';
import { ButtonGroup } from '@material-ui/core';
import { getMaxError } from 'src/wrappers/dev/helper';

export default function HTMLEditorContainer({
  title,
  subtitle,
  description,
  setNoticeState,
  handleSave,
  handlePublish,
  published,
  republishable,
  listen
}) {
  const handleChangeTitle = (value) => {
    setNoticeState((state) => ({
      ...state,
      title: value
    }));
  };
  const handleChangeSubtitle = (value) => {
    setNoticeState((state) => ({
      ...state,
      subtitle: value
    }));
  };

  const handleChangeContent = (value) => {
    console.log(value);
    setNoticeState((state) => ({
      ...state,
      description: value
    }));
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container spacing={2}>
        <Grid item md={9}>
          <TextField
            placeholder="Notice Title"
            fullWidth
            onChange={(e) => handleChangeTitle(e.target.value)}
            value={title}
            inputProps={{ maxLength: 256 }}
            error={title === '' || title.length > 255}
            helperText={title === '' ? 'Empty Title!' : title.length > 255? getMaxError() :' '}
            />
          <TextField
            placeholder="Notice Subtitle"
            fullWidth
            onChange={(e) => handleChangeSubtitle(e.target.value)}
            value={subtitle}
            inputProps={{ maxLength: 256 }}
            error={subtitle === '' || subtitle.length > 255}
            helperText={subtitle === '' ? 'Empty Title!' : subtitle.length > 255? getMaxError() :' '}
          />
        </Grid>
        <Grid item md={3}>
          <ButtonGroup
            fullWidth
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
          >
            <Button onClick={handleSave}>Save</Button>
            <Button
              onClick={handlePublish}
              disabled={(!republishable && published) || !listen}
            >
              {published ? 'Republish' : 'Publish'}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Grid item>
        <Editor
          value={description}
          init={{
            // skin_url: '/skins/lightgrey',
            height: 500,
            menubar: false
          }}
          onEditorChange={handleChangeContent}
        />
      </Grid>
    </Grid>
  );
}
