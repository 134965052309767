import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AlertBox from './AlertBox';

export default function AlertDialog({ handleAgree, open, setOpen,content,title }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            cancel
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              handleAgree();
            }}
            color="primary"
            autoFocus
          >
            yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertBox.defaultProps = {
  handleAgree: () => alert('Agree!'),
  handleClose: () => alert('close!')
};
