import {createAsyncThunk,createSlice} from '@reduxjs/toolkit';
import api from '../../api/posts-api';

const init = {
    loading: false,
    hasError: false,
    projectMap: []
}

export const getAllProjectMap = createAsyncThunk(
    '/project-map/get-all',
    async () => {
        try {
            const resp = await api.getAllProjectMap()
            return resp;
        } catch (error) {
        }
    }
)

const projectMapSlice = createSlice({
    name:'projectMapSlice',
    initialState:init,
    extraReducers:{
        [getAllProjectMap.fulfilled] : (state, {payload}) => {
            state.projectMap = payload;
        }
    }
})

// selectors
export const projectMapSelector = store => {
    return store.projectMap.projectMap
}

export default projectMapSlice.reducer;