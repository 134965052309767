import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dark: false
}

const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState,
  reducers: {
    setDarkMode(state, action) {
      state.dark = action.payload;
    }
  }
});

export const { setDarkMode } = darkModeSlice.actions;

export default darkModeSlice.reducer;

export const darkModeSelector = state => state.darkMode.dark;
