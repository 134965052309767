

export const USER_APPROVAL = 'USER';

export const OPERATION_APPROVE = 'approve';
export const OPERATION_REJECT = 'reject';

export const STATUS_APPROVED = 'APPROVED';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_PENDING = 'PENDING';
