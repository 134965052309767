import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';


export default function AlertBox(props) {
  const { message, open, handleClose, duration, severity, vertical, horizontal } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: (vertical ? vertical : 'top'), horizontal: (horizontal ? horizontal : 'center') }}
      open={open}
      autoHideDuration={duration ? duration : null}
      onClose={handleClose}
    >
      {message !== '' ? <Alert elevation={1} variant="filled" onClose={handleClose} severity={severity}>
        {message}
      </Alert> : null}
    </Snackbar>
  );
}
