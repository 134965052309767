import axios from "axios";
import { API_BASE } from '../v1Api';

const API_BASE_PROCUREMENT = API_BASE + "/procurement";

//Fetch All Notices
export async function getAllNotices() {
    try {
        return (await axios.get(API_BASE_PROCUREMENT + "/getAll")).data

    } catch (error) {

    }
};

//Get Procument Notice
export async function getNotice(id) {
    try {
        return (await axios.get(API_BASE_PROCUREMENT + `/${id}`)).data
    } catch (error) {

    }
};

//Post Procument Notice
export async function createNewNotice(data) {
    try {
        return (await axios.post(API_BASE_PROCUREMENT + '/notice', {
            title: data.title,
            subtitle: data.subtitle,
            description: data.description,
            status: false
        })).data
    } catch (error) {

    }
};

//Publish Notice
export async function publishNotice(id) {
    try {
        return (await axios.post(API_BASE_PROCUREMENT + `/notice/${id}/publish`)).data;
    } catch (error) {
        console.log(error);
    }
};


//Post Notice Attachment
export async function addNoticeAttachment(id, data) {
    try {
        return (await axios.post(API_BASE_PROCUREMENT + `/data/${id}`, {
            title: data.title,
            link: data.link,
            thumbnail: data.thumbnail,
            linkText: data.linkText,
            external: data.external
        })).data;
    } catch (error) {
        console.log(error);
    }
};

//Update Procurement Notice
export async function updateNotice(data, id) {
    try {
        return (await axios.put(API_BASE_PROCUREMENT + `/notice/${id}`, {
            title: data.title,
            subtitle: data.subtitle,
            description: data.description,
            status: data.status
        }))
    } catch (error) {
        console.log(error);
    }
};

//Delete Procurement Notice
export async function deleteNotice(id) {
    try {
        return (await axios.delete(API_BASE_PROCUREMENT + `/notice/${id}`)).data;
    } catch (error) {

    }
};

//Delete Procurement Notice Attachment
export async function deleteNoticeAttachment(id) {
    try {
        return (await axios.delete(API_BASE_PROCUREMENT + `/data/${id}`)).data;
    } catch (error) {

    }
};