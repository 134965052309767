import * as apiFake from './v1fake';
import * as apiV1 from './v1';

let api;

if(process.env.REACT_APP_ENV==='development'){
    api = apiFake;
} else if (process.env.REACT_APP_ENV === 'dev'){
    api = apiV1;
} else {
    api = apiV1;
}


export default api;