import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import React, { Fragment, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { OPERATION_APPROVE, OPERATION_REJECT, STATUS_PENDING, USER_APPROVAL } from './constants';
import UserApprovalHolder from './UserApprovalHolder';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import RemarkDialog from './RemarkDialog';

const APPROVAL_TYPE_INDEX = 1;
const APPROVAL_INFO_INDEX = 3;

function getHolderComponentForType(type) {
  switch (type) {
    case USER_APPROVAL:
      return UserApprovalHolder;
    default:
      return () => (<div>Unknown Type</div>);
  }
}

export default function ApprovalTable(props) {

  const { items, approveItem, rejectItem } = props;

  const [open, setOpen] = useState(false);
  const [activeApproval, setActiveApproval] = useState({
    operation: null,
    approval: null
  });
  const clearActiveApproval = () => {
    setActiveApproval({
      operation: null,
      approval: null
    });
  };
  const handleClose = () => {
    setOpen(false);
    clearActiveApproval();
  };

  const handleSubmit = (operation, approval) => {
    if (operation === OPERATION_APPROVE) {
      approveItem(approval.id, approval);
    } else if (operation === OPERATION_REJECT) {
      rejectItem(approval.id, approval);
    }
  };

  const showRemarkDialog = (operation, approval) => {
    setActiveApproval({
      operation,
      approval
    });
    setOpen(true);
  };

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        searchable: false,
        display: 'excluded'
      }
    },
    {
      label: 'Type',
      name: 'type',
      options: {
        searchable: true
      }
    },
    {
      label: 'Remark',
      name: 'remark',
      options: {
        searchable: true
      }
    },
    {
      label: 'Info',
      name: 'info',
      options: {
        searchable: true,
        display: 'excluded'
      }
    },
    {
      label: 'Status',
      name: 'status'
    },
    {
      name: 'approve',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Grid>
              <Button color="primary" disabled={items[dataIndex].status !== STATUS_PENDING} onClick={() => {
                const approval = items[dataIndex];
                showRemarkDialog(OPERATION_APPROVE, approval);
              }}>
                Approve
              </Button>
              <Button color="primary" disabled={items[dataIndex].status !== STATUS_PENDING} onClick={() => {
                const approval = items[dataIndex];
                showRemarkDialog(OPERATION_REJECT, approval);
              }}>
                Reject
              </Button>
            </Grid>
          );
        },
        customHeadLabelRender: () => {
          return null;
        }
      }
    }
  ];

  const options = {
    filterType: 'checkbox',
    expandableRows: true,
    selectable: false,
    selectableRows: 'none',
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    isRowExpandable: (dataIndex, expandedRows) => {
      return !(expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0);
    },
    rowsExpanded: [],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const type = rowData[APPROVAL_TYPE_INDEX];
      const InfoHolder = getHolderComponentForType(type);
      const info = rowData[APPROVAL_INFO_INDEX];
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <InfoHolder info={info}/>
          </TableCell>
        </TableRow>
      );
    },
    download: false,
    print: false
    // serverSide: true,
    // onTableInit: (action, tableState) => {
    //   console.log(tableState);
    // },
    // onTableChange: (action, tableState) => {
    //   console.log(tableState);
    //   console.log(action);
    // }
  };

  return (
    <Fragment>
      <RemarkDialog open={open} handleClose={handleClose} handleSubmit={handleSubmit}
                    operation={activeApproval.operation} approval={activeApproval.approval}/>
      <MUIDataTable title={'Approvals'} data={items} columns={columns} options={options}/>
    </Fragment>
  );

}
