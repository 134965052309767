import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React, {Fragment, useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import CustomToolbarSelect from 'src/components/common/CustomToolbarSelect';
import {
    getAllSliders,
    slidersSelector
} from './heroSlidersSlice'

import api from '../../api/posts-api';


export default function HeroSlidersTableView() {
    const dispatch = useDispatch();
    const history = useHistory();
    const sliders = useSelector(slidersSelector);

    const onDeleteItems = (indexes) => {

            Promise.all(
                indexes.map( (index)=>{
                    let slide = sliders.find(slide=>{
                        return slide.id === index;
                    });
                    return api.removeHeroSlide(slide.id)
                }),
            )
            .then(()=>{
                dispatch(getAllSliders());
            })
            .catch(console.error);
    }


    const columns = [
        {
            name:'id',
            label:"ID"
        },
        {
            name:'title',
            label:"Title"
        },
        {
            name:'createdOn',
            label:'Created On',
            options: {
                customBodyRender: createdOn => {
                    const date = new Date(createdOn);
                    return date.toDateString()+' '+date.toLocaleTimeString();
                }
            }
        },
        {
            name:'id',
            label: 'Actions',
            options:{
                customBodyRender: (id) => {
                    return <Button
                        onClick={()=>handlePostOpen(id)} 
                        variant="contained" 
                        color="primary"
                        >Open</Button>
                }
            }
        }
    
    ]

    const options = {
        filter:false,
        customToolbar: () => {
            return (
              <Fragment>
                <Tooltip title={'Add Item'}>
                 <Button 
                    variant="contained" 
                    color="primary"
                    onClick={()=>{
                        history.push("sliders/new")
                    }}
                    >New</Button>
                </Tooltip>
              </Fragment>
            );
          },

          customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
            console.log({_displayData,selectedRows});
            
            let selectedDisplayData = _displayData.filter(slider=>{
                return selectedRows.lookup[slider.dataIndex]
            });
            
            let selectedSliders = {
                data: selectedDisplayData.map((slider,index)=>{
                    return {
                        dataIndex:slider.data[0],
                        index
                    }
                })
            }
            console.log(selectedSliders);
            return (
              <CustomToolbarSelect selectedRows={selectedSliders} setSelectedRows={setSelectedRows}
                                   onDeleteItems={onDeleteItems}/>);
          },
    }

    const handlePostOpen = id => {
        history.push("/sliders/edit/"+id);
    }

    useEffect(()=>{
        dispatch(getAllSliders());
    },[])
    return <MUIDataTable title={'Hero Sliders'} columns={columns} data={sliders} options={options}/>;
}