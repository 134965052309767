import { IconButton } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
  PhotoCamera,
  Cancel,
  Delete,
  Done,
  CheckCircle
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { Component, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getImagePageDataTemplate,
  IMAGE_PAGE_ID
} from 'src/api/imageHelper-v1API';
import { uploadFile, addPageData } from '../../../api/v1Api';
import {
  getSiteFileImages,
  setCurrentThumbnail,
  setPostUploadSummary
} from '../fileSlice';

const ImageUpload = ({setshowAlert}) => {
  const [open, setOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [imgButtonVisible, setImgButtonVisible] = useState(false);

  const dispatch = useDispatch();

  const progressListener = (e) => {
    setProgress(Math.round((e.loaded * 100) / e.total));
  };

  const handleFileSave = (files) => {
    setThumbnail(null);
    let mime = files[0].type;
    if(mime.match('image')){
      setThumbnail(files[0]);
      dispatch(setCurrentThumbnail('SELECTED'));
      setImgButtonVisible(true);
      setOpen(false);
      setshowAlert(<Alert severity="info">You have Successfully selected a new image. Please click "Add" to upload it!</Alert>);
    }else{
      setOpen(false);
      setshowAlert(<Alert severity="error">You can only select images!</Alert>);  
    }
  };
  const handleRemove = () => {
    setThumbnail(null);
    setshowAlert(<Alert severity="info">Image Removed!</Alert>);
  };
  const handleUpload = () => {
    uploadFile({ file: thumbnail, tag: { key: 'generic' } }, progressListener)
      .then((ref) => {
        console.log(ref);
        if (ref.statusCodeValue === 200) {
          dispatch(setCurrentThumbnail(ref.body));
          setshowAlert(<Alert severity="success">Image upload Successfull!</Alert>);
        }
        const newImageData = getImagePageDataTemplate(ref.body, thumbnail.name);
        addPageData(newImageData).then(() => {
          dispatch(getSiteFileImages(IMAGE_PAGE_ID));
        });
      })
      .catch((e) => {
        dispatch(
          setPostUploadSummary({
            error: e,
            message: e.message || 'Image Upload Failed'
          })
        );
      })
      .finally(() => {
        setImgButtonVisible(false);
        dispatch(getSiteFileImages(IMAGE_PAGE_ID));
      });
  };

  useEffect(() => {
    if (thumbnail === null) {
      dispatch(setCurrentThumbnail(null));
    }
  }, [thumbnail]);

  const renderInterface = () => {
    return (
      <>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={() => setOpen(true)}
        >
          {thumbnail ? thumbnail.name : 'Change thumbnail'}
        </Button>
        {thumbnail && imgButtonVisible ? (
          <>
            <IconButton
              onClick={handleRemove}
              color="secondary"
              aria-label="upload picture"
              component="span"
             style={{fontSize:'0.9rem'}}
            >
              <Delete fontSize='small'/> Remove
            </IconButton>
            <IconButton
             style={{fontSize:'0.9rem'}}
              onClick={handleUpload}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CheckCircle fontSize='small'/> Add
            </IconButton>
          </>
        ) : (
          ''
        )}
      </>
    );
  };

  return (
    <>
      {renderInterface()}
      <DropzoneDialog
        open={open}
        onClose={() => setOpen(false)}
        filesLimit={1}
        maxFileSize={1000000000}
        onSave={handleFileSave}
        showPreviews={true}
        initialFiles={[]}
      />
    </>
  );
};

export default ImageUpload;
