import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import { 
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Typography,
    List,
    ListItem,
    Link,
    TextField,
    IconButton,
    Grid,
    Divider
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ListItemText } from '@material-ui/core';
import { ListItemSecondaryAction } from '@material-ui/core';
import { DeleteForeverOutlined, UpdateOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { deleteBreadcrumb, updateBreadcrumb, addBreadcrumb } from './PageSlice';
import slugify from 'slugify';
import AlertBox from '../../components/common/AlertBox';

const useStyles = makeStyles({
    title: {
        fontSize: '16px',
    },
    item: {
        minWidth: '550px',
    },
    divider: {
        background: 'black',
    },
    error: {
        color: 'red',
    }
  });

const makeTitle = (b) => {
    if (b) return b.title || b.label || b.linkText;
  };

export default function PageMetaUpdate({open, setOpen, page}) {   
    const classes = useStyles();
    const alert = useAlert();
    const dispatch = useDispatch();
    const [add, setAdd] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState(null);


    const [alertOpen, setAlertOpen] = useState(false);
    const [message, setMessage] = useState('Successful');
    const [severity, setSeverity] = useState('success');

    const handleDeleteItem = (id) => {
        if (window.confirm('Are you sure you want to delete this breadcrumb?')){
            dispatch(deleteBreadcrumb({ id: page.id, data: id }));
        }
        setAlertOpen(true);
        setMessage('Breadcrumb has been deleted!');
        setSeverity('success');
    }

    const handleUpdateItem = (b) => {
        setBreadcrumb(b);
        setAdd(true);
    }
    

    const handleCloseAlert = () => {
        setAlertOpen(false);
      };

    const makeBreadCrumbs = () => {
        if(page.breadcrumbs && page.breadcrumbs.map){
            return page.breadcrumbs.map((b,i)=>{
                return (
                    <Grid item>
                        <ListItem button className={classes.item}>
                            <ListItemText >
                                <Typography className={classes.title} varient="caption">{makeTitle(b)}</Typography>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <IconButton>
                                    <UpdateOutlined onClick={() => handleUpdateItem(b)}/>
                                </IconButton>
                                <IconButton>
                                    <DeleteForeverOutlined onClick={() => handleDeleteItem(b.id)}/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Grid>
                )
            })
        } else return null;
    }
    
    const handleAddBreadcrumb = () => {
        setBreadcrumb();
        setAdd(true);
    }
        
        return (
            <Dialog fullWidth open={open} onClose={()=>setOpen(false)}>
                <AlertBox
                open={alertOpen}
                message={message}
                handleClose={handleCloseAlert}
                duration={3000}
                severity={severity}
                />
            <DialogTitle>
                <Typography variant="h4" align="center">
                    Update Breadcrumbs
                </Typography>
                <Divider className={classes.divider} variant="middle" />
            </DialogTitle>;
            <DialogContent>
                <List>
                   <Grid container direction="row" alignItems="flex-start">
                       {makeBreadCrumbs()}
                   </Grid>
                   <ListItem>
                       <Button color="primary" onClick={() => handleAddBreadcrumb()}>
                           <AddIcon/>
                           Add New Breadcrumb
                       </Button>
                   </ListItem>

                </List>
                <AddBreadcrumb open={add} setOpen={setAdd} pageID={page.id} breadcrumb={breadcrumb}/>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
            </DialogActions>

        </Dialog>
    )
}

export function AddBreadcrumb({open, setOpen, pageID, breadcrumb }) {
    const classes = useStyles();
    const alert = useAlert();
    const dispatch = useDispatch();

    const [mode, setMode] = useState(false);
    const [current, setCurrent] = React.useState();
    const [name, setName] = React.useState();
    const [link, setLink] = React.useState();
    const [label, setLabel] = React.useState();
    const [error, setError] = useState('');
    
    const handleNameChange = (e) => {
        setLabel(e);
    }

    const handleLinkChange = (e) => {
        setLink(e);
    }

    const handleSubmit = () => {
        let data = {
            name: name,
            link: link,
            label: label
        }
         if(mode) {
            dispatch(updateBreadcrumb({data: data, id: current.id, pageID: pageID}))
            setName('');
            setLabel('');
            setLink(''); 
            setCurrent();
            setOpen(false);

         } else {
            dispatch(addBreadcrumb({data: data, pageID: pageID}))
            setName('');
            setLabel('');
            setLink('');
            setCurrent();
            setOpen(false);
         }

    }

    useEffect(() => {
        if(label){
            setName(slugify(label, { lower: true }));
        }
    }, [label]);

    useEffect(() => {
        if(breadcrumb){
            setMode(true);
            setCurrent(breadcrumb);
            setName(breadcrumb.name);
            setLabel(breadcrumb.label);
            setLink(breadcrumb.link);
        } else {
            setMode(false);
        }
    }, [breadcrumb]);  

    useEffect(() => {
        if(String(label).length > 200) {
            setError('The maximum length is 200 characters!');
        } else {
            setError('');
        }
    }, [label]);

    return (
        <Dialog fullWidth open={open} onClose={()=>setOpen(false)}>
            <DialogTitle>
                <Typography variant="h4" align="center">
                    {mode ? "Update Breadcrumb" : "Add New Breadcrumb"}
                </Typography>
                <Divider className={classes.divider} variant="middle" />
            </DialogTitle>
            <DialogContent>
                <ListItem >
                    <Grid container direction="row" alignItems="flex-start">
                        <Grid item >
                            <TextField 
                                className={classes.item}
                                value={label}
                                label="Label"
                                fulWidth
                                onChange={(e) => handleNameChange(e.target.value)}
                            />
                            {error ? <p className={classes.error}>{error}</p> : ''}
                            <TextField 
                                className={classes.item}
                                value={link}
                                label="Link"
                                fulWidth
                                inputProps={{ maxLength: 200 }}
                                required
                                onChange={(e) => handleLinkChange(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </ListItem>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>handleSubmit()}>Submit</Button>
                <Button onClick={()=>setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}