
import * as apiV1 from './v1Api';
import * as apiFake from './fake';

let api;
if(process.env.REACT_APP_ENV==='development'){
    api = apiFake;
} else {
    api = apiV1;
}



export default api;
