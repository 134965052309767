import React from 'react';
import * as MUI from '@material-ui/core';
import { useStyles } from '../FileViewMain';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPagesByFileType,
  typeSelector,
  pageSelector,
  sectionSelector,
  setCurrentType,
  setCurrentPage,
  setCurrentSection,
  setCurrentSectionData,
  getPageData,
  pageDataSelector
} from '../fileSlice';

export default function FileContainerSelector({
  sessionType,
  sessionPage,
  sessionSection
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const types = useSelector(typeSelector) || [];
  const pages = useSelector(pageSelector) || [];
  const sections = useSelector(sectionSelector) || [];
  const pageData = useSelector(pageDataSelector) || [];

  const [type, setType] = React.useState(sessionType);
  const [page, setPage] = React.useState(sessionPage);
  const [section, setSection] = React.useState(sessionSection);

  const handleTypeSelect = (e) => {
    e.preventDefault();
    setType(types[e.target.value]);
    setPage(null);
    dispatch(setCurrentType(types[e.target.value]));
  };

  const handlePageSelect = (e) => {
    e.preventDefault();
    setPage(pages[e.target.value]);
    dispatch(setCurrentPage(pages[e.target.value]));
    dispatch(getPageData(pages[e.target.value].id));
  };

  const handleSectionSelect = (e) => {
    e.preventDefault();
    setSection(sections[e.target.value]);
    dispatch(setCurrentSection(sections[e.target.value]));
    let linkArray = pageData.filter(
      (paged) => paged.section === sections[e.target.value].id
    );
    dispatch(setCurrentSectionData(linkArray));
  };

  const mapItemToIndex = (type, item) => {
    if (!item) return '';
    if (type === 'type') {
      return types.indexOf(item);
    } else if (type === 'page') {
      return pages.indexOf(item);
    } else if (type === 'section') {
      return sections.indexOf(item);
    }
  };

  // Fetch Pages that can contain the selected type of files.
  React.useEffect(() => {
    if (type) {
      dispatch(getPagesByFileType(type.id));
      dispatch(setCurrentPage(null));
    }
  }, [type]);

  React.useEffect(() => {
    setType(sessionType);
    setPage(sessionPage);
    setSection(sessionSection);
  }, [sessionType, sessionPage, sessionSection]);

  return (
    <>
      <MUI.FormControl className={classes.formControl}>
        {/* File Type Selector */}
        <MUI.InputLabel id="file-type-selector-label">File Type</MUI.InputLabel>
        <MUI.Select
          labelId="file-type-selector-label"
          id="file-type-selector"
          onChange={handleTypeSelect}
          value={mapItemToIndex('type', type)}
        >
          {types && (
            <MUI.MenuItem key="ft-4" value="3">
              {types[3]?.name}
            </MUI.MenuItem>
          )}
        </MUI.Select>
        <MUI.Link className={classes.paperActionLinks}>
          Manage File Types
        </MUI.Link>
      </MUI.FormControl>

      {/* Parent Page Selector */}
      <MUI.FormControl className={classes.formControl}>
        <MUI.InputLabel id="parent-page-selector-label">
          Parent Page
        </MUI.InputLabel>
        <MUI.Select
          labelId="parent-page-selector-label"
          id="parent-page-selector"
          onChange={handlePageSelect}
          value={mapItemToIndex('page', page)}
          disabled={!!!type}
        >
          {pages &&
            Array.isArray(pages) &&
            pages.filter(page => page.id !== "site-files").map((i, k) => {
              return (
                <MUI.MenuItem key={`ft-${i.id}`} value={k}>
                  {i.title}
                </MUI.MenuItem>
              );
            })}
        </MUI.Select>
        {type && (
          <MUI.Link className={classes.paperActionLinks}>
            Create New Page
          </MUI.Link>
        )}
      </MUI.FormControl>

      <MUI.FormControl className={classes.formControl}>
        {/* Page Section Selector */}
        <MUI.InputLabel id="page-section-selector-label">
          Parent Section
        </MUI.InputLabel>
        <MUI.Select
          labelId="page-section-selector-label"
          id="page-section-selector"
          onChange={handleSectionSelect}
          value={mapItemToIndex('section', section)}
          disabled={!!!type && !!!page}
        >
          {sections &&
            sections.map((i, k) => {
              return (
                <MUI.MenuItem key={`ft-${i.id}`} value={k}>
                  {i.label}
                </MUI.MenuItem>
              );
            })}
        </MUI.Select>
        {type && page && (
          <MUI.Link className={classes.paperActionLinks}>
            Create New Section
          </MUI.Link>
        )}
      </MUI.FormControl>
    </>
  );
}
