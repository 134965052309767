import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React, {Fragment, useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import CustomToolbarSelect from 'src/components/common/CustomToolbarSelect';
import EditIcon from '@material-ui/icons/Edit';
import {
    getAllChart,
    chartSelector
} from '../chart/chartSlice'

import api from '../../api/posts-api';

export default function ChartTableView() {
    const dispatch = useDispatch();
    const history = useHistory();
    const charts = useSelector(chartSelector);

    useEffect(()=>{
        dispatch(getAllChart());
    },[]);

    const onDeleteItems = (indexes) => {

        Promise.all(
            indexes.map( (index)=>{
                let chart = charts.find(chart=>{
                    return chart.chartId === index;
                });
                return api.removeChart(chart.chartId)
            }),
        )
        .then(()=>{
            dispatch(getAllChart());
        })
        .catch(console.error);
    }

    const columns = [
        {
            name:'chartId',
            label:"ID"
        },
        {
            name:'chartCategoryName',
            label:"Category Name"
        },
        {
            name:'subCategoryOneName',
            label:'Sub Category 1'
        },
        {
            name:'subCategoryOneValue',
            label:'Allocation Value'
        },
        {
            name:'subCategoryTwoName',
            label:'Sub Category 2'
        },
        {
            name:'subCategoryTwoValue',
            label:'Commitment Value'
        },
        {
            name:'chartId',
            label: 'Actions',
            options:{
                customBodyRender: (chartId) => {
                    return <div style={{ cursor: 'pointer' }}><EditIcon
                        onClick={() => handlePostOpen(chartId)}>Open</EditIcon></div>
                }
            }
        }
    
    ]

    const options = {
        filter:false,
        customToolbar: () => {
            return (
              <Fragment>
                <Tooltip title={'Add Item'}>
                 <Button 
                    variant="contained" 
                    color="primary"
                    onClick={()=>{
                        history.push("chart/new")
                    }}
                    >New</Button>
                </Tooltip>
              </Fragment>
            );
          },

          customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
            console.log({_displayData,selectedRows});
            
            let selectedDisplayData = _displayData.filter(slider=>{
                return selectedRows.lookup[slider.dataIndex]
            });
            
            let selectedSliders = {
                data: selectedDisplayData.map((slider,index)=>{
                    return {
                        dataIndex:slider.data[0],
                        index
                    }
                })
            }
            console.log(selectedSliders);
            return (
              <CustomToolbarSelect selectedRows={selectedSliders} setSelectedRows={setSelectedRows}
                                   onDeleteItems={onDeleteItems}/>);
          },
    }

    const handlePostOpen = chartId => {
        window.localStorage.setItem("Chart-ID", chartId);
        history.push("/chart/edit");
    }

    return <MUIDataTable title={'Chart'} columns={columns} data={charts} options={options}/>;

}