import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import HTMLEditorContainer from "./components/HtmlEditorContainer";
import NoticeProperties from './components/NoticeProperties';
import NoticeAttachments from './components/NoticeAttachments';
import {  useParams, useHistory } from 'react-router';
import api from '../../api/procurement-notices-api'
import AlertBox from 'src/components/common/AlertBox';
import { useAlert } from 'react-alert';


export default function NoticeEditorView() {
    const params = useParams();
    const history = useHistory();
    const [editMode,setMode] = useState(false);
    const [alertOpen,setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");
    const [severity, setSeverity] = useState("success");
    const [republishable,setRepublishable] = useState(false);
    const [listen, setListen] = useState(false);
    const alert = useAlert();
    const [noticeState,setNoticeState] = useState({
        title:'Untitled notice',
        subtitle:'subtitle',
        description: 'description',
        attachments:[],
    });
    
    

    useEffect(()=>{
        if(params.id){
            setMode(true);
            getNotice(params.id);
        }else{
            handleSave();
            setAlertOpen(true)
            setMessage("Notice draft has been created");
            setSeverity("success");   
        }

    },[params]);

    const getNotice = (id) => {
        api.getNotice(id)
        .then(res=>{
            if(res){
                setNoticeState({
                    ...res
                })
            }else{
                history.push('/404')
            }
        })
        .catch(console.error)
    }
    const handleSave = async () => {
        if(editMode){
            
            if (noticeState.title === '') {
                setAlertOpen(true)
                setMessage("Notice has not been Updated");
                setSeverity("error");
            } else {
            
                let saveRes = await api.updateNotice({...noticeState}, params.id);
            
                if(saveRes){
                    setAlertOpen(true)
                    setMessage("Notice has been Updated");
                    setSeverity("success");
                    getNotice(params.id);
                    setRepublishable(true);
                    setListen(true);
                }
            }
            
        }else{
            try{
                let id = await api.createNewNotice({...noticeState});
                history.push('/notices/edit/'+id);
            }catch(e){
                console.error(e);
            }
        }

    }

    const handlePublish = async () => {
        try {
            if (noticeState.title === '') {
                setAlertOpen(true)
                setMessage("Notice has been not published");   
                setSeverity("error"); 
            } else {

                let publishRes = await api.publishNotice(params.id);
                
                console.log(publishRes);
                if(publishRes){
                    
                    setNoticeState(state=>({
                        ...state,
                    }));
                    
                }
                if(publishRes){
                    getNotice(params.id);
                    setRepublishable(false);
                    alert.success('Notice has been published !');
                    history.push('/notices');
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
      };

    return (
        <Grid container spacing={2}>
            
            {/* Alerts */}
            <AlertBox 
                open={alertOpen}
                message={message}
                handleClose={handleCloseAlert}
                duration={3000}
                severity={severity}
            />
            
            <Grid item md={9}>
                <HTMLEditorContainer 
                    title={noticeState.title}
                    description={noticeState.description}
                    subtitle={noticeState.subtitle}
                    setNoticeState={setNoticeState}
                    handleSave={handleSave}
                    handlePublish={handlePublish}
                    republishable={republishable}
                    published={noticeState.status}
                    listen={listen}
                />
            </Grid>

            <Grid container item spacing={2} direction={"column"} md={3}>
                <Grid item>
                    {
                    !!(noticeState.attachments)&&
                        <NoticeAttachments 
                            noticeId={params.id}
                            attachments={noticeState.attachments} 
                            setNoticeState={setNoticeState}
                            refreshData={()=>getNotice(params.id)}
                            handleSave={handleSave}
                        />
                    }
                </Grid>
                <Grid item>
                    <NoticeProperties 
                        {...noticeState}
                    />
                </Grid>
            </Grid>

        </Grid>
    )
}