import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

// initial state of the auth slice
const initialState = {
  loading: false,
  approvals: [],
  hasErrors: false
};

// async thunk action
export const getApprovals = createAsyncThunk(
  'approval/getApprovals',
  () => {
    return api.getApprovals();
  }
);

export const approveApproval = createAsyncThunk(
  'approval/approveApproval',
  async ({ id, approval }, { dispatch }) => {
    try {
      await api.approveApproval(id, approval.remark);
      await dispatch(getApprovals());
    } catch (e) {
      throw e;
    }
  }
);

export const rejectApproval = createAsyncThunk(
  'approval/rejectApproval',
  async ({ id, approval }, { dispatch }) => {
    try {
      await api.rejectApproval(id, approval.remark);
      await dispatch(getApprovals());
    } catch (e) {
      throw e;
    }
  }
);

const approvalSlice = createSlice({
  name: 'approval',
  initialState,
  reducers: {},
  extraReducers: {
    [getApprovals.pending]: (state) => {
      state.loading = true;
    },
    [getApprovals.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.approvals = [];
    },
    [getApprovals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.approvals = payload;
    }
  }
});

// actions
// export const {} = userSlice.actions;

// selectors <- getters
export const approvalsSelector = store => {
  return store.approval.approvals;
};

// reducer
export default approvalSlice.reducer;
