import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertBox from './AlertBox';


export default function StateAlertBox(props) {
  const { messageSelector, resetMessage, duration, severity, vertical, horizontal } = props;
  const dispatch = useDispatch();
  const message = useSelector(messageSelector);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    dispatch(resetMessage());
    setOpen(false);
  };

  useEffect(() => {
    if (message !== '') {
      setOpen(true);
    }
  }, [message]);

  return (
    <AlertBox message={message} open={open} handleClose={handleClose} duration={duration} severity={severity}
              vertical={vertical} horizontal={horizontal}/>
  );
}
