import React from 'react';
import AuthWrapperComponent from './AuthWrapperComponent';
import RegisterForm from './RegisterForm';

export default function LoginView(props) {
  return (
    <AuthWrapperComponent
    //https://i.picsum.photos/id/237/800/800.jpg?hmac=vCdYL_CRxqCGRhpbwB6Q2uD9BMcs7wHZ0hb28qxtrYE
      image="/images/login.jpg" title="Login">
      <RegisterForm/>
    </AuthWrapperComponent>
  );
}
