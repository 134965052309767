const TYPE_PERMISSIONS = 'permissions';
const TYPE_ROLES = 'roles';
const TYPE_USERS = 'users';
const TYPE_APPROVALS = 'approvals';
const TYPE_PAGES = 'pages'
const TYPE_ECCONOMIC_INDICATORS = 'ecconomicIndicators';

const data = {
  permissions: {
    nextId: 3,
    values: {
      1: { id: 1, name: 'USER_CREATE', value: 'USER_CREATE', status: 'active' },
      2: { id: 2, name: 'USER_EDIT', value: 'USER_EDIT', status: 'active' }
    }
  },
  roles: {
    nextId: 2,
    values: {
      1: {
        id: 1, name: 'Admin', status: 'active', permissions: [
          { id: 1, name: 'USER_CREATE', value: 'USER_CREATE', status: 'active' },
          { id: 2, name: 'USER_EDIT', value: 'USER_EDIT', status: 'active' }
        ]
      }
    }
  },
  users: {
    nextId: 2,
    values: {
      1: {
        id: 1,
        firstName: 'John',
        lastName: 'Doe',
        email: 'john@doe.com',
        mobileNumber: '0767657651',
        status: 'approved',
        role: {
          id: 1, name: 'Admin', status: 'active', permissions: [
            { id: 1, name: 'USER_CREATE', value: 'USER_CREATE', status: 'active' },
            { id: 2, name: 'USER_EDIT', value: 'USER_EDIT', status: 'active' }
          ]
        },
        avatarLink: 'https://i.picsum.photos/id/61/200/200.jpg?hmac=RL-JLeHTLsWK7354qjYNQ1iuDxeoCv-kYRTUYun2h34',
        password: '123123123'
      }
    }
  },
  approvals: {
    nextId: 2,
    values: {
      1: {
        id: 1,
        userId: 1,
        remark: 'Admin User Accepted',
        type: 'USER_APPROVAL',
        status: 'approved',
        info: {
          id: 1,
          firstName: 'Admin',
          lastName: 'Doe',
          avatarLink: 'https://i.picsum.photos/id/61/200/200.jpg?hmac=RL-JLeHTLsWK7354qjYNQ1iuDxeoCv-kYRTUYun2h34',
          email: 'john@doe.com',
          mobileNumber: '0767657651',
          role: {
            id: 1, name: 'Admin', status: 'active', permissions: [
              { id: 1, name: 'USER_CREATE', value: 'USER_CREATE', status: 'active' },
              { id: 2, name: 'USER_EDIT', value: 'USER_EDIT', status: 'active' }
            ]
          }
        }
      }
    }
  }, 
  //testing do not use
  ecconomicIndicators:{
    nextId:2,
    values:{
      1:{id:1,userId:1,type:'ECCONOMIC_INDICATORS'}
    }
  }

};

const tokens = {
  token: null,
  refresh: null
};
const RegisterUser = {
  id: '',
  email:'',
  firstName: '',
  lastName:'',
  password:'',

};
const loggedUser = {
  id: 1,
  firstName: 'John',
  lastName: 'Doe',
  email: 'john@doe.com',
  mobileNumber: '0767657651',
  status: 'active',
  role: {
    id: 1,
    name: 'Admin',
    status: 'active',
    permissions: [
      { id: 1, name: 'USER_CREATE', value: 'USER_CREATE', status: 'active' },
      { id: 2, name: 'USER_EDIT', value: 'USER_EDIT', status: 'active' }
    ]
  },
  avatarLink: 'https://i.picsum.photos/id/61/200/200.jpg?hmac=RL-JLeHTLsWK7354qjYNQ1iuDxeoCv-kYRTUYun2h34'
};

async function addEntry(type, entry) {
  if (!(type in data)) return;
  if (!data[type].nextId) {
    data[type].nextId = 1;
  }
  const nextId = data[type].nextId;
  data[type].nextId++;
  data[type].values[nextId] = { ...entry, id: nextId };
  return nextId;
}

async function editEntry(type, id, entry) {
  if (!(type in data)) return;
  if (!(id in data[type].values)) return;
  data[type].values[id] = entry;
}

async function removeEntry(type, id) {
  if (!(type in data)) return;
  if (!(id in data[type].values)) return;
  delete data[type].values[id];
}

async function getEntryById(type, id) {
  if (!(type in data)) return;
  if (!(id in data[type].values)) return;
  return data[type].values[id];
}

async function getEntries(type) {
  if (!(type in data)) return;
  return Object.values(data[type].values);
}

const networkCall = (res, timeout = 500) => {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve(res);
    }, timeout);
  });
};

/*
  AUTH API
 */

export const setTokens = (newTokens) => {
  tokens.token = newTokens.token;
  tokens.refresh = newTokens.refresh;
};

export const login = async (credentials) => {
  if (credentials.email === 'demo@gmail.com' && credentials.password === 'demopwd1') {
    const res = { token: 'aaa', refresh: 'aab' };
    return await networkCall(res);
  }
  throw new Error('Invalid Credentials');
};
export const register = async (user) => {
  if (user.email && user.firstName && user.lastName && user.password) {
    const res = { token: 'aaa', refresh: 'aab' };
    return await networkCall(res);
  }
  throw new Error('Invalid Register');
};

export const getLoggedUser = async (token) => {
  if (!token) token = tokens.token;
  const res = loggedUser;
  return await networkCall(res);
};

/*
  PERMISSION API
 */

export const getPermissions = async () => {
  const res = await getEntries(TYPE_PERMISSIONS);
  return await networkCall(res);
};

/*
  APPROVAL API
 */
export const getApprovals = async () => {
  const res = getEntries(TYPE_APPROVALS);
  return await networkCall(res);
}

export const addApproval = async (approval) => {
  console.log(approval);
  await addEntry(TYPE_APPROVALS, approval);
  return await networkCall('OK');
}

export const editApproval = async (id, approval) => {
  await editEntry(TYPE_APPROVALS, id, approval);
  return await networkCall('OK');
}

/*
 * ROLE API
 */
export const getRoles = async () => {
  const res = getEntries(TYPE_ROLES);
  return await networkCall(res);
};

export const addRole = async (role) => {
  await addEntry(TYPE_ROLES, role);
  return await networkCall('OK');
};

export const editRole = async (id, role) => {
  await editEntry(TYPE_ROLES, id, role);
  return await networkCall('OK');
};

export const removeRole = async (id) => {
  await removeEntry(TYPE_ROLES, id);
  return await networkCall('OK');
};

/*
* USER API
* */
export const getUsers = async () => {
  const res = getEntries(TYPE_USERS);
  return await networkCall(res);
};

export const addUser = async (user) => {
  const id = await addEntry(TYPE_USERS, user);
  const approval = {
    remark: null,
    status: 'pending',
    userId: 1,
    type: 'USER_APPROVAL',
    info: {
      ...user,
      id
    }
  };
  await addApproval(approval)
  return await networkCall('OK');
};

export const editUser = async (id, user) => {
  await editEntry(TYPE_USERS, id, user);
  return await networkCall('OK');
};

export const removeUser = async (id) => {
  await removeEntry(TYPE_USERS, id);
  return await networkCall('OK');
};


// Page API

export const getPageList = async () => {
  const res = getEntries(TYPE_PAGES);
  return await networkCall(res);
};

export const getPage = async id => {
  const res = await getEntryById(TYPE_PAGES,id);
  console.log({res,id})
  return await networkCall(res);
}

export const updatePage = async (id,page) => {
  await editEntry(TYPE_PAGES,id, page)
  return await networkCall('OK');
  
}