import React, { useEffect, useState } from 'react';
import { CardContent, Hidden, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { useAuthStyles } from './authCommon';
import { setTitle } from '../../libs/helpers';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function RegisterView(props) {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const { children, image, title } = props;

  const classes = useAuthStyles();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.title = setTitle(title);
  }, [title]);

  return (
    // <Box
    //     display="flex"
    //     flexDirection="row"
    //     justifyContent="center"
    //     height="100%"
    //     width="100%"
    //     alignItems="center"
    //     position="fixed"
    //     >
    <Card
      style={{ height: `${windowDimensions.height - 53}px` }}
    >
      <Grid
        container
        style={{ height: '100%' }}
      >
          <Hidden smDown>
            <Grid item md={6} xs={12}>
              <img alt="Placeholder"  className={classes.leftImage}
                  src={image}/>
            </Grid>
          </Hidden>
          <Grid style={{ height: '100%' }} item md={6} xs={12}>
            <Card className={classes.rightPanel} square elevation={2}>
              <CardContent>
                {children}
              </CardContent>
            </Card>
          </Grid>

        
      </Grid>

    </Card>
    // </Box>
  );
}
