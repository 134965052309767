import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import React from 'react';
import DrawerCollapsibleItem from './DrawerCollapsibleItem';
import DrawerItem from './DrawerItem';


/**
 * Constructs DrawerList given props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default (props) => {
  const { title, items, ...rest } = props;
  return (
    <Box {...rest}>
      {title ? <Box mx={2} mt={2}><Typography variant='caption'>{title}</Typography></Box> : null}
      <List>
        {items.map((item, i) => {
          if (item.hide === "true") return null;
          if (item.type === 'collapsable')
            return <DrawerCollapsibleItem {...item} key={i}/>;
          return <DrawerItem {...item} key={i}/>;
        })}
      </List>
      <div style={{position:'relative',bottom:0,left:'43%'}}>
        <div className={'footer'}>
          Content Management System<br/>
          Version {process.env.REACT_APP_VERSION}<br/>
          © {new Date().getFullYear()} Elysian Crest<br/>
        </div>
      </div>
    </Box>
  );
};
