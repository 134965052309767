import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';


// initial state of the auth slice
const initialState = {
  loading: false,
  error: '',
  token: null,
  refresh: null,
  user: null,
  registered: '',
  sendEmail: '',
  setPassword: ''
};

// async thunk action
export const getLoggedUser = createAsyncThunk(
  'auth/getLoggedUser',
  async (token) => {
    return api.getLoggedUser(token);
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { dispatch, rejectWithValue }) => {
    try {
      const authRes = await api.login(credentials);
      await dispatch(getLoggedUser(authRes.token));
      return authRes;
    } catch (e) {
      const message = (e.message ? e.message : 'Error!');
      return rejectWithValue(message);
    }
    // console.log(credentials)
    // make api call
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  (_) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }
);
export const register = createAsyncThunk(
  'auth/register',
  async (user, { dispatch, rejectWithValue }) => {
    try {
      const authRes = await api.register(user);
      return authRes;
    } catch (e) {
      const message = (e.message ? e.message : 'Error!');
      return rejectWithValue(message);
    }


  }
);
export const forgetPassword = createAsyncThunk(
  'auth/forgerPassword',
  async (email, { dispatch, rejectWithValue }) => {
    try {
      const authRes = await api.forgetPassword(email);
      return authRes;
    } catch (e) {
      const message = (e.message ? e.message : 'Error!');
      return rejectWithValue(message);
    }


  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ email, token, password }, { dispatch, rejectWithValue }) => {
    try {
      return await api.resetPassword(email, token, password);
    } catch (e) {
      const message = (e.message ? e.message : 'Error!');
      return rejectWithValue(message);
    }
  }
);

export const verifyPasswordResetToken = createAsyncThunk(
  'auth/verifyPasswordResetToken',
  async ({ email, token }, { rejectWithValue }) => {
    try {
      return await api.verifyPasswordResetToken(email, token);
    } catch (e) {
      const message = (e.message ? e.message : 'Error!');
      return rejectWithValue(message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetError(state) {
      state.error = '';
    },
    resetRegistered(state) {
      state.registered = '';
    },
    sendForgetPassword(state) {
      state.sendEmail = '';
    },
    resetNewPassword(state) {
      state.setPassword = '';
    }
  },
  extraReducers: {

    // register thunk
    [register.pending]: (state) => {
      state.loading = true;
      state.error = '';
      state.registered = '';
    },
    [register.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.registered = 'Successfully registered';

    },
    [register.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;

    },

    // login thunk
    [login.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [login.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.token = null;
      state.refresh = null;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.token = payload.token;
      state.refresh = payload.refresh;
    },
    [logout.fulfilled]: (state) => {
      state.loading = false;
      state.token = null;
      state.refresh = null;
      state.user = null;
    },
    [getLoggedUser.fulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [getLoggedUser.rejected]: (state) => {
      state.user = null;
    },
    [forgetPassword.pending]: (state) => {
      state.loading = true;
      state.error = '';
      state.sendEmail = '';
    },
    [forgetPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sendEmail = 'Successfully send reset Password Email';

    },
    [resetPassword.pending]: (state) => {
      state.loading = true;
      state.error = '';
      state.setPassword = '';

    },
    [resetPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.setPassword = 'Successfully Updated new Password';

    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.error = payload;
    }
  }
});

// actions
export const { resetError, resetRegistered, sendForgetPassword, resetNewPassword } = authSlice.actions;


// selectors <- getters
export const tokensSelector = store => {
  return {
    token: store.auth.token,
    refresh: store.auth.refresh
  };
};

// export const userSelector = store => store.auth.user;
export const loggedUserSelector = store => (store.auth.user);
export const userAvatarSelector = store => (store.auth.user && store.auth.user.avatar) ? store.auth.user.avatar : '';
export const isLoggedSelector = store => Boolean(store.auth.token && store.auth.token.length > 0 && !store.auth.loading && !!store.auth.user);
// export const isLoggedSelector = store => false;
export const authErrorSelector = store => (store.auth.error);
export const registeredSelector = store => (store.auth.registered);
export const forgetPasswordEmail = store => (store.auth.sendEmail);
export const setNewPassword = store => (store.auth.setPassword);

export const hasPermission = perm => store => (store.auth.user && store.auth.user.role && store.auth.user.role.permissions && store.auth.user.role.permissions.some(p => p.value === perm));

// reducer
export default authSlice.reducer;
