import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MoreIcon from '@material-ui/icons/MoreVert';
import AppBar from '@material-ui/core/AppBar';
import React, { Fragment, useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useLocation } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { darkModeSelector, setDarkMode } from '../features/darkMode/darkModeSlice';

const useStyles = makeStyles((theme) => ({
  appBar: props => ({
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${props.drawerWidth}px)`,
      marginLeft: props.drawerWidth
    }
  }),
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  grow: {
    flexGrow: 1
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  darkModeToggle: {
    marginLeft: '10px'
  }
}));

export default function Header(props) {

  const { drawerWidth, title, handleDrawerToggle, headerItems } = props;
  const classes = useStyles({ drawerWidth });

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const location = useLocation();
  useEffect(() => {
    setMobileMoreAnchorEl(null);
  }, [location]);

  const dispatch = useDispatch();
  const darkMode = useSelector(darkModeSelector);

  const MenuBadgeButton = React.forwardRef((props, ref) => {
    const { icon, title, count, iconButtonColor, badgeColor, isMobile, ...restProps } = props;
    const Btn = (innerProps) => (
      <IconButton ref={ref} color={iconButtonColor ? iconButtonColor : 'inherit'} {...innerProps}>
        <Badge badgeContent={count} color={badgeColor ? badgeColor : 'secondary'}>
          {icon}
        </Badge>
      </IconButton>
    );
    if (isMobile) {
      return (
        <MenuItem ref={ref} {...restProps}>
          <Btn disableRipple disableFocusRipple/>
          <p>{title}</p>
        </MenuItem>
      );
    } else {
      return <Btn {...restProps}/>;
    }
  });

  const toggleMode = () => {
    dispatch(setDarkMode(!darkMode));
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {headerItems.map((item, i) => {
        item.isMobile = true;
        return <MenuBadgeButton key={i} {...item} />;
      })}
      <MenuItem disableRipple>
        <FormControlLabel control={<Switch checked={darkMode} onChange={toggleMode}/>}
                          label={`${darkMode ? 'Dark' : 'Light'} Mode`}/>
      </MenuItem>
    </Menu>
  );

  const renderDesktopMenu = (
    <Fragment>
      {headerItems.map((item, i) => {
        item.isMobile = false;
        return <MenuBadgeButton key={i} {...item} />;
      })}
      <FormControlLabel className={classes.darkModeToggle} control={<Switch checked={darkMode} onChange={toggleMode}/>}
                        label={`${darkMode ? 'Dark' : 'Light'} Mode`}/>
    </Fragment>
  );

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon/>
        </IconButton>
        <Typography className={classes.title} variant="h6" noWrap>
          {title}
        </Typography>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon/>
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
        <div className={classes.grow}/>
        <div className={classes.sectionDesktop}>
          {renderDesktopMenu}
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon/>
          </IconButton>
        </div>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
}
