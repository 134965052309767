import axios from 'axios';
import { API_BASE } from '../v1Api';

const API_BASE_PRESS_RELEASE = API_BASE + "/pressRelease";
const API_BASE_HERO = API_BASE + "/hero";
const API_BASE_NEWSLINE = API_BASE + "/news/news-line";
const API_BASE_CHART = API_BASE + "/chart";
const API_BASE_PROJECT_MAP = API_BASE + "/project-map";

//Get all Posts
export async function getAllPosts() {
    try {
        return (await axios.get(API_BASE_PRESS_RELEASE + "/getAll")).data;
    } catch (error) {

    }
}

//Get all Hero Sliders
export async function getAllSliders() {
    try {
        const resp = (await axios.get(API_BASE_HERO + '/fetchAll')).data
        return resp;
    } catch (error) { }
}

// Get All Post Data By post ID
export async function getPost(postId) {
    try {
        return (await axios.get(API_BASE_PRESS_RELEASE + `/${postId}`)).data
    } catch (error) {

    }
};

// Post Content
export async function createNewPost(data) {
    try {
        delete data.attachments;
        return (await axios.post(API_BASE_PRESS_RELEASE, {
            ...data
        })).data
    } catch (error) {

    }
}

export async function saveDraft(id, data) {
    try {
        return (await axios.put(API_BASE_PRESS_RELEASE + `/${id}`, {
            title: data.title,
            slug: data.slug,
            html: data.html,
            heroSlideId: data.heroSlideId,
            featuredImage: data.featuredImage,
            status: data.status
        }))
    } catch (error) {
        console.log(error.message)
    }
}

export async function publish(id) {
    try {
        return (await axios.post(API_BASE_PRESS_RELEASE + `/post/${id}/publish`)).data;
    } catch (error) {
        console.log(error);
    }
}

export async function deletePost(id, heroSliderId) {
    try {
        if (heroSliderId) {
            await removeHeroSlide(heroSliderId);
        }
        return (await axios.delete(API_BASE_PRESS_RELEASE + `/${id}`)).data;
    } catch (error) {

    }
}

export async function addPostAttachment(postId, data) {
    try {
        return (await axios.post(API_BASE_PRESS_RELEASE + `/data/${postId}`, {
            title: data.title,
            link: data.link,
            thumbnail: data.thumbnail,
            linkText: data.linkText,
            external: data.external
        })).data;
    } catch (error) {

    }
}

export async function deletePostAttachment(id) {
    try {
        return (await axios.delete(API_BASE_PRESS_RELEASE + `/data/${id}`)).data
    } catch (error) {
        console.log(error);
    }
}

// Post Hero Slide

//Create hero slide for press release
export async function createHeroSlide(description, action, image) {

    try {
        return (await axios.post(API_BASE_HERO, {
            title: 'Press Release',
            description: description,
            img_URL: image,
            pressRelease: true,
            actionLink: action,
            actionLabel: 'Read More!'
        })).data
    } catch (error) {

    }
}

export async function updateHeroSlide(id, description, action, image) {
    try {
        return (await axios.put(API_BASE_HERO + `/${id}`, {
            title: 'Press Release',
            description: description,
            img_URL: image,
            pressRelease: true,
            actionLink: action,
            actionLabel: 'Read More!'
        })).data
    } catch (error) {

    }
}

export async function removeHeroSlide(id) {
    try {
        return (await axios.delete(API_BASE_HERO + `/${id}`)).data
    } catch (error) {

    }
}

//Hero slider CRUD

//add hero slider
export async function createHero(data) {

    try {
        return (await axios.post(API_BASE_HERO, data)).data
    } catch (error) {

    }
}

//update hero slider
export async function updateHero(id, data) {
    try {
        return (await axios.put(API_BASE_HERO + `/${id}`, data)).data
    } catch (error) {

    }
}

//get hero slider by ID
export async function getHeroSlider(id) {
    try {
        const resp = (await axios.get(API_BASE_HERO + `/fetch/${id}`)).data
        return resp;
    } catch (error) {

    }
}


//Newsline CRUD

//add news
export async function createNews(data) {
    try {
        return (await axios.post(API_BASE_NEWSLINE + `/create`, data)).data
    } catch (error) {

    }
}

//update news
export async function updateNews(data) {
    try {
        return (await axios.put(API_BASE_NEWSLINE + `/update`, data)).data
    } catch (error) {

    }
}

//get all news
export async function getAllNews() {
    try {
        const resp = (await axios.get(API_BASE_NEWSLINE + `/get-all`)).data
        return resp;
    } catch (error) {

    }
}

//get all news by ID
export async function getAllNewsById(id) {
    try {
        const resp = (await axios.get(API_BASE_NEWSLINE + `/get-by-id/${id}`)).data
        return resp;
    } catch (error) {

    }
}

//delete news
export async function removeNews(id) {
    try {
        return (await axios.delete(API_BASE_NEWSLINE + `/delete-by-id/${id}`)).data
    } catch (error) {

    }
}



//Chart CRUD

//get all chart details
export async function getAllChart() {
    try {
        const resp = (await axios.get(API_BASE_CHART + `/get-all`)).data
        return resp;
    } catch (error) {

    }
}

//get all chart by ID
export async function getAllChartById(id) {
    try {
        const resp = (await axios.get(API_BASE_CHART + `/get-by-id/${id}`)).data
        return resp;
    } catch (error) {

    }
}

//add chart
export async function createChart(data) {
    try {
        return (await axios.post(API_BASE_CHART + `/create`, data)).data
    } catch (error) {

    }
}

//update chart
export async function updateChart(data) {
    try {
        return (await axios.put(API_BASE_CHART + `/update`, data)).data
    } catch (error) {

    }
}

//delete chart
export async function removeChart(id) {
    try {
        return (await axios.delete(API_BASE_CHART + `/delete-by-id/${id}`)).data
    } catch (error) {

    }
}


//Project Map CRUD

//get all project map
export async function getAllProjectMap() {
    try {
        const resp = (await axios.get(API_BASE_PROJECT_MAP + `/get-all`)).data
        return resp;
    } catch (error) {

    }
}

//get all project map by ID
export async function getProjectMapById(id) {
    try {
        const resp = (await axios.get(API_BASE_PROJECT_MAP + `/get-by-id/${id}`)).data
        return resp;
    } catch (error) {

    }
}

//add project map
export async function createProjectMap(data) {
    try {
        return (await axios.post(API_BASE_PROJECT_MAP + `/create`, data)).data
    } catch (error) {

    }
}

//update project map
export async function updateProjectMap(data) {
    try {
        return (await axios.put(API_BASE_PROJECT_MAP + `/update`, data)).data
    } catch (error) {

    }
}

//delete project map
export async function removeProjectMap(id) {
    try {
        return (await axios.delete(API_BASE_PROJECT_MAP + `/delete-by-id/${id}`)).data
    } catch (error) {

    }
}
