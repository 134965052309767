import React from 'react';

export default (props) => {
  const { fieldName, component, label, handleBlur, handleChange, touched, errors, values, ...rest } = props;
  const Component = component;
  return (
    <Component
      error={Boolean(touched[fieldName] && errors[fieldName])}
      helperText={touched[fieldName] && errors[fieldName]}
      label={label}
      name={fieldName}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values[fieldName]}
      {...rest}
    />
  );
};
