import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import * as PropTypes from 'prop-types';

function ConfirmationDialog(props) {

  const { id, onClose, open, title, subtitle, okText, onOk, okColor, ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onOk();
    onClose();
  };

  const useStyles = makeStyles(() => ({
    paper: {
      height: 300,
      width: 325,
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      // maxWidth="xs"
      classes={classes}
      aria-labelledby={id}
      open={open}
      {...other}
    >
      <DialogTitle id={id}>{title}</DialogTitle>
      <DialogContent dividers>
        <Typography variant='subtitle1'>{subtitle}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleOk} color='primary'>
          {okText ? okText : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onOk: PropTypes.func.isRequired,
  okText: PropTypes.string
};

export default ConfirmationDialog;

