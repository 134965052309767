import React from 'react';
import { makeStyles, Paper, Grid } from "@material-ui/core";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme=>({
    paper: {
        padding:theme.spacing(2),
    },
    typography: {
        fontWeight: 1000
    }
}))

export default function NoticeProperties({
    createdBy,createdOn,updatedBy,updatedOn,publishedBy,publishedOn,status
}){
    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
            <Grid item>
                <Typography variant="overline" className={classes.typography}>Created By:</Typography>
                <Typography variant="caption">&nbsp; {createdBy?createdBy:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline" className={classes.typography}>Created On:</Typography>
                <Typography variant="caption">&nbsp; {createdOn?createdOn:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline" className={classes.typography}>Last Updated By:</Typography>
                <Typography variant="caption">&nbsp; {updatedBy?updatedBy:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline" className={classes.typography}>Last Updated On:</Typography>
                <Typography variant="caption">&nbsp; {updatedOn?updatedOn:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline" className={classes.typography}>Notice State:</Typography>
                <Typography variant="caption">&nbsp; {status?'PUBLISHED':'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline" className={classes.typography}>Published By:</Typography>
                <Typography variant="caption">&nbsp; {publishedBy?publishedBy:'N/A'}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="overline" className={classes.typography}>Published On:</Typography>
                <Typography variant="caption">&nbsp; {publishedOn?publishedOn:'N/A'}</Typography>
            </Grid>
        </Paper>
    )
}