import { makeStyles } from '@material-ui/core';


export const useAuthStyles = makeStyles((theme) => ({
  leftImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%',
    justifyContent: 'center'
  }
}));
