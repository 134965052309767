import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

export default (props) => {

  const { fieldName, label, handleBlur, handleChange, touched, errors, values, dataset, ...rest } = props;

  const error = Boolean(touched[fieldName] && errors[fieldName]);

  return (
    <FormControl {...rest} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        error={error}
        name={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        onBlur={handleBlur}
        label={label}
      >
        {dataset.map((item) =>
          (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{touched[fieldName] && errors[fieldName]}</FormHelperText>
    </FormControl>
  );
};
