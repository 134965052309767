import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

// initial state of the auth slice
const initialState = {
  loading: false,
  roles: [],
  hasErrors: false
};

// async thunk action
export const getRoles = createAsyncThunk(
  'role/getRoles',
  () => {
    return api.getRoles();
  }
);

export const addRole = createAsyncThunk(
  'role/addRole',
  async (role, { dispatch }) => {
    try {
      await api.addRole(role);
      await dispatch(getRoles());
    } catch (e) {
      throw e;
    }
  }
);

export const editRole = createAsyncThunk(
  'role/editRole',
  async ({id, role}, { dispatch }) => {
    try {
      await api.editRole(id, role);
      await dispatch(getRoles());
    } catch (e) {
      throw e;
    }
  }
);

export const removeRole = createAsyncThunk(
  'role/removeRole',
  async (id, { dispatch }) => {
    try {
      await api.removeRole(id);
      await dispatch(getRoles());
    } catch (e) {
      throw e;
    }
  }
);

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: {
    [getRoles.pending]: (state) => {
      state.loading = true;
    },
    [getRoles.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.roles = [];
    },
    [getRoles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.roles = payload;
    }
    // [addRole.fulfilled]: (state, { payload }) => {
    //   const role = {
    //     ...payload,
    //     id: state.id
    //   };
    //   state.roles = [
    //     ...state.roles,
    //     role
    //   ];
    //   state.id = state.id + 1;
    // },
    // [removeRole.fulfilled]: (state, { payload }) => {
    //   state.roles = state.roles.filter((role) => {
    //     return role.id !== payload;
    //   });
    // }
  }
});

// actions
// export const {} = roleSlice.actions;

// selectors <- getters
export const rolesSelector = store => {
  return store.role.roles;
};

// reducer
export default roleSlice.reducer;
