import {createAsyncThunk,createSlice} from '@reduxjs/toolkit';
import api from '../../api/posts-api'

const init = {
    loading: false,
    hasError: false,
    posts: []
}

export const getAllPosts = createAsyncThunk(
    'posts/getAllPosts',
    async () => {
        try {
            return await api.getAllPosts()
        } catch (error) {
        }
    }
)

const postsSlice = createSlice({
    name:'postsSlice',
    initialState:init,
    extraReducers:{
        [getAllPosts.fulfilled] : (state, {payload}) => {
            state.posts = payload;
        }
    }
})


// selectors
export const postsSelector = store => {
    return store.post.posts
}


export default postsSlice.reducer;