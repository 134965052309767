import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/pages-api';

//initial state of the slice
const initialState = {
  loading: false,
  hasErrors: false,
  pageList: [],
  pageTypes: [],
  currentPage: null,
  currentPageData: [],
  orderedSections: [],
  orderedData: []
};

export const getPageTypes = createAsyncThunk('pages/getPageTypes', async () => {
  try {
    return await api.getLinkTypesWithAttribs();
  } catch (error) {
    throw error;
  }
});

export const getPageList = createAsyncThunk('pages/getPageList', (parent) => {
  try {
    return api.getPageList(parent);
  } catch (e) {
    throw e;
  }
});

export const getPageDataByID = createAsyncThunk(
  'page/getPageDataByID',
  async (id) => {
    try {
      const resp = await api.getPageDataByID(id);
      return resp;
    } catch (e) {
      throw e;
    }
  }
);

export const getPage = createAsyncThunk('pages/getPage', (id) => {
  try {
    return api.getPage(id);
  } catch (e) {
    throw e;
  }
});

export const getPageByID = createAsyncThunk(
  'pages/getPage',
  async (id, { dispatch }) => {
    try {
      const page = await api.getPage(id);
      dispatch(getPageDataByID(id));
      dispatch(getSectionOrder(id));
      dispatch(getOrderedPageData(id));
      return page;
    } catch (e) {
      throw e;
    }
  }
);

export const createPage = createAsyncThunk(
  'pages/createPage',
  async (data, { dispatch }) => {
    try {
      await dispatch(getPageList());
      dispatch(getPageByID(data.id));
    } catch (e) {
      throw e;
    }
  }
);

export const updatePage = createAsyncThunk(
  'pages/updatePage',
  async (data, { dispatch }) => {
    try {
      await api.updatePage(data);
      return data;
    } catch (e) {
      throw e;
    }
  }
);

export const publishPage = createAsyncThunk(
  'pages/publishPage',
  async (id, { dispatch }) => {
    try {
      await api.publishPage(id);
      dispatch(getPageByID(id));
    } catch (e) {
      throw e;
    }
  }
);

//Add a new Section
export const createSection = createAsyncThunk(
  'pages/createSection',
  async ({ data, id }, { dispatch }) => {
    try {
      await api.createSection(data, id);
      dispatch(getPageByID(id));
    } catch (error) {
      throw error;
    }
  }
);

//Delete a section
export const deleteSection = createAsyncThunk(
  'pages/deleteSection',
  async ({ pageId, sectionId, attachments }, { dispatch }) => {
    try {
      await api.deleteSection(sectionId, attachments);
      dispatch(getPageByID(pageId));
    } catch (error) {
      throw error;
    }
  }
);

//Add Page data
export const addPageData = createAsyncThunk(
  'pages/addPageData',
  async ({ pageId, data }, { dispatch }) => {
    try {
      // console.log(pageId, "---Slice Working---");
      await api.addPageData(data);
      await dispatch(getPageByID(pageId));
      await dispatch(getPageDataByID(pageId));
    } catch (e) {
      throw e;
    }
  }
);

//Update Page Data
export const updatePageData = createAsyncThunk(
  'pages/updatePageData',
  async ({ id, data, pageId }, { dispatch }) => {
    try {
      await api.updataPageData(id, data);
      await dispatch(getPageByID(pageId));
      await dispatch(getPageDataByID(pageId));
    } catch (e) {
      throw e;
    }
  }
);

//Delete PageData
export const deletePageData = createAsyncThunk(
  'pages/deletePageData',
  async ({ id, data }, { dispatch }) => {
    // console.log(data);
    try {
      await api.deletePageData(data);
      await dispatch(getPageByID(id));
      await dispatch(getPageDataByID(id));
    } catch (e) {
      throw e;
    }
  }
);

//Add New Breadcrumb
export const addBreadcrumb = createAsyncThunk(
  'pages/addBreadcrumb',
  async ({ data, pageID }, { dispatch }) => {
    try {
      await api.createBreadcrumb(data, pageID);
      await dispatch(getPageByID(pageID));
    } catch (e) {
      throw e;
    }
  }
);

//Update a Breadcrumb
export const updateBreadcrumb = createAsyncThunk(
  'pages/updateBreadcrumb',
  async ({ data, id, pageID }, { dispatch }) => {
    try {
      await api.updateBreadcrumb(data, id);
      await dispatch(getPageByID(pageID));
    } catch (e) {
      throw e;
    }
  }
);

//Delete Breadcrumb
export const deleteBreadcrumb = createAsyncThunk(
  'pages/deleteBreadcrumb',
  async ({ id, data }, { dispatch }) => {
    try {
      await api.deleteBreadcrumb(data);
      await dispatch(getPageByID(id));
    } catch (e) {
      throw e;
    }
  }
);

//Delete Page with the Page data
export const deletePage = createAsyncThunk(
  'pages/deletePage',
  async ({ id, pageData }, { dispatch }) => {
    try {
      await api.deletePage(id);
      await dispatch(getPageList());
    } catch (e) {
      throw e;
    }
  }
);

// Section Order
export const setSectionOrder = createAsyncThunk(
  'pages/setSectionOrder',
  async ({ id, order }, { dispatch }) => {
    try {
      await api.setSectionOrder(id, order);
      dispatch(getSectionOrder(id));
    } catch (error) {
      throw error;
    }
  }
);

export const getSectionOrder = createAsyncThunk(
  'pages/getSectionOrder',
  async (id) => {
    try {
      return await api.getOrderedSections(id);
    } catch (error) {
      throw error;
    }
  }
);

// Page Data Order
export const setPageDataOrder = createAsyncThunk(
  'pages/pageDataOrder',
  async ({ id, items }, { dispatch }) => {
    try {
      await api.setPageDataOrder(id, items);
      dispatch(getOrderedPageData(id));
      return;
    } catch (error) {
      throw error;
    }
  }
);

export const getOrderedPageData = createAsyncThunk(
  'pages/getOrderedPageData',
  async (pageId) => {
    try {
      const resp = await api.getOrderedPageData(pageId);
      if (resp === 'NOT_FOUND') {
        const order = [];
        return order;
      } else {
        return resp;
      }
    } catch (error) {
      throw error;
    }
  }
);

const PageSlice = createSlice({
  name: 'pages',
  initialState: initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    clearCurrPage(state) {
      state.currentPage = null;
    },
    setSectionHTML(state, action) {
      if (
        action.payload.title &&
        state.currentPage.title !== action.payload.title
      ) {
        let t = action.payload.title;
        let slug = t.toLowerCase().trim().split(' ').join('-');
        state.currentPage.title = action.payload.title;
        state.currentPage.slug = slug;
      }

      if (action.payload.id === state.currentPage.id) {
        state.currentPage.html = action.payload.html;
      } else {
        let id = action.payload.id;
        if (state.currentPage.sections && state.currentPage.sections.find) {
          let section =
            state.currentPage.sections.find((s) => s.id == id) || {};
          let orderedSection =
            state.orderedSections.find((os) => os.id === id) || {};
          section.html = action.payload.html;
          orderedSection.html = action.payload.html;
        } else {
          return;
        }
      }
    },
    setCurrentPageData(state, action) {
      state.currentPageData = action.payload;
    },
    removeLocalPageData(state, action) {
      state.currentPageData = state.currentPageData.filter(
        (d) => d.linkID !== action.payload.linkID
      );
    }
  },
  extraReducers: {
    [getPageTypes.pending]: (state) => {
      state.loading = true;
    },
    [getPageTypes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pageTypes = payload.body;
    },
    [getPageTypes.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [getPageList.pending]: (state) => {
      state.loading = true;
    },
    [getPageList.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [getPageList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.pageList = payload;
      if (state.currentPage && state.currentPage.id) {
        state.currentPage = payload.find((p) => p.id === state.currentPage.id);
      }
    },
    [getPage.pending]: (state) => {
      state.loading = false;
    },
    [getPage.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [getPage.fulfilled]: (state, { payload }) => {
      state.currentPage = payload;
    },
    [createPage.pending]: (state) => {
      state.loading = true;
    },
    [createPage.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [createPage.fulfilled]: (state) => {
      state.loading = false;
    },
    [addBreadcrumb.pending]: (state) => {
      state.loading = true;
    },
    [addBreadcrumb.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [addBreadcrumb.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateBreadcrumb.pending]: (state) => {
      state.loading = true;
    },
    [updateBreadcrumb.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [updateBreadcrumb.fulfilled]: (state) => {
      state.loading = false;
    },
    [updatePage.pending]: (state) => {
      state.loading = true;
    },
    [updatePage.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [updatePage.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentPage = payload;
    },
    [publishPage.pending]: (state) => {
      state.loading = true;
    },
    [publishPage.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [publishPage.fulfilled]: (state) => {
      state.loading = false;
    },
    [deletePage.pending]: (state) => {
      state.loading = true;
    },
    [deletePage.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [deletePage.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPageByID.pending]: (state) => {
      state.loading = true;
      state.currentPage = { sections: [] };
    },
    [getPageByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentPage = payload;
    },
    [getPageDataByID.pending]: (state, { payload }) => {
      state.loading = true;
      state.currentPageData = [];
    },
    [getPageDataByID.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.currentPageData = payload;
    },
    [deleteSection.rejected]: (state) => {
      state.hasErrors = true;
    },
    [addPageData.pending]: (state) => {
      state.loading = true;
    },
    [addPageData.fulfilled]: (state) => {
      state.loading = false;
    },
    [addPageData.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [updatePageData.pending]: (state) => {
      state.loading = true;
    },
    [updatePageData.fulfilled]: (state) => {
      state.loading = false;
    },
    [updatePageData.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [deletePageData.pending]: (state) => {
      state.loading = true;
    },
    [deletePageData.fulfilled]: (state) => {
      state.loading = false;
    },
    [deletePageData.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [deleteBreadcrumb.pending]: (state) => {
      state.loading = true;
    },
    [deleteBreadcrumb.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteBreadcrumb.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    [setSectionOrder.pending]: (state) => {
      state.loading = true;
    },
    [setSectionOrder.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [getSectionOrder.pending]: (state) => {
      state.loading = true;
    },
    [getSectionOrder.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orderedSections = payload;
    },
    [setPageDataOrder.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [setPageDataOrder.fulfilled]: (state) => {
      state.loading = false;
    },
    [getOrderedPageData.pending]: (state) => {
      state.loading = true;
    },
    [getOrderedPageData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orderedData = payload;
    }
  }
});

//Actions
export const {
  setCurrentPage,
  clearCurrPage,
  setCurrentPageData,
  setSectionHTML,
  removeLocalPageData
} = PageSlice.actions;

// Selectors
export const PageTypesSelector = (store) => {
  return store.pages.pageTypes;
};
export const PageListSelector = (store) => {
  return store.pages.pageList;
};

export const currentPageSelector = (store) => {
  return store.pages.currentPage;
};

export const currentPageDataSelector = (store) => {
  return store.pages.currentPageData;
};
export const sectionOrderSelector = (store) => {
  return store.pages.orderedSections;
};

export const orderedPageDataSelector = (store) => {
  return store.pages.orderedData;
};

export default PageSlice.reducer;
