import React, { Fragment, useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Edit from '@material-ui/icons/Edit';
import { Delete, List, Pages, Visibility } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import PageNewView from './PageNewView';
import AlertBox from 'src/components/common/AlertBox';
import { useSelector } from 'react-redux';
import { loggedUserSelector } from '../auth/authSlice';

export default function PageTable(props) {
  const { items, viewEditor, handleShowChildren, handleDeletePage } = props;
  const [newPage, setNewPage] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState('Post draft has been created');
  const [severity, setSeverity] = useState('success');

  const [filteredItems, setFilteredItems] = useState([]);
  // const [defaultItems, setDefaultItems] = useState([]);
  const [specificUser, setSpecificUser] = useState(false);

  const user = useSelector(loggedUserSelector);

  useEffect(() => {
    if (user) {
      if (user.role.name === "SUPER_ADMIN") {
        setSpecificUser(true);
      }
      if (user.role.name === "ADMIN") {
        setSpecificUser(true);
      }
    }
  }, []);

  useEffect(() => {
    const specificPages = [];
    const defaultPages = [];
    items.map((item) => {
      item.breadcrumbs.map((b) => {
        if (b.link === '/web/about') {
          specificPages.push(item);
        }
      });
    });
   
    items.map((item) => {
      if(!!!specificPages.find(p => p.id === item.id)){
        defaultPages.push(item);
      }
    });

    setFilteredItems(defaultPages);
    // setDefaultItems(defaultPages);
   
  }, [items]);

  const columns = [
    {
      label: 'Page Title',
      name: 'title'
    },
    {
      label: 'Created On',
      name: 'createdOn'
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        customBodyRender: (status) => <p>{status ? 'Published' : 'Draft'}</p>
      }
    },
    {
      label: 'Published On',
      name: 'publishedOn',
      options: {
        customBodyRender: (publishedOn) => (
          <p>{publishedOn ? publishedOn : 'N/A'}</p>
        )
      }
    },
    {
      label: 'Actions',
      name: 'id',
      options: {
        customBodyRender: (id, { rowIndex }) => (
          <Grid container spacing={2}>
            <Grid item>
              <Tooltip title="Child Pages">
                <Button
                  variant="outlined"
                  onClick={() => handleShowChildren(id)}
                >
                  <List color="primary" />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Edit Page">
                <Button
                  variant="outlined"
                  onClick={() => viewEditor(id, rowIndex)}
                >
                  <Edit color="action" />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Delete Page">
                <Button variant="outlined" onClick={() => handleDeletePage(id)}>
                  <Delete color="error" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }
    }
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    customToolbar: () => (
      <Fragment>
        <Button
          onClick={() => setNewPage(true)}
          variant="contained"
          color="primary"
          startIcon={<Pages />}
        >
          New Page
        </Button>
      </Fragment>
    )
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return (
    <Fragment>
      {/* Alerts */}
      <AlertBox
        open={alertOpen}
        message={message}
        handleClose={handleCloseAlert}
        duration={3000}
        severity={severity}
      />
      <PageNewView
        open={newPage}
        setAlertOpen={setAlertOpen}
        setOpen={setNewPage}
        pages={items}
      />

      <MUIDataTable
        title={props.title}
        data={specificUser ? items : filteredItems}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
}
