import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React, {Fragment, useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import CustomToolbarSelect from 'src/components/common/CustomToolbarSelect';
import {
    getAllPosts,
    postsSelector
} from './postsSlice'

import api from '../../api/posts-api';


export default function PostsTableView() {
    const dispatch = useDispatch();
    const history = useHistory();
    const posts = useSelector(postsSelector);

    const onDeleteItems = (indexes) => {

            Promise.all(
                indexes.map( (index)=>{
                    let post = posts.find(post=>{
                        return post.id === index;
                    });
                    return api.deletePost(index,post.heroSlideId)
                }),
            )
            .then(()=>{
                dispatch(getAllPosts());
            })
            .catch(console.error);
    }


    const columns = [
        {
            name:'id',
            label:"ID"
        },
        {
            name:'title',
            label:"Title"
        },
        {
            name:'createdOn',
            label:'Created On'
        },
        {
            name: 'status',
            label: 'Status',
            options:{
                customBodyRender: status => {
                    return <p>{status?'Published':'Draft'}</p>
                }
            }
        },
        {
            name:'id',
            label: 'Actions',
            options:{
                customBodyRender: (id) => {
                    return <Button
                        onClick={()=>handlePostOpen(id)} 
                        variant="contained" 
                        color="primary"
                        >Open</Button>
                }
            }
        }
    
    ]

    const options = {
        filter:false,
        customToolbar: () => {
            return (
              <Fragment>
                <Tooltip title={'Add Item'}>
                 <Button 
                    variant="contained" 
                    color="primary"
                    onClick={()=>{
                        history.push("new")
                    }}
                    >New</Button>
                </Tooltip>
              </Fragment>
            );
          },

          customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
            console.log({_displayData,selectedRows});
            
            let selectedDisplayData = _displayData.filter(post=>{
                return selectedRows.lookup[post.dataIndex]
            });
            
            let selectedPosts = {
                data: selectedDisplayData.map((post,index)=>{
                    return {
                        dataIndex:post.data[0],
                        index
                    }
                })
            }
            console.log(selectedPosts);
            return (
              <CustomToolbarSelect selectedRows={selectedPosts} setSelectedRows={setSelectedRows}
                                   onDeleteItems={onDeleteItems}/>);
          },
    }

    const handlePostOpen = id => {
        history.push("edit/"+id);
    }

    useEffect(()=>{
        dispatch(getAllPosts());
    },[])
    return <MUIDataTable title={'Posts'} columns={columns} data={posts} options={options}/>;
}