import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import FileUploader from '../file-upload/components/FileUploader';
import { useSelector, useDispatch } from 'react-redux';
import {
  sessionSelector,
  getFileTypes,
  resetCurrentSession,
  setCurrentType,
  setCurrentPage,
  setCurrentSection,
  typeSelector,
  setCurrentSectionData
} from '../file-upload/fileSlice';
import { getPageByID, getPageDataByID } from './PageSlice';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { addPageData } from './PageSlice';
import PageSelectorDialog from './PageSelectorDialog';

export default function PageFileNew({
  open,
  setOpen,
  page,
  current,
  type,
  isTable
}) {
  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  const types = useSelector(typeSelector);
  const pages = useSelector((store) => store.pages.pageList);
  const currData = useSelector((store) => store.pages.currentPageData);
  const [localType, setLocalType] = useState();
  const [localLink, setLocalLink] = useState('');
  const [showPageSelector, setShowPageSelector] = useState(false);

  useEffect(() => {
    dispatch(getFileTypes());
    dispatch(resetCurrentSession());
    dispatch(setCurrentPage(page));
    dispatch(
      setCurrentSection({ ...current, label: current.label || current.title })
    );
    dispatch(setCurrentSectionData([]));
    if (isTable) {
      let onlyType = types?.find((t) => t.id === type);
      dispatch(setCurrentType(onlyType));
    } else {
      let genericType = types?.find((t) => t.name === 'generic');
      dispatch(setCurrentType(genericType));
    }
  }, [open]);

  const getTitle = (link, type) => {
    let defaultTitle = '';
    if (type === 'link') {
      defaultTitle = link;
    } else if (type === 'page') {
      defaultTitle = pages.find((p) => p.id === link)?.title;
    }
    return window.prompt('What should the link text be?', link) || defaultTitle;
  };

  const getThumbnail = (link, type) => {
    if (type === 'link') {
      return '/assets/images/link.png';
    } else if (type === 'page') {
      return '/assets/images/emblem.png';
    }
  };

  const populateTypes = () => {
    if (types instanceof Array) {
      return types.map((type, k) => {
        if (type.name === 'generic') return null;
        return (
          <MenuItem key={type.id} value={type.id}>
            {String(type.name).charAt(0).toUpperCase() +
              String(type.name).slice(1)}
          </MenuItem>
        );
      });
    } else {
      return null;
    }
  };

  const handleTypeSelect = (e) => {
    let genericType = types.find((t) => t.name === 'generic');
    let selectedType = genericType;

    if (e.target.value === 'page' || e.target.value === 'link') {
      setLocalType(e.target.value);
    } else if (e.target.value === 'file') {
      setLocalType(e.target.value);
    } else {
      selectedType = types.find((t) => t.id === e.target.value);
      setLocalType(selectedType && selectedType.id);
    }

    dispatch(setCurrentType(selectedType));
  };

  const handleCreateAttachment = () => {
    let genericType = types.find((t) => t.name === 'generic');

    let data = {
      pageID: page.id,
      type: genericType.id,
      links: [
        {
          title: getTitle(localLink, localType),
          link: localLink,
          section: current.id,
          linkText: 'View',
          thumbnail: getThumbnail(localLink, localType),
          target: 'not root',
          external: localType === 'page' ? false : true,
          urlType: localType
        }
      ]
    };
    dispatch(addPageData({ pageId: page.id, data: data }));
    alert('Attachment Added.');
    setLocalType();
    setOpen(false);
  };

  const handleFileUploadCallback = (res) => {
    dispatch(getPageByID(page.id));
    dispatch(getPageDataByID(page.id));
    alert('Attachment Added.');
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        Attach a File/Link to{' '}
        {current.isParent ? current.title + ' (Main Section)' : current.label}
      </DialogTitle>
      <DialogContent>
        {isTable ? (
          <Alert severity="info">
            The Layout of this Page doesn't allow files types other than &nbsp;
            {session.fileType
              ? String(session.fileType.name).toUpperCase()
              : 'N/A'}
          </Alert>
        ) : (
          <Select
            fullWidth
            defaultValue={'file'}
            value={localType}
            onChange={handleTypeSelect}
            label="Select Type"
          >
            <MenuItem value="file">File</MenuItem>
            <MenuItem value="link">External Link</MenuItem>
            <MenuItem value="page">Page</MenuItem>
            {/* {populateTypes()} */}
          </Select>
        )}

        {/* Show Attributes and Inputs */}
        {localType === 'link' || localType === 'page' ? (
          <>
            {localType === 'link' ? (
              <>
                <TextField
                  fullWidth
                  label="Link"
                  value={localLink}
                  onChange={(e) => setLocalLink(e.target.value)}
                />
              </>
            ) : (
              <>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setShowPageSelector(true)}
                >
                  Select Page: {localLink}
                </Button>
                <PageSelectorDialog
                  open={showPageSelector}
                  setOpen={setShowPageSelector}
                  callback={(page) => setLocalLink(page.id)}
                />
              </>
            )}
            <Button
              onClick={handleCreateAttachment}
              fullWidth
              variant="contained"
              color="primary"
            >
              Add {localType}
            </Button>
          </>
        ) : (
          <FileUploader
            fileType={session.fileType}
            page={page}
            section={current}
            callback={handleFileUploadCallback}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
