import React, { Component } from 'react';

import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { Children } from 'react';

const TableHeaders = ({columns,id,name}) => {
    return ( 
        <TableHead>
            <TableRow>
                {columns.map(col => {
                    return <TableCell key={col[id]}>{col[name]}</TableCell>
                })}
            </TableRow>
        </TableHead>
     );
}
export default TableHeaders;
