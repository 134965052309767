

export function setTitle(title) {
  return `Admin | ${title}`
}

export function btnPreventDefault (event) {
  event.preventDefault();
}

export const USER_STATUS_DATASET = [
  { value: 'PENDING', name: 'Pending' },
  { value: 'ACTIVE', name: 'Active' },
  { value: 'INACTIVE', name: 'Inactive' }
];

export const APPROVAL_STATUS_DATASET = [
  { value: 'PENDING', name: 'Pending' },
  { value: 'APPROVED', name: 'Approved' },
  { value: 'REJECTED', name: 'Rejected' }
];

export const STATUS_DATASET = [
  { value: 'INACTIVE', name: 'Inactive' },
  { value: 'ACTIVE', name: 'Active' }
];

export const PRODUCT_STATUS_DATASET = [
  { value: 'PENDING', name: 'Pending' },
  { value: 'ACTIVE', name: 'Active' },
  { value: 'INACTIVE', name: 'Inactive' }
];
