import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React, {Fragment, useEffect } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import CustomToolbarSelect from 'src/components/common/CustomToolbarSelect';
import EditIcon from '@material-ui/icons/Edit';
import {
    getAllProjectMap,
    projectMapSelector
} from '../project-map/projectMapSlice'

import api from '../../api/posts-api';

export default function ProjectMapTableView() {
    const dispatch = useDispatch();
    const history = useHistory();
    const projectMaps = useSelector(projectMapSelector);

    useEffect(()=>{
        dispatch(getAllProjectMap());
    },[]);

    const onDeleteItems = (indexes) => {

        Promise.all(
            indexes.map( (index)=>{
                let projectMap = projectMaps.find(projectMap =>{
                    return projectMap.projectMapId === index;
                });
                return api.removeProjectMap(projectMap.projectMapId)
            }),
        )
        .then(()=>{
            dispatch(getAllProjectMap());
        })
        .catch(console.error);
    }

    const columns = [
        {
            name:'projectMapId',
            label:"ID"
        },
        {
            name:'locationName',
            label:"Location Name"
        },
        {
            name:'iwwComponent',
            label:'IWWRMP Component'
        },
        {
            name:'agency',
            label:'Agency'
        },
        {
            name:'projectValue',
            label:'Project Value'
        },
        {
            name:'contractPeriod',
            label:'Contract Period'
        },
        {
            name:'intendDateOfCompletion',
            label:'Intend Date Of Completion'
        },
        {
            name:'phyProgress',
            label:'Physical Progress'
        },
        {
            name:'projectMapId',
            label: 'Actions',
            options:{
                customBodyRender: (projectMapId) => {
                    return <div style={{ cursor: 'pointer' }}><EditIcon
                        onClick={() => handlePostOpen(projectMapId)}>Open</EditIcon></div>
                }
            }
        }
    
    ]

    const options = {
        filter:false,
        customToolbar: () => {
            return (
              <Fragment>
                <Tooltip title={'Add Item'}>
                 <Button 
                    variant="contained" 
                    color="primary"
                    onClick={()=>{
                        history.push("/project-map/new")
                    }}
                    >New</Button>
                </Tooltip>
              </Fragment>
            );
          },

          customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
            console.log({_displayData,selectedRows});
            
            let selectedDisplayData = _displayData.filter(slider=>{
                return selectedRows.lookup[slider.dataIndex]
            });
            
            let selectedSliders = {
                data: selectedDisplayData.map((slider,index)=>{
                    return {
                        dataIndex:slider.data[0],
                        index
                    }
                })
            }
            console.log(selectedSliders);
            return (
              <CustomToolbarSelect selectedRows={selectedSliders} setSelectedRows={setSelectedRows}
                                   onDeleteItems={onDeleteItems}/>);
          },
        }

        const handlePostOpen = projectMapId => {
            window.localStorage.setItem("ProjectMap-ID", projectMapId);
            history.push("/project-map/edit");
        }
    
        return <MUIDataTable title={'Project Map'} columns={columns} data={projectMaps} options={options}/>;
    }