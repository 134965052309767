import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import AdminMaterialTextField from '../../components/common/AdminMaterialTextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';


export default function(props) {

  const { open, onCancel, onSubmit, initValue } = props;

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="lg">
      <Formik
        initialValues={initValue}
        validationSchema={
          Yup.object().shape({
            // name: Yup.string().min(5).required('Name is required'),
            // permissions: Yup.array(Yup.object()).min(1)
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await onSubmit(values);
          } catch (e) {
            console.log(e);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => {
          const formProps = {
            handleBlur,
            errors,
            handleChange,
            touched,
            values
          };
          return (
            <Form>
              <DialogTitle>Edit Profile</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid container item direction="row" spacing={2}>
                    <Grid item md={6} xs={12}>
                      <AdminMaterialTextField fieldName="firstName" label="First Name" fullWidth margin="normal"
                                              variant="outlined" {...formProps} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <AdminMaterialTextField fieldName="lastName" label="Last Name" fullWidth margin="normal"
                                              variant="outlined" {...formProps} />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={12} xs={12}>
                      <AdminMaterialTextField fieldName="mobileNumber" label="Mobile Number" fullWidth margin="normal"
                                              variant="outlined" type="tel" {...formProps} />
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button disabled={isSubmitting} onClick={handleSubmit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );

}
