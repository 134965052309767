//react - npm  modules
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

//local modules
import { ecoIndicatorSelector } from './ecoIndicatorSlice';
import IndicatorComponentList from './indicatorComponentList';
import { getSections } from './ecoIndicatorSlice';
import { Alert, AlertTitle } from '@material-ui/lab';
import IndicatorsCard from './subComponents/IndicatorsCard';

const EcoIndicatorListView = () => {
  //data fetching
  const store = useSelector(ecoIndicatorSelector);
  const [sectionArray, setsectionArray] = useState(!store ? [] : store);

  //create state for storing selected section card - default object has passed
  const [editComponent, setEditComponent] = useState({
    section: {},
    columns: {},
    rows: {}
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSections());
  }, [dispatch, sectionArray]);

  //calling when click the section buttons
  const handleOnClick = (object) => {
    setEditComponent(object);
    console.log(object);
  };

  //call in the render
  const renderEditableCard = (section) => {
    switch (section.section_key) {
      case '1':
        return <IndicatorsCard secKey={0} section={section}/>;
        break;
      case '2':
        return <IndicatorsCard secKey={1} section={section}/>;
        break;
      case '3':
        return <IndicatorsCard secKey={2} section={section}/>;
        break;
      case '4':
        return <IndicatorsCard secKey={3} section={section}/>;
        break;
      case '5':
        return <IndicatorsCard secKey={4} section={section}/>;
        break;

      default:
        break;
    }
  };
  
  return (
    <>
      
        {sectionArray.length !== 0 ? (
          <IndicatorComponentList data={sectionArray} onClick={handleOnClick} />
        ) : (
          <div>
            <Alert severity="warning">
              <AlertTitle>Fail to load data</AlertTitle>
                Unable to load data check server connection — <strong>Refresh page again !</strong>
             </Alert>
          </div>
        )}
        <br />
        <br />
        {renderEditableCard(editComponent)}
      
    </>
  );
};

export default EcoIndicatorListView;
