import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../../api';

//initial state of the page data
const initialState = {
    loading: false,
    hasErrors: false,
    fileId: null,
    state: null
};

export const uploadFile = createAsyncThunk(
    'pageDataLink/uploadFile',
    async (file, tagname) => {
        try {
            const post = {
                file: file,
                tag: tagname
            }
            return await api.uploadFile(post);    
        } catch (e) {
            throw e;
        }
    }
);

const pageDataSlice = createSlice({
    name: 'pageDataLink',
    initialState: {
        initialState
    },
    
    extraReducers: {
        [uploadFile.pending]: (state, action) => {
            state.status = 'Loading'
        },
        [uploadFile.fulfilled]: (state, { payload }) => {
            state.fileId = payload 
            state.status = 'Success'
        },
        [uploadFile.rejected]: (state, action) => {
            state.status = 'Failed'
        }
    }
})

export const pageDataSelector = ({ pageData }) => pageData;

export const uplodFile = pageDataSlice.actions;

export default pageDataSlice.reducer;