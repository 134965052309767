import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import HTMLEditorContainer from "./components/HtmlEditorContainer";
import PostProperties from './components/PostProperties';
import PostFeaturedPhoto from './components/PostFeaturedPhoto';
import PostAttachments from './components/PostAttachments';
import {  useParams, useHistory } from 'react-router';
import api from '../../api/posts-api'
import AlertBox from 'src/components/common/AlertBox';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { getAllPosts } from './postsSlice';


export default function PostEditorView() {
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    const [editMode,setMode] = useState(false);
    const [addToHero,setAddToHero] = useState(true);
    const [alertOpen,setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");
    const [severity, setSeverity] = useState("success");
    const [listen, setListen] = useState(false);
    const alert = useAlert();
    const [republishable,setRepublishable] = useState(false);
    const [postState,setPostState] = useState({
        title:'Untitled Post',
        html:'',
        attachments:[],
        featuredImage:''
    });
    
    

    useEffect(()=>{
        if(params.id){
            setMode(true);
            getPost(params.id);
        }else{
            handleSave();
            setAlertOpen(true)
            setMessage("Post draft has been created");   
        }
    },[params]);

    const getPost = (id) => {
        api.getPost(id)
        .then(res=>{
            if(res){
                setPostState({
                    ...res
                })
            }else{
                history.push('/404')
            }
        })
        .catch(console.error)
    }
    const handleSave = async () => {
        if(editMode){

            if(postState.title === ''){
                setAlertOpen(true)
                setMessage("Post has been not Updated");
                setSeverity("error");
            } else {
                let saveRes = await api.saveDraft(params.id,{...postState});
                if(saveRes){
                    setAlertOpen(true);
                    setMessage("Post has been Updated");
                    setSeverity("success");
                    getPost(params.id);
                    setRepublishable(true);
                    setListen(true);
                }
            }
            
        }else{
            try{
                let id = await api.createNewPost({...postState});
                console.log(id);
                history.push('/posts/edit/'+id);
            }catch(e){
                console.error(e);
            }
        }

    }

    const handlePublish = async () => {
        try {

            if(postState.title === ''){
                setAlertOpen(true)
                setMessage("Post has been not published");   
                setSeverity("error"); 
            } else {
                let publishRes = await api.publish(params.id);
            if(publishRes && addToHero && postState.featuredImage){
                if(postState.heroSlideId){
                    
                    await api.updateHeroSlide(
                        postState.heroSlideId,
                        postState.title,
                        params.id,
                        postState.featuredImage
                    );

                } else {
                    let heroRes = await api.createHeroSlide(
                        postState.title,
                        params.id,
                        postState.featuredImage
                    );


                    setPostState(state=>({
                        ...state,
                        heroSlideId: heroRes
                    }));
                    
                    await api.saveDraft(params.id,{
                        ...postState,
                        status: true,
                        heroSlideId: heroRes
                    });
                    
                }
            }
            if(publishRes){
                getPost(params.id);
                setRepublishable(false);
                dispatch(getAllPosts);
                alert.success('Notice has been published !');
                history.push('/posts/');
            }    
            }

            
        } catch (error) {
            console.log(error)
        }
    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    return (
        <Grid container spacing={2}>
            
            {/* Alerts */}
            <AlertBox 
                open={alertOpen}
                message={message}
                handleClose={handleCloseAlert}
                duration={3000}
                severity={severity}
            />
            
            <Grid item md={9}>
                <HTMLEditorContainer 
                    title={postState.title}
                    html={postState.html}
                    setPostState={setPostState}
                    handleSave={handleSave}
                    handlePublish={handlePublish}
                    republishable={republishable}
                    published={postState.status}
                    listen={listen}
                />
            </Grid>

            <Grid container item spacing={2} direction={"column"} md={3}>
                <Grid item>
                    <PostFeaturedPhoto 
                        addToHero={addToHero}
                        setAddToHero={setAddToHero}
                        url={postState.featuredImage?process.env.REACT_APP_API_BASE + '/file/' + postState.featuredImage:null} 
                        setPostState={setPostState}
                        postId={params.id}
                        />
                </Grid>
                <Grid item>
                    {
                    !!(postState.attachments)&&
                        <PostAttachments 
                            postId={params.id}
                            attachments={postState.attachments} 
                            setPostState={setPostState}
                            refreshData={()=>getPost(params.id)}
                            handleSave={handleSave}
                        />
                    }
                </Grid>
                <Grid item>
                    <PostProperties 
                        {...postState}
                    />
                </Grid>
            </Grid>

        </Grid>
    )
}