import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormGroup,
  InputLabel,
  DialogActions
} from '@material-ui/core';
import { PageTypesSelector, getPageTypes, createPage } from './PageSlice';
import { createNewPage } from '../../api/pages-api/v1';
import { useDispatch, useSelector } from 'react-redux';
import slugify from 'slugify';
import PageSelectorDialog from './PageSelectorDialog';
import { useHistory } from 'react-router-dom';

const makeBreadCrumbs = (parent, page) => {
  if (parent && parent.breadcrumbs && Array.isArray(parent.breadcrumbs)) {
    let newOne = parent.breadcrumbs[
      parent.breadcrumbs.length - 1
    ].breadcrumbs.map((b) => ({
      name: b.name,
      link: b.link,
      label: b.label
    }));
    return newOne.concat([
      { name: page.id, link: '/web/' + page.id, label: page.title }
    ]);
    // return parent.breadcrumbs.map((b)=>(
    //     {
    //         name: b.name,
    //         link: b.link,
    //         label: b.label
    //     }
    // )).push({
    //     name: page.id,
    //     link:'/web/' + page.id,
    //     label: page.title
    // })
  }
  return [
    {
      name: 'home',
      link: '/',
      label: 'Home'
    },
    {
      name: page.id,
      link: '/web/' + page.id,
      label: page.title
    }
  ];
};

export default function PageNewView({
  open,
  setOpen,
  setAlertOpen,
  pages,
  pageTypes
}) {
  const dispatch = useDispatch();
  const [isTable, setIsTable] = React.useState(false);
  const [showPageSelector, setShowPageSelector] = React.useState(false);
  const [parent, setParent] = React.useState(null);
  const [title, setTitle] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [error, setError] = React.useState('');

  const types = useSelector(PageTypesSelector);
  const history = useHistory();

  const handleCreateNewPage = async (e) => {
    e.preventDefault();
    let formdata = new FormData(e.target);
    let title = formdata.get('title');
    let type = formdata.get('type') || 4; // This is the Generic Type
    type = parseInt(type);
    let layout = formdata.get('layout');

    let data = {
      id: slug,
      title,
      slug: slug,
      layout,
      type,
      breadcrumbs: makeBreadCrumbs(parent, { id: slug, title }),
      html: '',
      sections: [],
      pageData: { links: [] },
      status: 0,
      publishedOn: null,
      parent: parent ? (parent.id === 'web' ? null : parent.id) : null
    };

    await createNewPage(data).then((response) => {
      console.log(response.data.code);
        if(response.data.code === 9100){  
          setError(response.data.message);
        } else {
          dispatch(createPage(data));
          setOpen(false);
          setAlertOpen(true);
          history.push('/pages/' + data.id);
        }
      });
      
  };

  const checkIsTable = (e) => {
    if (e.target.value === 'table') setIsTable(true);
    else setIsTable(false);
  };

  const populatePageTypes = () =>
    types.map((t, k) => (
      <MenuItem key={k} value={t.id}>
        {t.name}
      </MenuItem>
    ));

  const makeBreadcrumbs = () => {
    return (
      <ul style={{ padding: '1rem' }}>
        {parent &&
          parent.breadcrumbs &&
          parent.breadcrumbs.map((b, k) => {
            return (
              <li key={k} style={{ display: 'inline' }}>
                {' '}
                / {b ? b.id : 'web'}
              </li>
            );
          })}
        <li style={{ display: 'inline' }}>/{parent ? slug : 'web/' + slug}</li>
      </ul>
    );
  };

  useEffect(() => {
    dispatch(getPageTypes());
  }, []);

  useEffect(() => {
    setSlug(slugify(title, { lower: true }));
  }, [title]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <form onSubmit={handleCreateNewPage}>
        <DialogTitle>Create New Page</DialogTitle>
        <DialogContent>
          <FormGroup>
            <FormControl>
              {/* <InputLabel id="title-label">Title</InputLabel> */}
              <TextField
                fullWidth
                inputProps={{ maxLength: 241 }}
                error={title.length > 240}
                label="Page Title"
                name="title"
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <TextField
                fullWidth
                inputProps={{ maxLength: 241 }}
                error={slug.length > 240}
                label="Page Slug"
                name="slug"
                onChange={(e) => setSlug(e.target.value)}
                value={slug}
                required
              />
            </FormControl>

            <FormControl>
              <InputLabel id="layout-label">Page Layout</InputLabel>
              <Select
                onChange={checkIsTable}
                fullWidth
                labelId="layout-label"
                name="layout"
                required
              >
                <MenuItem value="one-col">No Navigation</MenuItem>
                <MenuItem value="two-col">With Side Navigation</MenuItem>
                <MenuItem value="table">Table Layout</MenuItem>
                <MenuItem value="dept-home">Department Home</MenuItem>
              </Select>
            </FormControl>
            {isTable && (
              <FormControl>
                <InputLabel id="data-type-label">Table Data Type</InputLabel>
                <Select
                  labelId="data-type-label"
                  name="type"
                  required={isTable}
                >
                  {!!types && populatePageTypes()}
                </Select>
              </FormControl>
            )}

            <FormControl>
              <Button
                variant="outlined"
                onClick={() => setShowPageSelector(true)}
              >
                Parent Page
              </Button>
              {makeBreadcrumbs}
              <PageSelectorDialog
                open={showPageSelector}
                setOpen={setShowPageSelector}
                dialogTitle={'Select Parent Page'}
                callback={setParent}
              />
            </FormControl>
            <p style={{color: 'red', textAlign: 'center'}}>{error}</p>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Create</Button>
          <Button type="reset" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
        
      </form>
    </Dialog>
  );
}
