import { IMAGE_API_BASE } from "src/api/imageHelper-v1API";

export function getThumbnail(file, sessionThumb, currentLink, link) {
    
    if(!!currentLink){
        let thumb = currentLink.image || currentLink.thumbnail;
        if(String(thumb).match('icons')) {
            return thumb;
        } else if (String(thumb).match('assets')) {
            return '/icons/' + String(thumb).split('/').pop();
        }else if (thumb.urlType === 'external' || thumb.urlType === 'link') {
            return '/icons/link.png'; // Get the favicon instead.
        }else {
            return process.env.REACT_APP_FRONTEND + '/api/file/' + thumb;
        }  
    }

    if(sessionThumb) {
        return `${IMAGE_API_BASE}/file/${sessionThumb}`
    } else {
        if (file !== null){

            const mime = file.type;
            if(String(mime).match('image')) return link;
            switch(mime){
                case 'application/pdf':
                    return '/icons/pdf.png';
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                    return '/icons/docx.png'
                case 'application/zip':
                    return '/icons/zip.png';
                case 'application/vnd.ms-excel':
                    return'/icons/xls.webp';
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    return'/icons/xls.webp';
                /**
                 * This special case is for when an existing attachment is opened
                 * */ 
                default:
                    return'/icons/file.png';
            }
        }

    }
}

