import { Button, Grid, makeStyles, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { Component } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePageData,
  errorSelector,
  getPageData
} from 'src/features/file-upload/fileSlice';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'inline-block',
    width: '92%',
    padding: '10px',
    borderRadius: '16px',
    marginBottom: '20px',
    '@media screen and (max-width: 600)': {
      height: '400px'
    }
  },
  p2: {
    display: 'block',
    flexDirection: 'row-reverse'
  }
}));

const FileRow = ({ obj }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { title, linkID } = obj;
  const deleteError = useSelector(errorSelector)
  const alert = useAlert()
  

  const handleDelete = (id) => {
    console.log(obj);
    if(window.confirm('Are you sure you want to delete this attachment?')){
      dispatch(deletePageData(id));
    if(deleteError === null){
      alert.success("Page Data deleting successful !")
    }else{
      alert.error("Fail !"+deleteError);
    }
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container flex spacing={1}>
        <Grid item xs={12}>
          <p>{title}</p>
        </Grid>
        <Grid item xs={4} className={classes.p2}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleDelete(linkID)}
          >
            delete
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FileRow;
