import Link from '@material-ui/core/Link';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authErrorSelector, login, resetError } from './authSlice';
import AdminMaterialTextField from '../../components/common/AdminMaterialTextField';
import StateAlertBox from '../../components/common/StateAlertBox';

const useStyles = makeStyles((theme) => ({
  forgotPwdDiv: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right'
    }
  }
}));


export default function LoginForm(props) {

  const classes = useStyles();

  const dispatch = useDispatch();

  const initState = {
    email: '',
    password: ''
  };

  return (
    <Container maxWidth="sm">
      <StateAlertBox messageSelector={authErrorSelector} resetMessage={resetError} severity={'error'}/>
      <Formik
        initialValues={initState}
        validationSchema={
          Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            password: Yup.string().max(255).min(8).required('password is required')
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          dispatch(login({
            email: values.email,
            password: values.password
          }));
          setSubmitting(false);
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => {
          const formProps = {
            handleBlur,
            errors,
            handleChange,
            touched,
            values
          };
          return (
            <form onSubmit={handleSubmit}>
              <Box mb={3} justifyContent="center">
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  Login
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  Sign in to the internal platform
                </Typography>
              </Box>

              <AdminMaterialTextField fieldName="email" label="Email Address" type="email" fullWidth margin="normal"
                                      variant="outlined" {...formProps} />
              <AdminMaterialTextField fieldName="password" label="Password" type="password" fullWidth margin="normal"
                                      variant="outlined" {...formProps} />
              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Login
                </Button>
              </Box>
              {/* <Grid
                container
                spacing={0}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Don&apos;t have an account?
                    {' '}
                    <Link
                      component={RouterLink}
                      to="/register"

                    >
                      Register
                    </Link>
                  </Typography>

                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.forgotPwdDiv}
                >
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Forgot Password?
                    {' '}
                    <Link
                      variant="body2"
                      component={RouterLink}
                      to="/forgot"
                    >
                      Click Here
                    </Link>
                  </Typography>
                </Grid>
              </Grid> */}


            </form>
          );
        }}
      </Formik>
    </Container>
  );
}
