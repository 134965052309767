import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import DrawerItem from './DrawerItem';


const useStyle = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default (props) => {
  const classes = useStyle();
  const { text, icon, list } = props;
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <DrawerItem text={text} icon={icon} onClick={() => {setOpen(!open)}} />
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {list.map((item, i) => {
            return <DrawerItem {...item} key={i} className={classes.nested} />;
          })}
        </List>
      </Collapse>
    </Fragment>
  )
};
