import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import AdminMaterialTextField from '../../components/common/AdminMaterialTextField';
import { Form, Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import { OPERATION_APPROVE, OPERATION_REJECT } from './constants';

export default function RemarkDialog(props) {

  const { open, handleClose, handleSubmit, operation, approval } = props;

  const initRemark = {
    remark: ''
  };

  const getTitle = (type) => {
    if (!type) return null;
    switch (type) {
      case OPERATION_APPROVE:
        return 'Approve';
      case OPERATION_REJECT:
        return 'Reject';
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="md">
      <Formik
        initialValues={initRemark}
        validationSchema={
          Yup.object().shape({
            remark: Yup.string().min(1).required('Remark is Required')
            // name: Yup.string().min(5).required('Name is required'),
            // permissions: Yup.array(Yup.object()).min(1)
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await handleSubmit(operation, {
              ...approval,
              remark: values.remark
            });
          } catch (e) {
            console.log(e);
          } finally {
            handleClose();
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => {
          const formProps = {
            handleBlur,
            errors,
            handleChange,
            touched,
            values
          };
          return (
            <Form>
              <DialogTitle>
                {getTitle(operation)}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <AdminMaterialTextField fieldName="remark" label="Remark" fullWidth margin="normal"
                                          variant="outlined" multiline rows={3} {...formProps} />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary">
                  Cancel
                </Button>
                <Button disabled={isSubmitting} onClick={handleSubmit} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
