import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React, {Fragment, useEffect, useState } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from 'react-router';
import CustomToolbarSelect from 'src/components/common/CustomToolbarSelect';
import {
    getAllNotices,
    noticesSelector
} from './noticesSlice'
import AlertBox from 'src/components/common/AlertBox';

import api from '../../api/procurement-notices-api';


export default function NoticesTableView() {
    const dispatch = useDispatch();
    const history = useHistory();
    const notices = useSelector(noticesSelector);
    const [alertOpen,setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");

    const onDeleteItems = (indexes) => {

            Promise.all(
                indexes.map( (index)=>{
                    let notice = notices.find(notice=>{
                        return notice.id === index;
                    });
                    return api.deleteNotice(index)
                }),
            )
            .then(()=>{
                setAlertOpen(true);
                setMessage("Notices have been deleted.");
                dispatch(getAllNotices());
            })
            .catch(console.error);
    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
      };


    const columns = [
        {
            name:'id',
            label:"ID"
        },
        {
            name:'title',
            label:"Title"
        },
        {
            name:'createdOn',
            label:'Created On'
        },
        {
            name: 'status',
            label: 'Status',
            options:{
                customBodyRender: status => {
                    return <p>{status?'Published':'Draft'}</p>
                }
            }
        },
        {
            name:'id',
            label: 'Actions',
            options:{
                customBodyRender: (id) => {
                    return <Button
                        onClick={()=>handleNoticeOpen(id)} 
                        variant="contained" 
                        color="primary"
                        >Open</Button>
                }
            }
        }
    
    ]

    const options = {
        customToolbar: () => {
            return (
              <Fragment>
                <AlertBox 
                    open={alertOpen}
                    message={message}
                    duration={3000}
                    handleClose={handleCloseAlert}
                />
                <Tooltip title={'Add Item'}>
                 <Button 
                    variant="contained" 
                    color="primary"
                    onClick={()=>{
                        history.push("/notices/new")
                    }}
                    >New</Button>
                </Tooltip>
              </Fragment>
            );
          },

          customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
            console.log({_displayData,selectedRows});
            
            let selectedDisplayData = _displayData.filter(notice=>{
                return selectedRows.lookup[notice.dataIndex]
            });
            
            let selectedNotices = {
                data: selectedDisplayData.map((notice,index)=>{
                    return {
                        dataIndex:notice.data[0],
                        index
                    }
                })
            }
            console.log(selectedNotices);
            return (
              <CustomToolbarSelect selectedRows={selectedNotices} setSelectedRows={setSelectedRows}
                                   onDeleteItems={onDeleteItems} />);
          },
    }

    const handleNoticeOpen = id => {
        history.push("/notices/edit/"+id);
    }

    useEffect(()=>{
        dispatch(getAllNotices());
    },[])
    return <MUIDataTable title={'Notices'} columns={columns} data={notices} options={options}/>;
}