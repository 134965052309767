import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ButtonGroup
} from '@material-ui/core';
import { SettingsPowerRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export function CropImage({
    image,
    open,
    setOpen,
    aspect,
    setCropped,
    blob
}) {

    // const [crop,setCrop] = useState({aspect: aspect?aspect:16/9});
    const [crop,setCrop] = useState({aspect: aspect?aspect:233/81});
    const [original,setOriginal] = useState(null);

    useEffect(()=>{
        if(original) console.log(original.width)
    },[original])

    const getCroppedImage = async () => {
        if(!!!original) return;
        let reader = new FileReader();
        let result = await getCroppedImg(original,crop,'croppedImage');
        reader.onload = () => {
            if(blob){
                setCropped({
                    blob: result,
                    uri:reader.result
                });
            }else{
                setCropped(reader.result);
            }
            setOpen(false);
        }
        if(result) reader.readAsDataURL(result);
    }

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle>
                Crop Image
            </DialogTitle>
            <DialogContent>
                <ReactCrop 
                    src={image} 
                    crop={crop}
                    onChange={newCrop => setCrop(newCrop)}
                    onImageLoaded={setOriginal}
                    />
            </DialogContent>
            <DialogActions>
                    <Button 
                        onClick={getCroppedImage}
                        variant="contained"
                        color="primary"
                    >Save</Button>
                    <Button 
                        onClick={()=>setOpen(false)}
                        variant="contained"
                        color="primary"
                    >Cancel</Button>
            </DialogActions>
        </Dialog>
    ) 
    

}



/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
 function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
   
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
   
    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');
   
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        // blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg', 1);
    });
  }
   
 