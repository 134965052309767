import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import HTMLEditorContainer from "./components/HtmlEditorContainer";
import HeroSliderFeaturedPhoto from './components/HeroSliderFeaturedPhoto';
import {  useParams, useHistory } from 'react-router';
import api from '../../api/posts-api'
import AlertBox from 'src/components/common/AlertBox';

export default function HeroSlidersEditorView() {
    const params = useParams();
    const history = useHistory();
    const [editMode,setMode] = useState(false);
    const [alertOpen,setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");
    const [severity, setSeverity] = useState("success");
    const [sliderState,setSliderState] = useState({
        title:'IWWRMP',
        description:'Integrated Watershed & Water Resources Management Project',
        featuredImage:'',
        actionLink:'',
        actionLabel: 'Read More!',
    });

    useEffect(()=>{
        if(params.id){
            setMode(true);
            getSlider(params.id);
        }
    },[params]);

    const getSlider = (id) => {
        api.getHeroSlider(id)
        .then(res=>{
            if(res){
                setSliderState({
                    title: res.title,
                    description: res.description,
                    featuredImage: res.img_URL,
                    actionLink: res.actionLink,
                    actionLabel: res.actionLabel,
                })
            }else{
                history.push('/404')
            }
        })
        .catch(console.error)
    }
    const handleSave = async () => {
        if(editMode){
            
            const data = {
                title: sliderState.title ? sliderState.title : ' ',
                description: sliderState.description,
                img_URL: sliderState.featuredImage,
                pressRelease: false,
                actionLink: sliderState.actionLink,
                actionLabel: sliderState.actionLabel
            }

            let saveRes = await api.updateHero(params.id, data);
            if(saveRes === 'OK'){
                setAlertOpen(true);
                setMessage("Post has been Updated");
                setSeverity("success");
                getSlider(params.id);
            }

        }else{
            try{
                const data = {
                    title: sliderState.title ? sliderState.title : ' ',
                    description: sliderState.description,
                    img_URL: sliderState.featuredImage,
                    pressRelease: false,
                    actionLink: sliderState.actionLink,
                    actionLabel: sliderState.actionLabel
                }
                let id = await api.createHero(data);
                getSlider(id);
                history.push('/sliders');
            }catch(e){
                console.error(e);
            }
        }

    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    return (
        <Grid container spacing={2}>
            
            {/* Alerts */}
            <AlertBox 
                open={alertOpen}
                message={message}
                handleClose={handleCloseAlert}
                duration={3000}
                severity={severity}
            />
            
            <Grid item md={12}>
                <HTMLEditorContainer 
                    title={sliderState.title}
                    description={sliderState.description}
                    actionLink={sliderState.actionLink}
                    actionLabel={sliderState.actionLabel}
                    editMode={editMode}
                    setSliderState={setSliderState}
                    handleSave={handleSave}
                />
            </Grid>

            <Grid container item direction={"row"} md={12}>
                <Grid item>
                    <HeroSliderFeaturedPhoto 
                        url={sliderState.featuredImage? process.env.REACT_APP_API_BASE + '/file/' + sliderState.featuredImage:null} 
                        setSliderState={setSliderState}
                        />
                </Grid>
            </Grid>

        </Grid>
    )
}