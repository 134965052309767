import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

// initial state of the auth slice
const initialState = {
  loading: false,
  permissions: [],
  hasErrors: false
};

// async thunk action
export const getPermissions = createAsyncThunk(
  'permission/getPermissions',
  (_, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    return api.getPermissions(token);
    // console.log(credentials)
    // make api call
  }
)

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {},
  extraReducers: {
    [getPermissions.pending]: (state) => {
      state.loading = true;
    },
    [getPermissions.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.permissions = [];
    },
    [getPermissions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.permissions = payload;
    }
  }
});

// actions
// export const { } = permissionSlice.actions;

// selectors <- getters
export const permissionsSelector = store => {
  return store.permission.permissions;
};

// reducer
export default permissionSlice.reducer;
