import React, { Component, useState } from 'react';
import { Card, CardActionArea, CardMedia, Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useRef } from 'react';
import ImageUpload from './ImageUploader';
import { Delete, Collections, CloudUpload, DoneAllOutlined } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelector, setCurrentThumbnail, thumbnailSelector } from '../fileSlice';
import { useEffect } from 'react';
import { getThumbnailLink } from 'src/api/imageHelper-v1API';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
  card: {
    '&:hover': {
      //   background:'red',
    }
  }
});


const cardSelect = {
  boxShadow: '2px 4px 30px 0px #4d8be8',
  border: '5px solid',
  borderColor: '#4d8be8',
  maxWidth: '205px',
  opacity: '0.5'
};

const PreviousImageList = () => {
  
  const thumbnails = useSelector(thumbnailSelector);
  const {thumbnail:current} = useSelector(sessionSelector);

  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showAlert, setshowAlert] = useState(null);
  const dispatch = useDispatch();

  useEffect(()=>{
    const imgs = getThumbnailLink(thumbnails)
    setImages(imgs);
    // console.log(imgs);
  },[])
  
  useEffect(()=>{
    const imgs = getThumbnailLink(thumbnails)
    setImages(imgs);
    // setshowAlert(<Alert severity="success">New image uploaded!</Alert>);
  },[thumbnails])

  const handleSelect = (i, id) => {
    setSelected(i);
    dispatch(setCurrentThumbnail(id));
    setshowAlert(<Alert severity="success">Click "Done" to finish !</Alert>);
  };
  const handleClose = () =>{
    setOpen(false);
    dispatch(setCurrentThumbnail(null));
    setSelected(null);
  }
  const handleDone = () => {
    if(current === 'SELECTED'){
      setshowAlert(<Alert severity="info">Please click "Add" button to upload your selected image!</Alert>);
    }else{
      setOpen(false);
    }
  }

  const populateCards = () => {
    return images.map((img, i) => {
      return (
        <Grid item className={classes.card} key={img.id}>
          <Card
            style={{ width: '180px', height: '140px', ...(selected === i && cardSelect) }}
            key={img.id}
          >
            <CardActionArea onClick={(e) => handleSelect(i++, img.id)}>
              <CardMedia
                component="img"
                alt={img.id}
                height="140"
                image={img.path}
              />
            </CardActionArea>
          </Card>
        </Grid>
      );
    });
  };

  const classes = useStyles();
  const handleViewPreUploadList = () =>{
    setOpen(true);
    setshowAlert('');
  }

  return (
    <>
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={() => handleViewPreUploadList()}
        className={classes.button}
        startIcon={<CloudUpload />}
      >
        Change Thumbnail
      </Button>
      <Dialog open={open} onClose={() => handleClose()} maxWidth={400}>
        <DialogTitle>Click on Change Thumbnail</DialogTitle>
        <DialogContent className={classes.root}>
          <Grid container className={classes.container} spacing={2}>
            {/* {populateCards()} */}
          </Grid>
          <br />
          <br />
          <ImageUpload setshowAlert={setshowAlert}/>
          <br />
          {showAlert?showAlert:''}
        </DialogContent>
        <Button
        variant="text"
        color="primary"
        size="large"
        onClick={() => handleDone()}
        className={classes.button}
        startIcon={<DoneAllOutlined style={{fontSize:'25px'}} />}
      >
        Done
      </Button>
      </Dialog>
    </>
  );
};

export default PreviousImageList;
