import React, {useState} from 'react';
import { 
    makeStyles,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    IconButton,
    ListItemIcon
    } from '@material-ui/core';

import { AddTwoTone, DeleteOutlined, Fullscreen} from '@material-ui/icons';
import { ListItemSecondaryAction } from '@material-ui/core';
import NoticeAttachmentModel from './NoticeAttachmentModal';
import api from '../../../api/procurement-notices-api';
import { Avatar } from '@material-ui/core';
import AlertBox from 'src/components/common/AlertBox';


const useStyles = makeStyles(theme=>({
    paper: {
        padding:theme.spacing(2),
    },
    input: {
        display:'none'
    }
}))


export default function NoticeAttachments({
    noticeId,
    attachments,
    setNoticeState,
    refreshData,
    handleSave
}) {
    const classes = useStyles();

    const [open,setOpen] = useState(false);
    const [file,setFile] = useState(null);
    const [alertOpen,setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");

    const setAttachments = (data) => {
        setNoticeState(state=>({
            ...state,
            attachments: data
        }))
    }
   
    const handleSelectFile = (e) => {
        handleSave();
        setOpen(true);
        setFile(e.target.files[0])
    };

    const handleRemoveFile = (index) => {
       if(window.confirm('Are you sure you want to delete this attachment?')){
        api.deleteNoticeAttachment(index)
        .then(()=>{
             setAlertOpen(true)
             setMessage("Attachment has been deleted!");
            refreshData();
        })
        .catch(console.error);
       }
    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    const refresh = () => {
        refreshData();
    }

    return (
        <>
        {/* Alerts */}
        <AlertBox 
            open={alertOpen}
            message={message}
            handleClose={handleCloseAlert}
            duration={3000}
        />
        <Paper className={classes.paper}>
            <Grid container direction="column" justify="center">
                <Grid item>
                    <Typography>Attachments</Typography>
                </Grid>
            </Grid>
                <List dense>
                    <label htmlFor="add-attachment-file">
                        <ListItem button>
                            <ListItemIcon>
                                <AddTwoTone/>
                            </ListItemIcon>
                            <ListItemText primary="Attach File"/>
                        </ListItem>
                    </label>
                    <input 
                        className={classes.input}
                        id="add-attachment-file"
                        type="file"
                        accept="*"
                        onChange={handleSelectFile}    
                    />
                    {
                        (!!attachments.length)&&
                        attachments.map((file,id) => {
                            return (
                                <ListItem button key={id}>
                                     <ListItemIcon>
                                         <Avatar src={file.thumbnail} > 
                                            <Avatar src={process.env.REACT_APP_API_BASE + '/file/' + file.thumbnail}/>
                                         </Avatar>
                                     </ListItemIcon>
                                <ListItemText 
                                    primary={file.title}
                                />
                                <ListItemSecondaryAction onClick={()=>handleRemoveFile(file.id)}>
                                    <IconButton>
                                        <DeleteOutlined/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>)
                        })
                    }
                </List>
        </Paper>
        <NoticeAttachmentModel 
            noticeId={noticeId} 
            file={file} 
            open={open} 
            setOpen={setOpen}
            refreshData={refresh}/>
        </>
    )
}