import { FormGroup } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
 } from '@material-ui/core';
import { nanoid } from 'nanoid';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createSection, deleteSection } from './PageSlice';

const createUniqueAnchor = (label,sections) => {
    let str = String(label).toLowerCase();
    if(sections && sections instanceof Array) {
        if(sections.some(s=>String(s.anchor).toLowerCase() === str)) {
            str = str + '-' + nanoid();
        } else {
            return encodeURI(str);
        }
    } else {
        throw new Error ('Sections not supported')
    }
}

export default function PageSectionView({open,setOpen,pageId,section,sections,onUpdate,files,setAlertOpen}) {

    const dispatch = useDispatch();

    const [title,setTitle] = React.useState();
    const [root,setRoot] = React.useState();
    const [id,setId] = React.useState(); 


    useEffect(()=>{
        if(section){
            setTitle(section.label);
            setId(section.id);
            setRoot(section.root);
        }else{
            setTitle('Create New Section')
            setId(nanoid());
        }
    },[section,sections]);

    const saveSection = () => {
        let data = {
            id: id,
            label: title,
            html:section?section.html:'',
            anchor:section?section.anchor:createUniqueAnchor(title,sections)
        }
        if(section) {
            let updatedSections = sections.map(s=>{
                if(s.id === id) {
                    return {
                        ...s,
                        label: title,
                        anchor: createUniqueAnchor(title,sections),
                        root: root
                    }
                } else return s;
            })
            onUpdate(updatedSections);

        } else {
           dispatch(createSection({data, id:pageId}));
        }
        setOpen(false);
        setAlertOpen(true);
    }

    const deleteThisSection = () => {
        if(window.confirm('The Section and will be deleted along with all attachments. Proceed?')){
            dispatch(deleteSection({
                pageId: pageId,
                sectionId:id,
                attachments:files}));
            setOpen(false);
            setAlertOpen(true);
        }
    }

    return (
        <Dialog open={open}  title={title} onClose={()=>setOpen(false)} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <FormGroup>
                    <FormControl>
                        <TextField 
                            required
                            value={title} 
                            onChange={(e)=>setTitle(e.target.value)}
                            label="Section Title"
                            inputProps={{ maxLength: 230 }}
                            />
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox 
                            checked={root}
                            onChange={(e)=>setRoot(e.target.checked)}
                            />}
                        label="Appear before all."
                    />
                </FormGroup>

            </DialogContent>
            <DialogActions>
                <Button onClick={saveSection}>{section?'Update':'Create'}</Button>
                {!!section&&<Button onClick={deleteThisSection}>Delete</Button>}
                <Button onClick={()=>setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}