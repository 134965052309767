import React, { Fragment, useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import MUIDataTable from 'mui-datatables';
import CustomToolbarSelect from '../../components/common/CustomToolbarSelect';
import UserEditDialog from './UserEditDialog';
import { useSelector } from 'react-redux';
import { rolesSelector } from '../role/roleSlice';
import Avatar from '@material-ui/core/Avatar';

// Should be the same as the index in the columns variable
// const MOBILE_NUMBER_INDEX = 5;

export default function UserDataTable(props) {

  const { items, addItem, removeItem, editItem } = props;

  const roles = useSelector(rolesSelector);

  const editedItemTemplate = {
    id: -1,
    avatarLink: '',
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    status: '',
    role: '',
    createdAt: '',
    createdBy: '',
    modifiedAt: '',
    modifiedBy: ''
  };

  const [open, setOpen] = useState(false);
  const [editedItem, setEditedItem] = useState(editedItemTemplate);

  useEffect(() => {
    console.log(items);
  }, []);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: {
        // display: 'excluded'
        sort: false,
        searchable: true,
        customBodyRender: (id, tableMeta, updateValue) => {
          return <div>{id ? id : ''}</div>;
        }
      }
    },
    {
      label: 'Avatar',
      name: 'avatarLink',
      options: {
        sort: false,
        searchable: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Avatar src={value}/>;
        }
      }
    },
    {
      label: 'First Name',
      name: 'firstName',
      options: {
        searchable: true
      }
    },
    {
      label: 'Last Name',
      name: 'lastName',
      options: {
        searchable: false
      }
    },
    {
      label: 'Email',
      name: 'email',
      options: {
        searchable: false
      }
    },
    {
      label: 'Mobile Number',
      name: 'mobileNumber',
      options: {
        searchable: false
      }
    },
    {
      label: 'Status',
      name: 'status',
      options: {
        searchable: false
      }
    },
    {
      label: 'Role',
      name: 'role',
      options: {
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{value ? value.name : ''}</div>;
        }
      }
    },
    {
      label: 'Created By',
      name: 'createdBy',
      options: {
        searchable: false,
        display: 'excluded'
      }
    },
    {
      label: 'Created At',
      name: 'createdAt',
      options: {
        searchable: false,
        display: 'excluded'
      }
    },
    {
      label: 'Modified By',
      name: 'modifiedBy',
      options: {
        searchable: false,
        display: 'excluded'
      }
    },
    {
      label: 'Modified At',
      name: 'modifiedAt',
      options: {
        searchable: false,
        display: 'excluded'
      }
    }
  ];


  const getItemsByIndex = (index) => {
    return items[index];
  };

  const onEditItem = (index) => {
    const item = getItemsByIndex(index);
    setEditedItem(item);
    setOpen(true);
  };

  const onDeleteItems = (indexes) => {
    indexes.map((index) => (getItemsByIndex(index))).map((item) => {
      removeItem(item.id);
    });
  };

  const onSaveItem = async (data) => {
    if (data.id === -1) {
      return addItem(data);
    }
    editItem(data.id, data);
  };

  const options = {
    filterType: 'checkbox',
    // expandableRows: true,
    expandableRowsHeader: false,
    // expandableRowsOnClick: true,
    // isRowExpandable: (dataIndex, expandedRows) => {
    //   return !(expandedRows.data.length > 4 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0);
    // },
    // rowsExpanded: [],
    // renderExpandableRow: (rowData, rowMeta) => {
    //   const mobileNumber = rowData[MOBILE_NUMBER_INDEX];
    //   return (
    //     <TableRow>
    //       <TableCell colSpan={2}>
    //         <b>Mobile Number</b>
    //       </TableCell>
    //       <TableCell colSpan={rowData.length - 1}>
    //         {mobileNumber}
    //       </TableCell>
    //     </TableRow>
    //   );
    // },
    customToolbar: () => {
      return (
        <Fragment>
          <Tooltip title={'Add Item'}>
            <IconButton onClick={() => {
              setEditedItem(editedItemTemplate);
              setOpen(true);
            }}>
              <AddIcon/>
            </IconButton>
          </Tooltip>
        </Fragment>
      );
    },
    customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
      return (
        <CustomToolbarSelect selectedRows={selectedRows} setSelectedRows={setSelectedRows}
                             onEditItem={onEditItem} onDeleteItems={onDeleteItems}/>);
    },
    // serverSide: true,
    // onTableInit: (action, tableState) => {
    //   console.log(tableState);
    // },
    // onTableChange: (action, tableState) => {
    //   console.log(tableState);
    //   console.log(action);
    // }
  };

  return (
    <Fragment>
      <UserEditDialog open={open} handleClose={() => {
        setOpen(false);
      }} handleSubmit={onSaveItem} user={editedItem} roles={roles}/>
      <MUIDataTable title={'Users'} data={items} columns={columns} options={options}/>
    </Fragment>
  );

}
