import React, { Component } from 'react';
import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@material-ui/core';
import TableHeaders from 'src/components/tables/tableHeaders';
import TableContent from './tableContent';
import {
  ecoIndicatorSelector,
  editSection,
  getSections,
  loadingSelector
} from '../ecoIndicatorSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import DateInput from 'src/components/common/DateInput';

const IndicatorsCard = (props) => {
  const store = useSelector(ecoIndicatorSelector);
  const loading = useSelector(loadingSelector);
  const section =
    !!store && store.length > 0 ? store[props.secKey] : props.section;
  const [columns, setcolumns] = useState(section.columns);
  const dispatch = useDispatch();
  const alert = useAlert();
  const [isEdit, setisEdit] = useState(true);
  const [subTitle, setSubTitle] = useState('');

  useEffect(() => {
    dispatch(getSections());
  }, [isEdit]);

  const handleUpdate = () => {
    const key = '2';
    const newSection = { ...section, columns: columns, subtitle: subTitle };
    dispatch(editSection({ key, newSection }));
    if (loading) {
      alert.error('fail.. please try again');
    } else {
      alert.success(section.title + ' Update successful !');
    }
  };

  const handleOnChange_Exchange = (obj, e) => {
    const { value } = e.target;
    const cols = [...columns];
    const newCols = cols.map((col) =>
      col.col_index === obj.colIndex
        ? {
            ...col,
            rows: col.rows.map((row) =>
              row.rowIndex === obj.rowIndex ? { ...obj, rowValue: value } : row
            )
          }
        : col
    );
    setcolumns([...newCols]);
    setisEdit(false);
  };

  return (
    <React.Fragment>
      {/* <h2> ExchangeRate loading card component </h2> */}
      <Card style={{ width: 600, height: 410, borderRadius: 50 }}>
        <CardContent>
          <Typography variant="h4" component="h2" style={{ marginLeft: 50 }}>
            {section.title}
          </Typography>
          <Typography variant="h6" component="h2" style={{ marginLeft: 50 }}>
            {props?.subTitle ? (
              <DateInput getDate={setSubTitle} initValue={section.subtitle} />
            ) : (
              section.subtitle
            )}
          </Typography>
          <br />
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHeaders
                columns={columns}
                id="col_index"
                name="col_name"
              ></TableHeaders>
              <TableBody>
                <TableContent
                  // columns={columns}
                  onChange={handleOnChange_Exchange}
                  type={section.section_key}
                  section={section}
                />
              </TableBody>
            </Table>
          </TableContainer>

          <div style={{ float: 'right', padding: 12 }}>
            <Button
              style={{ width: 155 }}
              variant="contained"
              size="large"
              color="primary"
              disabled={isEdit}
              onClick={() => {
                handleUpdate();
              }}
            >
              UPDATE
            </Button>
          </div>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default IndicatorsCard;
