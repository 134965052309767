import { Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import {
    Paper,
    makeStyles,
    FormControl,
    TextField,
    Grid,
    Typography,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import Switch from '@material-ui/core/Switch';
import React, { Fragment, useEffect, useState } from 'react';
import AlertBox from 'src/components/common/AlertBox';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import CustomToolbarSelect from 'src/components/common/CustomToolbarSelect';
import {
    getAllNews,
    newslineSelector
} from './newslineSlice';

import api from '../../api/posts-api';

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            padding: '1rem',
            marginBottom: '1rem'
        },
        textField: {
            width: '500px'
        },
        error: {
            color: 'red'
        }
    };
});

export default function NewsroomTableView() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const newsline = useSelector(newslineSelector);
    const [addAddEditNews, setAddEditNews] = useState('');
    const [news, setNews] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");
    const [severity, setSeverity] = useState("success");
    const [currentNews, setCurrentNews] = useState('');

    useEffect(() => {
        dispatch(getAllNews());
    }, []);

    const onDeleteItems = (indexes) => {

        Promise.all(
            indexes.map((index) => {
                let news = newsline.find(news => {
                    return news.newsLineId === index;
                });
                return api.removeNews(news.newsLineId)
            }),
        )
            .then(() => {
                dispatch(getAllNews());
            })
            .catch(console.error);
    }

    const handleAddNews = () => {
        setAddEditNews('add');
    };

    const handleEditNews = async (id) => {
        setAddEditNews('edit');
        try {
            let res = await api.getAllNewsById(id);
            setCurrentNews(res);
            setNews(res);
        }
        catch (error) {

        }
    };

    const handleCreateUpdateNewsline = async (type) => {
        if (type === 'create') {
            const payload = {
                newsLineDescription: news,
                status: true
            }

            let saveRes = await api.createNews(payload);
            if (saveRes === 'Successfully added') {
                setAlertOpen(true);
                setMessage(saveRes);
                setSeverity("success");
            }
            else {
                setAlertOpen(true);
                setMessage(saveRes);
                setSeverity("error");
            }
        }
        else if (type === 'update') {
            const updatePayload = {
                newsLineId: currentNews.newsLineId,
                newsLineDescription: news,
                status: currentNews.status === true ? true : false
            }

            let saveRes = await api.updateNews(updatePayload);
            if (saveRes === 'Successfully updated') {
                setAlertOpen(true);
                setMessage(saveRes);
                setSeverity("success");
            } else {
                setAlertOpen(true);
                setMessage(saveRes);
                setSeverity("error");
            }
        }
        window.location.reload();
    };

    const handleStatusChange = async (status, newsLineId) => {
        const updatePayload = {
            newsLineId: newsLineId?.rowData[0],
            newsLineDescription: newsLineId?.rowData[2],
            status: status === true ? false : true
        }

        let saveRes = await api.updateNews(updatePayload);
        if (saveRes === 'Successfully updated') {
            setAlertOpen(true);
            setMessage(saveRes);
            setSeverity("success");
        } else {
            setAlertOpen(true);
            setMessage(saveRes);
            setSeverity("error");
        }
    };

    const columns = [
        {
            name: 'newsLineId',
            label: "ID"
        },
        {
            name: 'createdDate',
            label: 'Date',
            options: {
                customBodyRender: createdDate => {
                    return createdDate?.month + " " + createdDate?.dayOfMonth + " " + createdDate?.year 
                        + ", " + createdDate?.hour + ":" + createdDate?.minute;
                }
            }
        },
        {
            name: 'newsLineDescription',
            label: 'News',
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRender: (status, newsLineId) => {
                    return <Switch defaultChecked={status} onChange={() => handleStatusChange(status, newsLineId)} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />
                }
            }
        },
        {
            name: 'newsLineId',
            label: 'Actions',
            options: {
                customBodyRender: (newsLineId) => {
                    return <div style={{ cursor: 'pointer' }}><EditIcon onClick={() => handleEditNews(newsLineId)} >Open</EditIcon></div>
                }
            }
        }

    ]

    const options = {
        filter: false,
        customToolbar: () => {
            return (
                <Fragment>
                    <Tooltip title={'Add Item'}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddNews}
                        >New</Button>
                    </Tooltip>
                </Fragment>
            );
        },

        customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
            console.log({ _displayData, selectedRows });

            let selectedDisplayData = _displayData.filter(news => {
                return selectedRows.lookup[news.dataIndex]
            });

            let selectedNews = {
                data: selectedDisplayData.map((news, index) => {
                    return {
                        dataIndex: news.data[0],
                        index
                    }
                })
            }
            return (
                <CustomToolbarSelect selectedRows={selectedNews} setSelectedRows={setSelectedRows}
                    onDeleteItems={onDeleteItems} />);
        },
    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };


    return (
        <>
            <AlertBox
                open={alertOpen}
                message={message}
                handleClose={handleCloseAlert}
                duration={3000}
                severity={severity}
            />
            {addAddEditNews === 'add' && (
                <Paper className={classes.paper}>
                    <Typography variant="h5">Add News</Typography>
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormControl>
                                <TextField
                                    id="addNews"
                                    label=""
                                    type="text"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => {
                                        setNews(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCreateUpdateNewsline('create')}>
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}
            {addAddEditNews === 'edit' && (
                <Paper className={classes.paper}>
                    <Typography variant="h5">Edit News</Typography>
                    <Grid container spacing={3}>
                        <Grid item>
                            <FormControl>
                                <TextField
                                    id="editNews"
                                    label=""
                                    type="text"
                                    value={news.newsLineDescription}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => {
                                        setNews(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCreateUpdateNewsline('update')}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}

            {<MUIDataTable title={'Newsline'} columns={columns} data={newsline} options={options} />}
        </>
    );
}