import React, { Component } from 'react';
import { Button } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IndicatorComponent from './indicatorComponent';


//material UI theme customize
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const IndicatorComponentList = (props) => {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();
  

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          alignItems="baseline"
          justify="flex-start"
          spacing={spacing}
        >
          {!!!props.data ? "" : props.data.map((sec) => {
            return <IndicatorComponent key={sec.title+sec.subtitle} object={sec} onClick={props.onClick}/>;
          })}
          
        </Grid>
      </Grid>
    </Grid>
  );

  // return <h1>Hello</h1>
};

export default IndicatorComponentList;
