import React from 'react';
import LoginForm from './LoginForm';
import AuthWrapperComponent from './AuthWrapperComponent';

export default function LoginView(props) {
  return (
    <AuthWrapperComponent
      image="/images/iwwrmp-login.jpg" title="Login">
      <LoginForm/>
    </AuthWrapperComponent>
  );
}
