import HomeView from './features/home/HomeView';
import Error404View from './components/Error404View';
import RegisterView from './features/auth/RegisterView';
import LoginView from './features/auth/LoginView';
import ResetPasswordView from './features/auth/ResetPasswordView';
import ForgotPasswordView from './features/auth/ForgotPasswordView';
import { Redirect } from 'react-router-dom';
import React from 'react';
import RoleListView from './features/role/RoleListView';
import ProfileView from './features/profile/ProfileView';
import UserListView from './features/user/UserListView';
import UserApprovalListView from './features/approval/ApprovalListView';
import PostEditorView from './features/posts/PostEditorView';
import PostsTableView from './features/posts/PostsTableView';
import PageListView from './features/cms-pages/PageListView';
import PageView from './features/cms-pages/PageView';
import FileViewMain from './features/file-upload/FileViewMain';
import EcoIndicatorListView from './features/economicIndicators/ecoIndicatorListView';
import NoticesTableView from './features/procurement-notices/NoticesTableView';
import NoticeEditorView from './features/procurement-notices/NoticeEditorView';
import HeroSlidersTableView from './features/hero-slider/HeroSlidersTableView';
import HeroSlidersEditorView from './features/hero-slider/HeroSlidersEditorView';
import AuditView from './features/audit/AuditView';
import NewslineTableView from './features/newsline/NewslineTableView';
import ChartTableView from './features/chart/ChartTableView';
import ChartEditorView from './features/chart/ChartEditorView';
import ProjectMapTableView from './features/project-map/ProjectMapTableView';
import ProjectMapEditorView from './features/project-map/ProjectMapEditorView';


const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/login',
    exact: true,
    notLogged: true,
    component: LoginView
  },
  {
    path: '/register',
    exact: true,
    notLogged: true,
    component: RegisterView
  },
  {
    path: '/reset',
    exact: true,
    notLogged: true,
    component: ResetPasswordView
  },
  {
    path: '/forgot',
    exact: true,
    notLogged: true,
    component: ForgotPasswordView
  },
  {
    path: '/dashboard',
    exact: true,
    logged: true,
    notLogged: true,
    component: HomeView
  },
  {
    path: '/roles',
    exact: true,
    logged: true,
    notLogged: true,
    component: RoleListView
  },
  {
    path: '/profile',
    exact: true,
    logged: true,
    notLogged: true,
    component: ProfileView
  },
  {
    path: '/users',
    exact: true,
    logged: true,
    notLogged: true,
    component: UserListView
  },
  {
    path: '/approvals',
    exact: true,
    logged: true,
    notLogged: true,
    component: UserApprovalListView
  },
  {
    path: '/posts',
    exact: true,
    logged: true,
    component: PostsTableView
  },
  {
    path: '/posts/new',
    exact: true,
    logged: true,
    component: PostEditorView
  },
  {
    path: '/posts/edit/:id',
    exact: true,
    logged: true,
    component: PostEditorView
  },
  {
    path: '/sliders',
    exact: true,
    logged: true,
    component: HeroSlidersTableView
  },
  {
    path: '/sliders/new',
    exact: true,
    logged: true,
    component: HeroSlidersEditorView
  },
  {
    path: '/sliders/edit/:id',
    exact: true,
    logged: true,
    component: HeroSlidersEditorView
  },
  {
    path: '/pages',
    exact: true,
    logged: true,
    component: PageListView
  },
  {
    path: '/pages/:id',
    exact: true,
    logged: true,
    component: PageView
  },
  {   
    path: '/file', 
    exact: true, 
    logged: true, 
    component: FileViewMain 
  },
  {
    path: '/ecconomic-indicators',
    exact: true,
    logged: true,
    notLogged: true,
    component: EcoIndicatorListView
  },
  {
    path: '/notices',
    exact: true,
    logged: true,
    component: NoticesTableView
  },
  {
    path: '/notices/new',
    exact: true,
    logged: true,
    component: NoticeEditorView
  },
  {
    path: '/notices/edit/:id',
    exact: true,
    logged: true,
    component: NoticeEditorView
  },
  {
    path: '/audit',
    exact: true,
    logged: true,
    component: AuditView
  },
  {
    path: '/newsline',
    exact: true,
    logged: true,
    component: NewslineTableView
  },
  {
    path: '/chart',
    exact: true,
    logged: true,
    component: ChartTableView
  },
  {
    path: '/chart/new',
    exact: true,
    logged: true,
    component: ChartEditorView
  },
  {
    path: '/chart/edit',
    exact: true,
    logged: true,
    component: ChartEditorView
  },
  {
    path: '/project-map',
    exact: true,
    logged: true,
    component: ProjectMapTableView
  },
  {
    path: '/project-map/new',
    exact: true,
    logged: true,
    component: ProjectMapEditorView
  },
  {
    path: '/project-map/edit',
    exact: true,
    logged: true,
    component: ProjectMapEditorView
  },
  {
    component: Error404View
  }
];

export default routes;
