import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { approvalsSelector, approveApproval, getApprovals, rejectApproval } from './approvalSlice';
import ApprovalTable from './ApprovalTable';

export default function RoleListView(_props) {

  const approvals = useSelector(approvalsSelector);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getApprovals());
  }, [dispatch]);

  const approveItem = (id, approval) => {
    dispatch(approveApproval({ id, approval }));
  };

  const rejectItem = (id, approval) => {
    dispatch(rejectApproval({ id, approval }));
  };

  return (
    <ApprovalTable items={approvals} approveItem={approveItem} rejectItem={rejectItem}/>
  );


}
