import {createAsyncThunk,createSlice} from '@reduxjs/toolkit';
import api from '../../api/posts-api';

const init = {
    loading: false,
    hasError: false,
    chart: []
}

export const getAllChart = createAsyncThunk(
    '/chart/get-all',
    async () => {
        try {
            const resp = await api.getAllChart()
            return resp;
        } catch (error) {
        }
    }
)

const chartSlice = createSlice({
    name:'chartSlice',
    initialState:init,
    extraReducers:{
        [getAllChart.fulfilled] : (state, {payload}) => {
            state.chart = payload;
        }
    }
})

// selectors
export const chartSelector = store => {
    return store.chart.chart
}

export default chartSlice.reducer;