import axios from 'axios';
import { isAsExpression } from 'typescript';
import { getPage, getPageDataByID, getLinkTypesWithAttribs } from '../v1Api';
import { API_BASE } from '../v1Api';

const API_BASE_PAGE = API_BASE + '/page';

const GENERICID = 4;

export async function getPageList(parent = 'root') {
  return await axios({
    baseURL: API_BASE_PAGE,
    url: `/parent/${parent}`,
    method: 'GET'
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw new Error('No such a Page!');
    });
}

export async function createNewPage(data) {
  try {
    const response = await axios.post(API_BASE_PAGE, data);
    console.log(response);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updatePage(page) {
  try {
    return await axios.post(API_BASE_PAGE + '/update', page);
  } catch (error) {
    throw error;
  }
}

export async function publishPage(id) {
  try {
    if (!!!id) throw new Error('PAGE ID REQUIRED');
    return await axios.post(API_BASE_PAGE + '/published/' + id);
  } catch (error) {
    throw error;
  }
}

export async function createSection(data, id) {
  try {
    return await axios.post(API_BASE_PAGE + '/section/' + id, data);
  } catch (error) {
    throw error;
  }
}

export async function deleteSection(sectionId, attachments) {
  try {
    return Promise.all([
      axios.delete(API_BASE_PAGE + '/section/' + sectionId),
      attachments.map((a) => {
        return axios.delete(API_BASE_PAGE + '/data/' + a.linkID);
      })
    ]);
  } catch (error) {
    throw error;
  }
}

export async function addPageData(data) {
  try {
    return await axios.post(API_BASE_PAGE + '/data', data);
  } catch (error) {
    console.error(error);
    throw new Error('Could not add Page Data');
  }
}

export async function updataPageData(id, data) {
  try {
    return await axios.put(API_BASE_PAGE + '/data/' + id, data);
  } catch (error) {
    console.error(error);
    throw new Error('Could not update Page Data');
  }
}

export async function deletePageData(id) {
  try {
    return await axios.delete(API_BASE_PAGE + '/data/' + id);
  } catch (error) {
    throw error;
  }
}

export async function getOrderedSections(pageID) {
  try {
    return (await axios.get(`${API_BASE_PAGE}/${pageID}/sections/order`)).data;
  } catch (error) {
    throw error;
  }
}

export async function setSectionOrder(pageID, order) {
  try {
    return await axios.put(`${API_BASE_PAGE}/${pageID}/sections/order`, order);
  } catch (error) {
    throw error;
  }
}

export async function setPageDataOrder(pageID, items) {
  try {
    return (await axios.put(`${API_BASE_PAGE}/${pageID}/data/order`, items)).data;
  } catch (error) {
    throw error;
  }
}

export async function getOrderedPageData(pageId) {
  try {
    return (await axios.get(`${API_BASE_PAGE}/${pageId}/data/order`)).data;
  } catch (error) {}
}

export async function deletePage(pageId) {
  try {
    return await axios.delete(`${API_BASE_PAGE}/delete/${pageId}`);
  } catch (error) {
    console.log(error);
    return error.message;
  }
}

export async function createBreadcrumb(data, pageID) {
  try {
    return await axios.post(`${API_BASE_PAGE}/breadcrumb/${pageID}`, data);
  } catch (error) {
    console.log(error);
    return error.message;
  }
}

export async function updateBreadcrumb(data, breadcrumbID) {
  try {
    return await axios.put(`${API_BASE_PAGE}/breadcrumb/${breadcrumbID}`, data);
  } catch (error) {
    console.log(error);
    return error.message;
  }
}

export async function deleteBreadcrumb(breadcrumbID) {
  try {
    return await axios.delete(`${API_BASE_PAGE}/breadcrumb/${breadcrumbID}`);
  } catch (error) {
    return error.message;
  }
}

export { getPage, getPageDataByID, getLinkTypesWithAttribs };
