import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import AuthWrapperComponent from './AuthWrapperComponent';
import { Link as RouterLink } from 'react-router-dom';
import { authErrorSelector, forgetPassword, forgetPasswordEmail, resetError, sendForgetPassword } from './authSlice';
import { useDispatch } from 'react-redux';
import StateAlertBox from '../../components/common/StateAlertBox';


const useStyles = makeStyles((theme) => ({
  leftImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  rightPanel: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  }
}));

export default function ResetPasswordView(props) {
  const dispatch = useDispatch();
  return (

    <AuthWrapperComponent
      image="/images/login.jpg"
      title="Reset Password">
      <Container maxWidth="sm">
        <StateAlertBox
          messageSelector={authErrorSelector}
          resetMessage={resetError}
          severity={'error'}

        />
        <StateAlertBox
          messageSelector={forgetPasswordEmail}
          resetMessage={sendForgetPassword}
          severity={'success'}

        />
        <Formik

          initialValues={{
            email: ''

          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required')

          })}
          // onSubmit={() => {

          // }}
          onSubmit={async (values, setSubmitting) => {
            dispatch(forgetPassword({
              email: values.email
            }));

            setSubmitting(false);

          }}

        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
            <form onSubmit={handleSubmit}>

              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  Forgot Password
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  Enter your email, phone, or username and we'll send you a link to get back into your account.
                </Typography>
              </Box>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email, Phone or Username"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />


              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Send Login Link
                </Button>
              </Box>
              <Typography
                align="center"
                color="textSecondary"
                variant="subtitle1"
              >
                OR
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                align="center"
              >
                Don&apos;t have an account?
                {' '}
                <Link
                  component={RouterLink}
                  to="/register"
                  variant="body2"
                >
                  Sign up
                </Link>
              </Typography>

            </form>
          )}
        </Formik>
      </Container>
    </AuthWrapperComponent>
  );
}
