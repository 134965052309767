import React from 'react';
import { Editor } from '@tinymce/tinymce-react';


export default function HTMLEditor(props){
  
    const {
      initText,
      height,
      onChange,
      value
    } = props;

    const editorStateChange = (html) =>{
      if(onChange && typeof onChange ==='function'){
        onChange(html);
      }
    }


    return(
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          init={{
            selector:'#htmleditor',
            height:height||500,
            placeholder: "Please select a section before adding anything!!!",
            plugins:['image','save','table','link', "fullscreen", "insertdatetime", "paste", "preview", "charmap"]
            // file_picker_callback: function (callback,value,meta){
            //   console.log(meta);
            // }  
          }}
          initialValue={initText}
          value={value}
          onEditorChange={editorStateChange}
          />)
}