import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { List } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import { TextField } from '@material-ui/core';

import { sessionSelector } from '../file-upload/fileSlice';
// import { updatePageData } from '../../api/v1Api';
import { getPageDataByID, updatePageData } from '../cms-pages/PageSlice';
import PreviousImageList from '../file-upload/components/PreviousImageList';
import AutomaticThumbnail from '../file-upload/components/AutomaticThumbnail';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    minHeight: '185px'
  },
  title: {
    fontSize: 14
  }
});

export default function PageFileUpdate({
  open,
  setOpen,
  attData,
  type,
  pageId
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentFile, setCurrentFile] = React.useState();
  const [title, setTitle] = React.useState();
  const [link, setLink] = React.useState();
  const [linkText, setLinkText] = React.useState();
  const [circularNo, setCircularNo] = React.useState();
  const [gazetteNo, setGazetteNo] = React.useState();
  const [date, setDate] = React.useState();
  const thisUploadSession = useSelector(sessionSelector);

  useEffect(() => {
    if (attData) {
      setCurrentFile(attData);
      setTitle(attData.title);
      setLinkText(attData.linkText);
      setLink(attData.link);
      if (attData.circularNo) {
        setCircularNo(attData.circularNo);
        setDate(attData.date);
      }
      if (attData.gazetteNo) {
        setGazetteNo(attData.gazetteNo);
        setDate(attData.date);
      }
    }
  }, [attData]);

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const handleLinkTextChange = (value) => {
    setLinkText(value);
  };

  const handleCircularNoChange = (value) => {
    setCircularNo(value);
  };

  const handleGazetteNoChange = (value) => {
    setGazetteNo(value);
  };
  const handleDateChange = (value) => {
    setDate(value);
  };

  const hendleLinkChange = (value) => {
    setLink(decodeURI(value));
  };

  const handleUpdateAttachment = () => {
    let newAttData = Object.assign({}, currentFile);
    delete newAttData.linkID;
    delete newAttData.title;
    delete newAttData.link;
    delete newAttData.linkText;
    if (circularNo) {
      newAttData.circularNo = circularNo;
      newAttData.date = date;
    }
    if (gazetteNo) {
      newAttData.gazetteNo = gazetteNo;
      newAttData.date = date;
    }

    let newLinks = {
      title: title,
      link: link,
      linkText: linkText,
      thumbnail: thisUploadSession.thumbnail
        ? thisUploadSession.thumbnail
        : currentFile.thumbnail
    };

    var data = {
      pageID: pageId,
      type: type,
      links: [
        {
          ...newAttData,
          ...newLinks
        }
      ]
    };

    dispatch(
      updatePageData({ id: currentFile.linkID, data: data, pageId: pageId })
    );
    alert('Attachment Updated');
    setOpen(false);
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update Attachment</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <TextField
                value={title}
                label="Title :"
                fullWidth
                onChange={(e) => handleTitleChange(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                value={encodeURI(link)}
                label="Link :"
                fullWidth
                onChange={(e) => hendleLinkChange(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                value={linkText}
                label="Link Text :"
                fullWidth
                onChange={(e) => handleLinkTextChange(e.target.value)}
              />
            </ListItem>
            <ListItem>
              {circularNo ? (
                <TextField
                  value={circularNo}
                  label="Circular No :"
                  fullWidth
                  onChange={(e) => handleCircularNoChange(e.target.value)}
                />
              ) : null}
            </ListItem>
            <ListItem>
              {gazetteNo ? (
                <TextField
                  value={gazetteNo}
                  label="Gazette No :"
                  fullWidth
                  onChange={(e) => handleGazetteNoChange(e.target.value)}
                />
              ) : null}
            </ListItem>
            <ListItem>
              {date ? (
                <TextField
                  value={date}
                  label="Date :"
                  fullWidth
                  onChange={(e) => handleDateChange(e.target.value)}
                />
              ) : null}
            </ListItem>
            <ListItem>
              <p>Thumbnail :</p>
                        <AutomaticThumbnail
                            file={null}
                            currentLink={thisUploadSession.thumbnail ? null : currentFile}
                            thumbnail={thisUploadSession.thumbnail}
                        />
                        
                        <PreviousImageList />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleUpdateAttachment()}>Submit</Button>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
