import React from 'react';
import * as MUI from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFileTypes,
  sessionSelector,
  resetCurrentSession,
  getSiteFileImages
} from './fileSlice';
import FileContainerSelector from './components/FileContainerSelector';
import FileUploader from './components/FileUploader';
import FileTable from 'src/components/fileupload/fileTable';
import { IMAGE_PAGE_ID } from 'src/api/imageHelper-v1API';

const useStyles = MUI.makeStyles((theme) => ({
  paper: {
    width: '92%',
    padding: '10px',
    borderRadius: '16px',
    marginBottom: '20px',
    '@media screen and (max-width: 600)': {
      height: '400px'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: ' 30%'
  },
  paperActionLinks: {
    fontSize: 'small',
    marginTop: '5px'
  },
  attributeInputs: {
    width: '100%'
  },
  selectFileButton: {
    width: '100%',
    marginTop: '10px'
  },
  filePreviewCard: {
    marginTop: '10px'
  }
}));

export default function FileUploadMain() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  
  
  React.useEffect(() => {
    dispatch(getFileTypes());
    dispatch(resetCurrentSession());
    dispatch(getSiteFileImages(IMAGE_PAGE_ID));
  }, []);
  
  return (
    <MUI.Grid container spacing={0} direction="row">
      <MUI.Grid item xs={8}>
        {/* File Uploader */}
        <MUI.Paper className={classes.paper}>
          <FileContainerSelector
            sessionType={session.fileType}
            sessionPage={session.page}
            sessionSection={session.section}
            sessionSectionData={session.sectionData}
          />
          <FileUploader
            fileType={session.fileType}
            file={session.file}
            page={session.page}
            section={session.section}
          />
        </MUI.Paper>
      </MUI.Grid>

      {/* File List */}
      <MUI.Grid item xs={4}>
        <FileTable data={session.sectionData} />
      </MUI.Grid>
    </MUI.Grid>
  );
}

export { useStyles };
