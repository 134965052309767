import React from 'react';
import { useSelector } from 'react-redux';
import { sessionSelector } from 'src/features/file-upload/fileSlice';
import FileRow from './filerow';

const FileTable = ({ data }) => {
  const {sectionData} = useSelector(sessionSelector);
  return (
    <React.Fragment>
      <div>
        {sectionData &&
          sectionData.map((pageData) => (
            <FileRow key={pageData.linkID} obj={pageData} />
          ))}
      </div>
    </React.Fragment>
  );
};

export default FileTable;
