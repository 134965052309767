import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import AdminMaterialTextField from '../../components/common/AdminMaterialTextField';
import { Form, Formik } from 'formik';
import AdminMaterialSelect from '../../components/common/AdminMaterialSelect';
import Grid from '@material-ui/core/Grid';
import { USER_STATUS_DATASET } from '../../libs/helpers';

export default function UserEditDialog(props) {
  const { open, handleClose, handleSubmit, user, roles } = props;

  const roleMap = {};
  const selectRoles = roles.map((role) => {
    roleMap[role.id] = role;
    return {
      value: role.id,
      name: role.name
    };
  });

  const userToFormUser = (user) => {
    return {
      ...user,
      role: user.role ? user.role.id : ''
    };
  };

  const formUserToUser = (formUser) => {
    return {
      ...formUser,
      role: roleMap[formUser.role]
    };
  };

  const initUser = userToFormUser(user);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="lg"
    >
      <Formik
        initialValues={initUser}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required('First Name is required'),
          email: Yup.string().email('Invalid email').required('Required'),
          password: Yup.string().min(8).required('Password is required'),
          role: Yup.string().required('Required')
          // permissions: Yup.array(Yup.object()).min(1)
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await handleSubmit(formUserToUser(values));
          } catch (e) {
            console.log(e);
          } finally {
            handleClose();
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => {
          const formProps = {
            handleBlur,
            errors,
            handleChange,
            touched,
            values
          };
          return (
            <Form>
              <DialogTitle>
                {user.id === -1 ? 'Create ' : 'Edit '}User
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid container item direction="row" spacing={2}>
                    <Grid item md={6} xs={12}>
                      <AdminMaterialTextField
                        fieldName="firstName"
                        label="First Name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        {...formProps}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <AdminMaterialTextField
                        fieldName="lastName"
                        label="Last Name"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        {...formProps}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={6} xs={12}>
                      <AdminMaterialTextField
                        fieldName="email"
                        label="Email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="email"
                        {...formProps}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <AdminMaterialTextField
                        fieldName="mobileNumber"
                        label="Mobile Number"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="tel"
                        {...formProps}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <AdminMaterialSelect
                        fieldName="role"
                        label="Role"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        {...formProps}
                        dataset={selectRoles}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <AdminMaterialTextField
                        fieldName="password"
                        label="Password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="password"
                        {...formProps}
                      />
                    </Grid>
                    {user.id === -1 ? null : (
                      <Grid item md={6} xs={12}>
                        <AdminMaterialSelect
                          fieldName="status"
                          label="Status"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          dataset={USER_STATUS_DATASET}
                          {...formProps}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}
