import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import AdminMaterialTextField from '../../components/common/AdminMaterialTextField';
import { Form, Formik } from 'formik';
import AdminMaterialMultiSelect from '../../components/common/AdminMaterialMultiSelect';
import AdminMaterialSelect from '../../components/common/AdminMaterialSelect';
import { STATUS_DATASET } from '../../libs/helpers';

export default function RoleEditDialog(props) {

  const { open, handleClose, handleSubmit, role, permissions } = props;

  const permissionMap = {};

  const selectPermissions = permissions.map((perm) => {
    permissionMap[perm.id] = perm;
    return {
      value: perm.id,
      name: perm.name
    };
  });

  // normalize the data to match the form
  const roleToFormRole = (role) => {
    const retRole = { ...role };
    // role object permissions to an array
    retRole.permissions = role.permissions.map(permission => {
      return permission.id;
    });

    return retRole;
  };

  // back to the normal form
  const formRoleToRole = (formRole) => {
    const retRole = { ...formRole };

    retRole.permissions = formRole.permissions.map(permission => {
      return permissionMap[permission];
    });

    return retRole;
  };

  const initRole = roleToFormRole(role);

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="md">
      <Formik
        initialValues={initRole}
        validationSchema={
          Yup.object().shape({
            name: Yup.string().min(5).required('Name is required'),
            permissions: Yup.array(Yup.number()).min(1)
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const normalizedValues = formRoleToRole(values);
            await handleSubmit(normalizedValues);
          } catch (e) {
            console.log(e);
          } finally {
            handleClose();
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => {
          const formProps = {
            handleBlur,
            errors,
            handleChange,
            touched,
            values
          };
          return (
            <Form>
              <DialogTitle>{role.id === -1 ? 'Create ' : 'Edit '}Role</DialogTitle>
              <DialogContent>
                <AdminMaterialTextField fieldName="name" label="Name" fullWidth margin="normal"
                                        variant="outlined" {...formProps} />
                { role.id === -1 ? null : <AdminMaterialSelect fieldName="status" label="Status" fullWidth margin="normal" variant="outlined"
                                                               dataset={STATUS_DATASET} {...formProps}/>}
                <AdminMaterialMultiSelect fieldName="permissions" label="Permissions" fullWidth margin="normal"
                                          variant="outlined" dataset={selectPermissions} {...formProps}/>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button disabled={isSubmitting} onClick={handleSubmit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );

}
