import { TextField } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const DateInput = (props) => {
  const [date, setDate] = useState(props.initValue);

  useEffect(() => {
    if(!!props && !!props.getDate && typeof props.getDate == 'function')
        props.getDate(date);    
  }, [date]);

  return (
    <TextField
      size="small"
      variant="outlined"
      onChange={(e) => setDate(e.target.value)}
      label={props.initValue||'Subtitle'}
    />
  );
};

export default DateInput;
