import axios from 'axios';
import purgeStoredState from 'redux-persist/es/purgeStoredState';

// export const API_BASE =  process.env.REACT_APP_API_BASE || "http://localhost:8080"
export const API_BASE =  process.env.REACT_APP_API_BASE || "https://ec-api-gateway.azure-api.net/iwwrmp-cms"

const tokens = {
  token: '',
  refresh: ''
};

axios.interceptors.request.use(function (config) {
  if (tokens.token) config.headers.Authorization = `Bearer ${tokens.token}`;
  return config;
});

export const setTokens = (newTokens) => {
  tokens.token = newTokens.token;
  tokens.refresh = newTokens.refresh;
};

export const login = (credentials) => {
  return axios({
    method: 'POST',
    baseURL: API_BASE,
    url: '/login',
    data: credentials
  })
    .then((response) => {
      const accessToken = response.data.data['accessToken'];
      const refreshToken = response.data.data['refreshToken'];
      tokens.refresh = refreshToken;
      tokens.token = accessToken;

      return { token: accessToken, refresh: refreshToken };
    })
    .catch((error) => {
      console.log('There is no user with the given username and password');
      throw new Error('Invalid Credentials');
    });
};

export const getLoggedUser = async (token) => {
  if (!token) {
    token = tokens.token;
  }
  return axios({
    method: 'GET',
    baseURL: API_BASE,
    url: '/user/logged',
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
    });
};

//Register User
export const register = async (user) => {
  return axios({
    method: 'POST',
    baseURL: API_BASE,
    url: '/auth/register',
    data: {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password
    }
  })
    .then((res) => {
      return {};
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not Register user');
    });
};

//forget password
export const forgetPassword = async (email) => {
  return axios({
    method: 'POST',
    baseURL: API_BASE,
    url: 'auth/password/request',
    data: email
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not send email');
    });
};

//reset PW
export const resetPassword = async (email, token, password) => {
  return axios({
    method: 'POST',
    baseURL: API_BASE,
    url: '/auth/password/reset',
    data: {
      email,
      token,
      password
    }
  })
    .then((res) => {
      return 'Okay';
    })
    .catch((err) => {
      console.error('err:', err);
      throw new Error('Could not create new password');
    });
};

export const verifyPasswordResetToken = async (email, token) => {
  return axios({
    method: 'POST',
    baseURL: API_BASE,
    url: '/auth/password/verify',
    data: {
      email,
      token
    }
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not create new password');
    });
};

/*
  PERMISSION API
 */
export const getPermissions = async () => {
  return axios({
    baseURL: API_BASE,
    url: '/permission/list',
    method: 'GET'
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not load permissions');
    });
};

/*
  APPROVAL API
 */
export const getApprovals = async () => {
  return axios({
    baseURL: API_BASE,
    url: '/approval/list',
    method: 'GET'
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not load approvals');
    });
};

export const approveApproval = async (id, remark) => {
  return axios({
    baseURL: API_BASE,
    url: `/approval/${id}/approve`,
    method: 'POST',
    data: {
      remark
    }
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not approve!');
    });
};

export const rejectApproval = async (id, remark) => {
  return axios({
    baseURL: API_BASE,
    url: `/approval/${id}/reject`,
    method: 'POST',
    data: {
      remark
    }
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not reject!');
    });
};

export const editApproval = async (id, approval) => {
  return axios({});
};

/*
 * ROLE API
 */
export const getRoles = async () => {
  return axios({
    baseURL: API_BASE,
    url: '/role/list',
    method: 'GET'
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not load roles');
    });
};

export const addRole = async (role) => {
  return axios({
    baseURL: API_BASE,
    url: '/role',
    method: 'POST',
    data: {
      name: role.name,
      permissions: role.permissions
    }
  })
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not create the role');
    });
};

export const editRole = async (id, role) => {
  return axios({
    baseURL: API_BASE,
    url: `/role/${id}`,
    method: 'PUT',
    data: {
      name: role.name,
      status: role.status,
      permissions: role.permissions
    }
  })
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not update the role');
    });
};

export const removeRole = async (id) => {
  return axios({
    baseURL: API_BASE,
    url: `/role/${id}`,
    method: 'DELETE'
  })
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not delete the role');
    });
};

/*
 * USER API
 * */
export const getUsers = async () => {
  return axios({
    baseURL: API_BASE,
    url: '/user/list',
    method: 'GET'
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not load users');
    });
};

export const addUser = async (user) => {
  const { id, status, ...restUser } = user;
  return axios({
    baseURL: API_BASE,
    url: '/user',
    method: 'POST',
    data: restUser
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not create the user');
    });
};

export const editUser = async (id, user) => {
  return axios({
    baseURL: API_BASE,
    url: `/user/${id}`,
    method: 'PUT',
    data: user
  })
    .then((res) => {
      return res.data.message;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not update the user');
    });
};

export const removeUser = async (id) => {
  return axios({
    baseURL: API_BASE,
    url: `/user/${id}`,
    method: 'DELETE'
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not delete the user');
    });
};

/*
 * PROFILE API
 * */

export const updateProfile = async (updatePayload) => {
  return axios({
    baseURL: API_BASE,
    url: `/user/profile`,
    method: 'PUT',
    data: {
      firstName: updatePayload.firstName,
      lastName: updatePayload.lastName,
      mobileNumber: updatePayload.mobileNumber
    }
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not update profile');
    });
};

export const updateProfileAvatar = async (avatarLink) => {
  return axios({
    baseURL: API_BASE,
    url: `/user/profile/avatar`,
    method: 'PUT',
    data: {
      avatarLink: avatarLink
    }
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not update profile avatar');
    });
};

export const changePassword = async (password) => {
  return axios({
    baseURL: API_BASE,
    url: `/auth/password/change`,
    method: 'POST',
    data: {
      password: password
    }
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not change password');
    });
};

/*
IMAGE API
 */
export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append('image', file);
  return axios({
    baseURL: API_BASE,
    url: '/image',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

/*
FILE UPLOAD API
 */

export const uploadFile = async ({ file, tag }, listener) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('tagname', `${tag.key}`);
  return await axios({
    baseURL: API_BASE,
    url: '/file/uploadFile',
    method: 'POST',
    data: formData,
    onUploadProgress: listener,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${tokens.token}`
    }
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not upload file');
    });
};

export const getTags = async () => {
  return axios({
    baseURL: API_BASE,
    url: '/tags',
    method: 'GET'
  });
};

/*
CUSTOM LINK API
 */

export const getLinkTypesWithAttribs = async () => {
  return axios({
    baseURL: API_BASE,
    url: '/pageData/typesWithAttributes',
    method: 'GET'
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not upload file');
    });
};

/*
PAGE API
 */

// export const getPageList = async () =>{
//   console.log(list);
//   return list;
// }

export const getPage = async (pageID) => {
  return axios({
    baseURL: API_BASE,
    url: `/page/${pageID}`,
    // url: `/page/data?id=${pageID}&pageSize=1000&pageNo=0`,
    method: 'GET'
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw new Error('No such a Page!');
    });
};

export const getPagesByType = async (type) => {
  return axios({
    baseURL: API_BASE,
    url: `/page/type/${type}`,
    method: 'GET'
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPageDataByID = async (id) => {
  return axios({
    baseURL: API_BASE,
    url: `/page/data?id=${id}&pageSize=1000`,
    method: 'GET'
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPageByLinkDataID = async (id) => {
  return axios({
    baseURL: API_BASE,
    url: `/page/data/${id}/linkData`,
    method: 'GET'
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

// export const updatePageData = async (id, dto) => {
//   return axios({
//     baseURL: API_BASE,
//     url: `/page/data/${id}`,
//     method: 'PUT',
//     data: dto,
//     headers: {
//       Authorization: `Bearer ${tokens.token}`
//     }
//   })
//     .then((resp) => {
//       return resp;
//     })
//     .catch((err) => {
//       console.error(err);
//       throw new Error('Could not update Page');
//     });
// };

export const deletePageDataByID = async (id) => {
  console.log('delete - ', id);
  return axios({
    baseURL: API_BASE,
    url: `/page/data/${id}`,
    method: 'DELETE'
  })
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not Delete PageData');
    });
};

// Developer Note: Page data stands child data items
// TODO: Rename other instances of Page Data where word Data
// implies association to a Page content to Page Content
// eg: updatePageData -> updatePageContent
export const addPageData = async (data) => {
  return axios({
    baseURL: API_BASE,
    url: '/page/data',
    method: 'POST',
    data: data,
    headers: {
      Authorization: `Bearer ${tokens.token}`
    }
  })
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not update Page');
    });
};

/*
ecconomic Indicator API - test
 */

export const addEccIndicator = async (sectionDetails) => {
  // just for describe the object
  const { id, title, subTitle } = sectionDetails;

  return axios({
    baseURL: API_BASE,
    url: '/ecconomic-indicator',
    method: 'POST',
    data: sectionDetails
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not create the ecconomic-indicator');
    });
};

export const getEccIndicator = async () => {
  console.log({}, 'API - getEccIndicator {Request}');
  return axios({
    baseURL: API_BASE,
    url: '/ecconomic-indicator/list',
    method: 'GET'
  })
    .then((res) => {
      console.log('API - getAllSection {Response}', res);
      return res.data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not load ecconomic-indicator');
    });
};

export const getAllSection = async () => {
  console.log({}, 'API - getAllSection {Request}');
  return axios({
    baseURL: API_BASE,
    // url: '/economic-indicator/section',
    url: '/economic-indicator',
    method: 'GET'
  })
    .then((res) => {
      var sec_array = [];

      if (res.data.body !== undefined && res.data.body.length > 0) {
        sec_array = res.data.body.map((section) => {
          section = {
            ...section,
            data: [
              { 'col-1': 150, 'col-2': 145 },
              { 'col-1': 150, 'col-2': 145 }
            ]
          };
        });
      }

      console.log('API - getAllSection {response}', res);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not sections ecconomic-indicator');
    });
};

export const editSection = async (key, section) => {
  console.log('editSection {Request}', section);
  return axios({
    baseURL: API_BASE,
    url: '/economic_indicator/post',
    method: 'PUT',
    data: section
  })
    .then((res) => {
      console.log('editSection {Response}', res);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error('Could not Update section');
    });
};
