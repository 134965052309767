import  fakebackend from './fake-backend';

let _fakebackend = fakebackend();

// Get All Post Data
export async function getAllPosts() {
    return await _fakebackend.readAll();
}

export  async function getPost(postId) {
    return await _fakebackend.readById(postId);
};

// Post Content
export async function createNewPost(data){
    return await _fakebackend.save(data);
}

export async function saveDraft(id,data){
    return await _fakebackend.updateItem(id,data);
}

export function publish(id){

}

export function deletePost(id) {

}

// Post Featured Images

export async function addFeaturedImage(postId,imageUrl) {
   let post = await _fakebackend.readById(postId);
   post.featuredImage = imageUrl;
   return await _fakebackend.updateItem(postId,post);
}

export function updateFeaturedImage(postId,imageUrl) {

}

export function deleteFeaturedImage(postId) {

}


// Post Hero Slide

export function createHeroSlide(postId,{title,description,action,image}){

}

export function updateHeroSlide(postId,{title,description,action,image}) {

}

export function removeHeroSlide(postId) {
    
}

export function uploadFile(file) {
    
}