import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

// initial state of the auth slice
const initialState = {
  loading: false,
  users: [],
  hasErrors: false
};

// async thunk action
export const getUsers = createAsyncThunk(
  'user/getUsers',
  () => {
    return api.getUsers();
  }
);

export const addUser = createAsyncThunk(
  'user/addUser',
  async (user, { dispatch }) => {
    try {
      await api.addUser(user);
      await dispatch(getUsers());
    } catch (e) {
      throw e;
    }
  }
);

export const editUser = createAsyncThunk(
  'user/editUser',
  async ({id, user}, { dispatch }) => {
    try {
      await api.editUser(id, user);
      await dispatch(getUsers());
    } catch (e) {
      throw e;
    }
  }
);

export const removeUser = createAsyncThunk(
  'user/removeUser',
  async (id, { dispatch }) => {
    try {
      await api.removeUser(id);
      await dispatch(getUsers());
    } catch (e) {
      throw e;
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.loading = true;
    },
    [getUsers.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.users = [];
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.users = payload;
    }
  }
});

// actions
// export const {} = userSlice.actions;

// selectors <- getters
export const usersSelector = store => {
  return store.user.users;
};

// reducer
export default userSlice.reducer;
