import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';

// initial state - ecconomic indicator slice
const initialState = {
  loading: false,
  ecoIndicators: [],
  hasErrors: false
};

// export const addEccIndicator = createAsyncThunk(
//     'ecconomic-indicators/add',
//     async (section, { dispatch }) => {
//       try {
//         await api.addEccIndicator(section)
//         await dispatch(getRoles());
//       } catch (e) {
//         throw e;
//       }
//     }
// );

export const getSections = createAsyncThunk(
  'ecconomic-indicator/section',
  async () => {
    return await api.getAllSection();
  }
  );
  
  export const editSection = createAsyncThunk(
    'ecconomic-indicator/editSection',
    async ({key,newSection:section},_props) => {
      // console.log('Economic indicator slice',key);
      // console.log('Economic indicator slice',section);
      try {
      await api.editSection(key, section);
      return await api.getAllSection();
      // await dispatch(getRoles());
    } catch (e) {
      throw e;
    }
  }
);

const ecoIndicatorSlice = createSlice({
  name: 'ecoIndicator',
  initialState,
  reducers: {},
  extraReducers: {
    [getSections.pending]: (state) => {
      state.loading = true;
    },
    [getSections.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.ecoIndicators = [];
    },
    [getSections.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.ecoIndicators = payload;
      console.log(payload, 'payload');
    },
    [editSection.pending]: (state) => {
      state.loading = true;
    },
    [editSection.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.ecoIndicators = [];
    },
    [editSection.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.ecoIndicators = payload;
      // console.log(payload, 'payload');
    },
  }
});

// selectors
export const ecoIndicatorSelector = (store) => {
  return store.ecoIndicator.ecoIndicators;
};
export const loadingSelector = (store) => {
  return store.ecoIndicator.hasErrors;
};

// reducer
export default ecoIndicatorSlice.reducer;
