import { DialogTitle } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import api from '../../../api';
import postAPI from '../../../api/posts-api';
import AlertBox from 'src/components/common/AlertBox';

export default function PostAttachmentModal({
    postId,
    file,
    open,
    setOpen,
    refreshData
}) {

    const [thumbnail,setThumbnail] = useState(null);
    const [title,setTitle] = useState('');
    const [linkText,setLinkText] = useState('Download Attachment');
    const [external,setExternal] = useState(true);
    const [isImage,setIsImage] = useState(false);
    const [progress,setProgress] = useState(0);
    const [alertOpen,setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");

    const getIcon = (mime) => {

        switch(mime){
            case 'application/pdf':
                setThumbnail('/icons/pdf.png');
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                setThumbnail('/icons/docx.png');
                break;
            case 'application/zip':
                setThumbnail('/icons/zip.png');
                break;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                setThumbnail('/icons/xls.webp');
                break;
            /**
             * This special case is for when an existing attachment is opened
             * */ 
            case 'post/attachment':
                setThumbnail(file.thumbnail);
                break;
            default:
                setThumbnail('/icons/file.png')
        }
    }

    const handleSave = () => {
        api.uploadFile({file,tag:{key:'post_attachments'}},function(p) {
            setProgress(Math.round(p.loaded/p.total)*100)
        })
        .then(res=>{
            postAPI.addPostAttachment(postId,{
                title: title,
                link: res.body ,
                thumbnail: isImage?res.body:thumbnail,
                linkText: linkText,
                external: external
            })
        })
        .catch(console.error)
        .finally(()=>{
            setAlertOpen(true)
            setMessage("Attachment has been uploaded");
            setProgress(0);
            setOpen(false);
            refreshData();
        })
    }

    useEffect(()=>{
        if(file){
            setTitle(file.name);
            let mime =file.type;
            if(mime.match('image')){
                setIsImage(true);
                const reader = new FileReader();
                reader.onload = () =>{
                    setThumbnail(reader.result);
                }
                
                reader.readAsDataURL(file);

            }else{
                setIsImage(false);
                getIcon(mime)
            }
        } else {

        }
    },[file]);

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };


    if(!!!file) return null;
    return <>
    {/* Alerts */}
    <AlertBox 
        open={alertOpen}
        message={message}
        handleClose={handleCloseAlert}
        duration={3000}
    />

    <Dialog open={open} fullWidth>
        <DialogTitle>
            Post Attachment: {file&&file.name}
        </DialogTitle>
        <DialogContent>
            <Grid container direction="column" justify="center" spacing={2}>
                <Grid item>
                    <Typography variant="caption">Attachment Title</Typography>   
                    <TextField 
                        value={title}
                        onChange={(e)=>{
                            setTitle(e.target.value)
                        }}
                        placeholder="Attachment Title" fullWidth/>
                </Grid>
                <Grid item>
                    <Typography variant="caption">Link Text</Typography>
                    <TextField 
                        value={linkText}
                        onChange={(e)=>{
                            setLinkText(e.target.value);
                        }}
                        placeholder="Link Text" fullWidth/>
                </Grid>   
                <Grid item>
                    <img width={isImage?
                        progress?'80px':'100%':'80px'} src={thumbnail}/>
                </Grid>
                <Grid item>
                    {(!!progress)&&
                        <LinearProgress variant="determinate" value={progress} />
                    }
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button 
                onClick={handleSave}
                variant="contained" color="primary">Save</Button>
            <Button
                onClick={()=>{
                    setOpen(false);
                }} 
                variant="contained" color="primary">Cancel</Button>

        </DialogActions>
    </Dialog>
    </>
}