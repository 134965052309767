import React, { useEffect, useState } from 'react';
import { 
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    Link

} from "@material-ui/core";
import { Breadcrumbs } from '@material-ui/core';

export default function PageMetaView({open, setOpen, page}) {
    
    const makeBreadCrumbs = () => {
        if(page.breadcrumbs && page.breadcrumbs.map){
            return page.breadcrumbs.map((b,i)=>(
                <Link key={`bc-${i}`}href={b.link}>{b.label}</Link>
            ))
        } else return null;
    }

    return (
        <Dialog fullWidth open={open} onClose={()=>setOpen(false)}>
            <DialogTitle>{page?.title}</DialogTitle>;
            <DialogContent>
                <List>
                   <ListItem>
                       <Breadcrumbs>
                        {makeBreadCrumbs()}
                       </Breadcrumbs>
                   </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary="Created On"
                            secondary={page?.createdOn}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary="Published On"
                            secondary={page?.status?page?.publishedOn:"Draft"}
                        />
                    </ListItem>
                </List>
            </DialogContent>

            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
            </DialogActions>

        </Dialog>
    )
}