import React from 'react';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../theme';
import { darkModeSelector } from '../features/darkMode/darkModeSlice';
import { useSelector, useDispatch } from 'react-redux';
import { isLoggedSelector, logout } from '../features/auth/authSlice';
import routes from '../routes';
import axios from 'axios';

const App = () => {

  const darkMode = useSelector(darkModeSelector);
  const isLogged =  useSelector(isLoggedSelector);
  const dispatch = useDispatch();

  // Clear Tokens when a response is unauthorized.
  axios.interceptors.response.use(undefined, function(error){
  
    if (error.response.status === 403) {
      dispatch(logout());
    } 
      return Promise.reject(error);
  });

  const customTheme = createMuiTheme({
    ...theme,
    palette: {
      ...theme.palette,
      type: darkMode ? 'dark' : 'light'
    }
  });

  const LoggedRoute = ({ component, ...rest }) => {
    const Component = component;
    return (
      <Route {...rest} render={props =>
        isLogged ? <Component {...props} /> :
          <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>
      }/>
    );
  };

  const NotLoggedRoute = ({ component, ...rest }) => {
    const Component = component;
    return (
      <Route {...rest} render={props =>
        isLogged ? <Redirect to="/dashboard"/> : <Component {...props}/>
      }/>
    );
  };

  const renderRoutes = (
    routes.map((route, i) => {
      const { logged, notLogged, redirect, ...rest } = route;
      if (logged === true) {
        return <LoggedRoute key={i} {...rest}/>;
      }
      if (notLogged === true) {
        return <NotLoggedRoute key={i} {...rest}/>;
      }
      return <Route key={i} {...rest}/>;
    })
  );

  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <CssBaseline/>
        <Dashboard>
          <Switch>
            {renderRoutes}
          </Switch>
        </Dashboard>
      </ThemeProvider>
    </Router>
  );
};

export default App;
