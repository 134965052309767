import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { getLoggedUser } from '../auth/authSlice';

// initial state of the auth slice
const initialState = {
  loading: false,
  hasErrors: false
};

// async thunk action
export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (updatePayload, { dispatch }) => {
    await api.updateProfile(updatePayload);
    await dispatch(getLoggedUser());
    return 'ok';
  }
);

export const changePassword = createAsyncThunk(
  'profile/changePassword',
  async ({ password }) => {
    await api.changePassword(password);
    return 'ok';
  }
);

export const uploadAvatar = createAsyncThunk(
  'profile/uploadAvatar',
  async ({ file }, { dispatch }) => {
    const imageRest = await api.uploadImage(file);
    const path = imageRest.data.data;
    await api.updateProfileAvatar(path);
    await dispatch(getLoggedUser());
    return 'ok';
  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [updateProfile.pending]: (state) => {
      state.loading = true;
    },
    [updateProfile.rejected]: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.users = [];
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.users = payload;
    }
  }
});

// actions
// export const {} = userSlice.actions;

// selectors <- getters
export const usersSelector = store => {
  return store.user.users;
};

// reducer
export default profileSlice.reducer;
