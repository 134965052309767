import { Button } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { 
    Select, 
    MenuItem,
    Dialog, 
    DialogActions 
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getPageList } from 'src/api/pages-api/v1';


export default function PageSelectorDialog({
    open,
    setOpen,
    callback,
    dialogTitle="Select Page"
    }) {
    const [breadcrumbs,setBreadcrumbs]  = useState([null]);
    const [title,setTitle] = useState(<p>Pages</p>);
    const [localPages,setLocalPages]= useState([]);


    const populatePageList = () => (
        localPages.map((t,k)=>(
            <MenuItem key={k} value={t.id}>{t.title}</MenuItem>
        ))
    )

    const handleSubmit = ()=>{
        let selected = breadcrumbs[breadcrumbs.length - 1]?
        breadcrumbs[breadcrumbs.length - 1]:
        null;
        if(selected){
            callback(
                {
                    ...selected,
                    breadcrumbs: breadcrumbs  
                });
        } else  return null;
        setOpen(false);
    }

    const handleShowChildren = (e) =>{
        let parent = e.target.value;
        let page = localPages.find(p=>p.id==parent);
        if(page){
            setBreadcrumbs([...breadcrumbs,{id:page.id,breadcrumbs: page.breadcrumbs,title:page.title}])
            getPageList(parent)
            .then(res=>{
                setLocalPages(res);
            })
            .catch(console.error);

        }else {
            setBreadcrumbs([{
                id: 'web',
                title: 'Home'
            }])

        }
    }

    const handleBreadCrumbClick = (index,id) => {
        setBreadcrumbs(breadcrumbs.slice(0,index+1));
        getPageList(id)
        .then(res=>{
            setLocalPages(res);
        })
    }

    useEffect(() => {
        let breadcrumbsAsTitle = breadcrumbs.map((page,k) => {
            if(page){
                return <span key={`bc-${k}`}>
                    <button 
                    style={{backgroundColor:'none',border:'none',cursor:'pointer'}}
                    onClick={()=>handleBreadCrumbClick(k,page.id)} 
                    > {page.title}</button> / </span>
            }else{
                return <span key={`bc-${k}`}>
                    <button
                    style={{backgroundColor:'none',border:'none',cursor:'pointer'}}
                    onClick={()=>handleBreadCrumbClick(k,"root")} 
                     >Site Pages </button> / </span>
            }
        });
        setTitle(breadcrumbsAsTitle);
    },[breadcrumbs]);

    useEffect(()=>{
        getPageList('root')
        .then(res=>{
            setLocalPages(res);
        })
    },[])

    return (
    <Dialog open={open} onClose={()=>setOpen(false)} fullWidth>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
            {title}
            <Select 
                fullWidth
                labelId="parent-label"
                name="parent"
                onChange={handleShowChildren}
                required>
                    <MenuItem value={"root"}>{
                    breadcrumbs[breadcrumbs.length-1]?
                    breadcrumbs[breadcrumbs.length-1].title
                    :'Home'}</MenuItem>
                    {!!localPages&&populatePageList()}
                </Select>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSubmit}>Select</Button>
            <Button onClick={()=>setOpen(false)}>Cancel</Button>
        </DialogActions>
    </Dialog>)
}