import React, { useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { ButtonGroup } from '@material-ui/core';
import { useState } from 'react';
import { getMaxError } from 'src/wrappers/dev/helper';

export default function HTMLEditorContainer({
  title,
  description,
  actionLink,
  actionLabel,
  editMode,
  setSliderState,
  handleSave
}) {
    const [_title, setTitle] = useState("Untitled");
    const [_subTitle, setSubTitle] = useState("Untitled");
    //const [_link, setLink] = useState("Untitled");
  const handleChangeTitle = (value) => {
    setSliderState((state) => ({
      ...state,
      title: value
    }));
    setTitle(value)
  };

  const handleChangeSubTitle = (value) => {
    setSliderState((state) => ({
      ...state,
      description: value
    }));
    setSubTitle(value)
  };


  const handleChangeActionLabel = (value) => {
    setSliderState((state) => ({
      ...state,
      actionLabel: value
    }));
  };
  // const handleChangeActionLink = (value) => {
  //   setSliderState((state) => ({
  //     ...state,
  //     actionLink: value
  //   }));
  //   setLink(value)
  // };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container spacing={3}>
        <Grid item md={9}>
          <TextField
            disabled={true}
            placeholder="Title"
            inputProps={{ maxLength: 991 }}
            error={_title === '' || _title.length > 990}
            helperText={_title === '' ? 'Empty Title!' : _title.length > 990? getMaxError() :' '}
            fullWidth
            onChange={(e) => handleChangeTitle(e.target.value)}
            value={title}
            style={{ paddingTop: '8px' }}
          />
          <TextField
            disabled={true}
            placeholder="Sub Title"
            fullWidth
            onChange={(e) => handleChangeSubTitle(e.target.value)}
            value={description}
          />
          {/* <TextField
            placeholder="Link"
            inputProps={{ maxLength: 491 }}
            error={_link.length > 490}
            helperText={_link === '' ? 'Empty Link!' : _link.length > 490? getMaxError() :' '}
            fullWidth
            onChange={(e) => handleChangeActionLink(e.target.value)}
            value={actionLink}
          /> */}
          <TextField
            placeholder="Button Label"
            sx={{ width: 500, maxWidth: '50%' }}
            onChange={(e) => handleChangeActionLabel(e.target.value)}
            value={actionLabel}
            style={{ paddingTop: '8px' }}
          />
        </Grid>
        <Grid item md={3}>
          <ButtonGroup
            fullWidth
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
          >
            <Button onClick={handleSave}>{editMode ? 'Update' : 'Save'}</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
}
