import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useHistory } from 'react-router';
import api from '../../api/posts-api';
import AlertBox from 'src/components/common/AlertBox';
import { Button } from '@material-ui/core';
import { ButtonGroup } from '@material-ui/core';

export default function ChartEditorView() {
    const history = useHistory();
    const [editMode, setMode] = useState(false);
    const [alertOpen,setAlertOpen] = useState(false);
    const [message, setMessage] = useState("Successful");
    const [severity, setSeverity] = useState("success");
    const [chartState, setChartState] = useState({
        chartCategoryName: '',
        subCategoryOneName: 'Allocation',
        subCategoryOneValue: '',
        subCategoryTwoName: 'Commitment',
        subCategoryTwoValue: '',
    });

    useEffect(() => {
        if (history.location.pathname === "/chart/edit") {
            setMode(true);
            getCharts(window.localStorage.getItem("Chart-ID"));
        }
    }, []);

    const getCharts = (id) => {
        api.getAllChartById(id)
            .then(res => {
                if (res) {
                    setChartState({
                        chartCategoryName: res.chartCategoryName,
                        subCategoryOneName: res.subCategoryOneName,
                        subCategoryOneValue: res.subCategoryOneValue,
                        subCategoryTwoName: res.subCategoryTwoName,
                        subCategoryTwoValue: res.subCategoryTwoValue,
                    })
                } else {
                    history.push('/404');
                }
            })
            .catch(console.error)
    };

    const handleSave = async () => {
        console.log("edit mode >>>>>>>>>", editMode);
        if (editMode) {
            const data = {
                chartId: window.localStorage.getItem("Chart-ID"),
                chartCategoryName: chartState.chartCategoryName ? chartState.chartCategoryName : '',
                subCategoryOneName: chartState.subCategoryOneName,
                subCategoryOneValue: chartState.subCategoryOneValue,
                subCategoryTwoName: chartState.subCategoryTwoName,
                subCategoryTwoValue: chartState.subCategoryTwoValue,
            };
            let saveRes = await api.updateChart(data);
            if(saveRes === 'Successfully updated'){
                setAlertOpen(true);
                setMessage("Chart has been Updated");
                setSeverity("success");
                history.push('/chart');
                window.localStorage.removeItem("Chart-ID");
            }
        }
        else {
            try {
                const data = {
                    chartCategoryName: chartState.chartCategoryName ? chartState.chartCategoryName : '',
                    subCategoryOneName: chartState.subCategoryOneName,
                    subCategoryOneValue: chartState.subCategoryOneValue,
                    subCategoryTwoName: chartState.subCategoryTwoName,
                    subCategoryTwoValue: chartState.subCategoryTwoValue,
                };
                let saveRes = await api.createChart(data);
                if(saveRes === 'Successfully added'){
                    setAlertOpen(true);
                    setMessage("Chart has been Added");
                    setSeverity("success");
                    history.push('/chart');
                }
            }
            catch(e){
                console.error(e);
            }
        }
    };

    const handleChangeCategoryName = (value) => {
        setChartState((state) => ({
            ...state,
            chartCategoryName: value
        }));
    };

    const handleChangeAllocationValue = (value) => {
        setChartState((state) => ({
            ...state,
            subCategoryOneValue: value
        }));
    };

    const handleChangeCommitmentValue = (value) => {
        setChartState((state) => ({
            ...state,
            subCategoryTwoValue: value
        }));
    };

    const handleCloseAlert = () => {
        setAlertOpen(false);
    };

    return (
        <Grid container direction="column" spacing={2}>
            <AlertBox 
                open={alertOpen}
                message={message}
                handleClose={handleCloseAlert}
                duration={3000}
                severity={severity}
            />
            <Grid item container spacing={3}>
                <Grid item md={9}>
                    <TextField
                        placeholder="Category Name"
                        fullWidth
                        onChange={(e) => handleChangeCategoryName(e.target.value)}
                        value={chartState.chartCategoryName}
                    />
                    <TextField
                        disabled={true}
                        placeholder="Sub Category 1"
                        fullWidth
                        value={chartState.subCategoryOneName}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Allocation Value"
                        type="number"
                        fullWidth
                        onChange={(e) => handleChangeAllocationValue(e.target.value)}
                        value={chartState.subCategoryOneValue}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        disabled={true}
                        placeholder="Sub Category 2"
                        fullWidth
                        value={chartState.subCategoryTwoName}
                        style={{ paddingTop: '20px' }}
                    />
                    <TextField
                        placeholder="Commitment Value"
                        type="number"
                        fullWidth
                        onChange={(e) => handleChangeCommitmentValue(e.target.value)}
                        value={chartState.subCategoryTwoValue}
                        style={{ paddingTop: '20px' }}
                    />
                </Grid>
                <Grid item md={3}>
                    <ButtonGroup
                        fullWidth
                        variant="contained"
                        color="primary"
                        aria-label="contained primary button group"
                    >
                        <Button onClick={handleSave}>{editMode ? 'Update' : 'Save'}</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Grid>
    );
};