import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, CardContent, Container, Divider, TextField, Typography } from '@material-ui/core';
import AuthWrapperComponent from './AuthWrapperComponent';
import { useDispatch } from 'react-redux';
import { authErrorSelector, resetError, resetNewPassword, resetPassword, setNewPassword } from './authSlice';
import StateAlertBox from '../../components/common/StateAlertBox';
import * as qs from 'qs';
import { useHistory } from 'react-router-dom';
import api from '../../api';

export default function ResetPasswordView(props) {

  const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const email = query.email;
  const token = query.token;

  
  const dispatch = useDispatch();

  const history = useHistory();

  api.verifyPasswordResetToken(email, token).then((res) => {
    console.log(res);
  }).catch((e) => {
    history.push('/');
  });

  return (
    
    <AuthWrapperComponent
      image="https://images.pexels.com/photos/57690/pexels-photo-57690.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
      title="Reset Password">
      <Container maxWidth="sm">
        <StateAlertBox
          messageSelector={authErrorSelector}
          resetMessage={resetError}
          severity={'error'}

        />
        <StateAlertBox
          messageSelector={setNewPassword}
          resetMessage={resetNewPassword}
          severity={'success'}
        />
        <Formik
          initialValues={{
            password: '123456789',
            confirm: '123456789',
          }}


          validationSchema={
            Yup.object().shape({
              password: Yup
                .string()
                .label('Password')
                .required()
                .min(8)
                .max(20, 'We prefer insecure system, try a shorter password.'),
              confirm: Yup
                .string()
                .required()
                .label('Confirm password')
                .test('passwords-match', 'Passwords must match', function(value) {
                  return this.parent.password === value;
                })

            })
          }
          
          onSubmit={async (values, setSubmitting) => {
            dispatch(resetPassword({
              email: email,
              token: token,
              password: values.password
            }));
            setSubmitting(false);
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  Reset Password
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  Enter new Password
                </Typography>
                <CardContent>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    label="Password"
                    margin="normal"
                    name="password"
                    onChange={handleChange}
                    helperText={touched.password && errors.password}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.confirm && errors.confirm)}
                    fullWidth
                    label="Confirm password"
                    margin="normal"
                    name="confirm"
                    onChange={handleChange}
                    helperText={touched.confirm && errors.confirm}
                    type="password"
                    value={values.confirm}
                    variant="outlined"
                  />
                </CardContent>
                <Divider/>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  p={2}
                >
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </AuthWrapperComponent>
  );
}
