export const convertToDateFromat = (attribute) => {
    const temp = attribute.split('-');
    return `${temp[2]}-${temp[1]}-${temp[0]}`;
  };
  
  export const materialDateInput = () => {
    const dateNow = new Date();
    const year = dateNow.getFullYear();
    const monthWithOffset = dateNow.getUTCMonth() + 1;
    const month =
      monthWithOffset.toString().length < 2
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date =
      dateNow.getDate().toString().length < 2
        ? `0${dateNow.getDate()}`
        : dateNow.getDate();
  
    return `${year}-${month}-${date}`;
  };