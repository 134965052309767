import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteForever } from '@material-ui/icons';
import ConfirmationDialog from './ConfirmationDialog';


export default function CustomToolbarSelect(props) {
  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    title: '',
    subtitle: ''
  });
  const showDeleteDialog = (deleteCount) => {
    const title = `Delete ${deleteCount} Item(s)?`;
    const subtitle = 'Do you want to delete the item(s)?';
    setDeleteDialogState({
      ...deleteDialogState,
      title,
      subtitle,
      open: true
    });
  };
  const { selectedRows, setSelectedRows, onDeleteItems } = props;
  const indexes = selectedRows.data.map((item) => (item.dataIndex));
  return (
    <Box>
      <Tooltip title={'Delete Item(s)'}>
        <IconButton onClick={() => {
          showDeleteDialog(indexes.length);
        }}><DeleteForever/></IconButton>
      </Tooltip>
      <ConfirmationDialog okText="Delete" onOk={() => {
        setSelectedRows([]);
        onDeleteItems(indexes);
      }} id='role-delete-dialog' onClose={() => {
        setDeleteDialogState({
          ...deleteDialogState,
          open: false
        });
      }} open={deleteDialogState.open} title={deleteDialogState.title} subtitle={deleteDialogState.subtitle}/>
    </Box>
  );
}
