import React, { useEffect } from 'react';
import UserDataTable from './UserDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, editUser, getUsers, removeUser, usersSelector } from './userSlice';
import { getRoles } from '../role/roleSlice';
import { getPermissions } from '../permission/permissionSlice';


export default function UserListView(props) {

  const users = useSelector(usersSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPermissions());
    dispatch(getRoles());
    dispatch(getUsers());
  }, [dispatch]);

  const addItem = (user) => {
    dispatch(addUser(user));
  };

  const removeItem = (id) => {
    dispatch(removeUser(id));
  };

  const editItem = (id, user) => {
    dispatch(editUser({ id, user }));
  };

  return (
    <UserDataTable items={users} addItem={addItem} removeItem={removeItem} editItem={editItem}/>
  );
}
