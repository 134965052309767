import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import api from './api';
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { login } from './features/auth/authSlice';
// import logger from 'redux-logger';

// middleware to set access token and refresh token
const saveAuthTokens = store => next => action => {
  if (action.type === login.fulfilled.type) {
    api.setTokens(action.payload);
  }
  return next(action);
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }),
  saveAuthTokens,
  // logger
]

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: middleware,
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const persister = persistStore(store);

export default store;
