import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import { Avatar, CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedUser, loggedUserSelector } from '../auth/authSlice';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EditProfileDialog from './EditProfileDialog';
import { changePassword, updateProfile, uploadAvatar } from './profileSlice';
import ChangePasswordDialog from './ChangePasswordDialog';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  }
}));

export default function ProfileView(props) {

  const classes = useStyles();

  const user = useSelector(loggedUserSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const handleEditProfileCancel = () => {
    setEditProfileOpen(false);
  };
  const handleEditProfileSubmit = (value) => {
    dispatch(updateProfile(value));
    setEditProfileOpen(false);
  };
  const openEditProfileDialog = () => {
    setEditProfileOpen(true);
  };

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const handleChangePasswordCancel = () => {
    setChangePasswordOpen(false);
  };
  const handleChangePasswordSubmit = (value) => {
    dispatch(changePassword(value));
    setChangePasswordOpen(false);
  };
  const openChangePasswordDialog = () => {
    setChangePasswordOpen(true);
  };

  const handleUploadAvatar = (e) => {
    const file = e.target.files[0];
    dispatch(uploadAvatar({ file }));
    e.target.value = "";
  }

  let fileInputRef = null;

  return (
    <Box>
      <ChangePasswordDialog open={changePasswordOpen} onCancel={handleChangePasswordCancel}
                            onSubmit={handleChangePasswordSubmit}/>
      <EditProfileDialog open={editProfileOpen} onCancel={handleEditProfileCancel} onSubmit={handleEditProfileSubmit}
                         initValue={{
                           firstName: user.firstName,
                           lastName: user.lastName,
                           mobileNumber: user.mobileNumber
                         }}/>
      <Card>
        <CardContent>
          <Typography variant="h6">Profile</Typography>
          <Grid container spacing={2}>
            <Grid container item direction="column" justify="center" alignItems="center">
              <Grid item>
                <input type="file" style={{ display: 'none' }} ref={fileInput => fileInputRef = fileInput} onChange={handleUploadAvatar} />
                <IconButton onClick={() => { fileInputRef.click(); }}>
                  <Avatar src={user.avatarLink} className={classes.avatar}/>
                </IconButton>
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField value={user.firstName} fullWidth variant="outlined" label="First Name"
                           InputProps={{ readOnly: true }}/>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField value={user.lastName} fullWidth variant="outlined" label="Last Name"
                           InputProps={{ readOnly: true }}/>
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField value={user.email} fullWidth variant="outlined" label="Email"
                           InputProps={{ readOnly: true }}/>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField value={user.mobileNumber} fullWidth variant="outlined" label="Mobile Number"
                           InputProps={{ readOnly: true }}/>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField value={user.role.name} fullWidth variant="outlined" label="Role"
                           InputProps={{ readOnly: true }}/>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField value={user.status} fullWidth variant="outlined" label="Status"
                           InputProps={{ readOnly: true }}/>
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item>
                <Button onClick={openEditProfileDialog} color="primary" variant="contained">Edit</Button>
              </Grid>
              <Grid item>
                <Button onClick={openChangePasswordDialog} color="secondary" variant="contained">Change
                  Password</Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
