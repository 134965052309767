import React, { useEffect } from 'react';
import { setTitle } from '../../libs/helpers';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

export default function HomeView(props) {
  const classes = useStyles();

  useEffect(() => {
    document.title = setTitle('Home');
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {/* <iframe
          width="100%"
          height="940px"
          src="https://datastudio.google.com/embed/reporting/7c98a6e3-d0de-46dc-a23e-e92c3c3ef35b/page/sreGC" 
          frameBorder="0" style={{ border: 0, backgroundColor: 'white' }} allowFullScreen
        /> */}

        <iframe
          width="100%"
          height="940px"
          src="https://lookerstudio.google.com/embed/reporting/41f46ada-d158-4583-b7ea-d167fcd7e9f8/page/2mYjD"
          frameborder="0"
          style={{ border: 0, backgroundColor: 'white' }}
          allowfullscreen
        ></iframe>

      </Grid>
    </div>
  );
}
