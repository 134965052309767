import { Box, Button, Checkbox, Container, FormHelperText, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { setTitle } from '../../libs/helpers';
import { useDispatch } from 'react-redux';
import { authErrorSelector, register, registeredSelector, resetError, resetRegistered } from './authSlice';
import AdminMaterialTextField from '../../components/common/AdminMaterialTextField';
import StateAlertBox from '../../components/common/StateAlertBox';


export default function RegisterForm(props) {
  /* Server State Handling */
  // const [serverState, setServerState] = useState();
  // const handleServerResponse = (ok, msg) => {
  //   setServerState({ok, msg});
  // };

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = setTitle('Register');
  }, []);

  const initState = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    policy: false
  };

  return (
    <Container maxWidth="sm">
      <StateAlertBox
        messageSelector={authErrorSelector}
        resetMessage={resetError}
        severity={'error'}

      />
      <StateAlertBox
        messageSelector={registeredSelector}
        resetMessage={resetRegistered}
        severity={'success'}
      />
      <Formik
        initialValues={initState}
        validationSchema={
          Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
            firstName: Yup.string().max(255).required('First name is required'),
            lastName: Yup.string().max(255).required('Last name is required'),
            password: Yup.string().max(255).min(8).required('password is required'),
            policy: Yup.boolean().oneOf([true], 'This field must be checked')
          })
        }


        onSubmit={async (values, setSubmitting) => {
          dispatch(register({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
          }));
          setSubmitting(false);
          
        }}

      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => {
          const formProps = {
            handleBlur,
            errors,
            handleChange,
            touched,
            values
          };
          return (
            <form onSubmit={handleSubmit}>
              <Box>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  Register
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  Use your email to create new account
                </Typography>
              </Box>
              <AdminMaterialTextField fieldName="firstName" label="First Name" fullWidth margin="normal"
                                      variant="outlined" {...formProps} />
              <AdminMaterialTextField fieldName="lastName" label="Last Name" fullWidth margin="normal"
                                      variant="outlined" {...formProps} />
              <AdminMaterialTextField fieldName="email" label="Email" fullWidth margin="normal"
                                      variant="outlined" {...formProps} />
              <AdminMaterialTextField fieldName="password" label="Password" fullWidth margin="normal"
                                      variant="outlined" type="password" {...formProps} />
              <Box
                alignItems="center"
                display="flex"
                ml={-1}
              >
                <Checkbox
                  checked={values.policy}
                  name="policy"
                  onChange={handleChange}
                />
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Accept Terms & Conditions
                </Typography>
              </Box>
              {Boolean(touched.policy && errors.policy) && (
                <FormHelperText error>
                  {errors.policy}
                </FormHelperText>
              )}
              <Box my={1}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Register
                </Button>
              </Box>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Have an account?
                {' '}
                <Link
                  component={RouterLink}
                  to="/login"
                  color="primary"
                  variant="body2"
                >
                  Login
                </Link>

              </Typography>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
}
