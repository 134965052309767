import { makeStyles } from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef'
import { Button } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { List } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import {
  AddCircle,
  Delete,
  DeleteForeverOutlined,
  InfoOutlined,
  UpdateOutlined
} from '@material-ui/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PageFileNew from './PageFileNew';
import { deletePageData, getPageDataByID, setPageDataOrder } from './PageSlice';
import { ListItemIcon } from '@material-ui/core';
import { ListItemSecondaryAction } from '@material-ui/core';
import PageFileUpdate from './PageFileUpdate';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    minHeight: '185px'
  },
  title: {
    fontSize: 14
  }
});

const makeLink = (link, type) => {
  switch (type) {
    case 'external':
      return link;
    case 'page':
      return process.env.REACT_APP_FRONTEND + '/web/' + link;
    default:
      return process.env.REACT_APP_FRONTEND + '/api/file/' + link;
  }
};

const makeThumbnail = (file) => {
  let link = file.image || file.thumbnail;

  if(String(link).match('icons')) {
    return link;
  } else if (String(link).match('assets')) {
    return '/icons/' + String(link).split('/').pop();
  } else if (file.urlType === 'external' || file.urlType === 'link') {
    return '/icons/link.png'; // Get the favicon instead.
  } else {
    return process.env.REACT_APP_FRONTEND + '/api/file/' + link;
  }
};

const makeTitle = (file) => {
  if (file) return file.title || file.name || file.linkText;
};

const makeContent = (file) => {
  if (file)
    return Object.entries(file).map((e, k) => (
      <ListItem key={k}>
        <ListItemText primary={String(e[0]).toUpperCase()} secondary={String(e[1]).toString()} />
      </ListItem>
    ));
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {})
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const mapItemsToOrder = (files) => {
  return files.map((item, index) => ({
    linkID: item.linkID,
    position: index + 1
  }));
};


export default function PageFileList({ files, type, isTable, current, page }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [item, setItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  
  const handleShowItem = (file) => setItem(file);
  const handleDeleteItem = (id) => {
    if (window.confirm('Are you sure you want to delete this attachment?'))
      dispatch(deletePageData({ id: page.id, data: id }));
  };

  const handleUpdateItem = (file) => {
    setUpdateItem(file);
    setOpen(true);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(files, result.source.index, result.destination.index);
    if (window.confirm('Are you sure you want to move this item?')) {
      dispatch(
        setPageDataOrder({
          id: page.id,
          items: mapItemsToOrder(items)
        })
      );
    } else {
      return;
    }
    setIsDragging(false);
  };

  useEffect(() => {
    dispatch(getPageDataByID(page.id));
  }, [dispatch]);

  const populate = () => {
    if (files && files instanceof Array) {
      return files.map((file, k) => {
        return (
          <Draggable
            key={`file-${file.title}-${k}`}
            draggableId={`file-${file.title}-${k}`}
            index={k}
          >
            {(provided, snapshot) => (
              <ListItem
                button
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <ListItemIcon>
                  <Avatar src={makeThumbnail(file)} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="caption">{makeTitle(file)}</Typography>
                </ListItemText>
                <ListItemSecondaryAction
                  style={isDragging ? { display: 'none' } : {}}
                >
                  <IconButton onClick={() => handleShowItem(file)}>
                    <InfoOutlined />
                  </IconButton>
                  <IconButton onClick={() => handleUpdateItem(file)}>
                    <UpdateOutlined/>
                  </IconButton>
                  <IconButton onClick={() => handleDeleteItem(file.linkID)}>
                    <DeleteForeverOutlined />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </Draggable>
        );
      });
    }
  };
  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}
        onBeforeCapture={() => setIsDragging(true)}
      >
        <Grid container justify="flex-start" spacing={2}>
          <Droppable droppableId="file-list-droppable">
            {(provided, snapshot) => {
              return (
                <RootRef rootRef={provided.innerRef}>
                  <Grid md={12}>
                    <List>
                      <ListItem button onClick={() => setNewFile(true)}>
                        <ListItemText>Add Attachment</ListItemText>
                        <ListItemIcon>
                          <AddCircle />
                        </ListItemIcon>
                      </ListItem>
                      {populate()}
                    </List>
                  </Grid>
                </RootRef>
              );
            }}
          </Droppable>
        </Grid>
      </DragDropContext>

      {/* View Details Dialog  */}
      <Dialog fullWidth open={!!item} onClose={() => setItem(null)}>
        <DialogTitle>{makeTitle(item)}</DialogTitle>
        <DialogContent>
          <List>{makeContent(item)}</List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setItem(null)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* New File Dialog */}
      {/* */}
      <PageFileNew
        open={newFile}
        setOpen={setNewFile}
        type={type}
        isTable={isTable}
        page={page}
        current={current}
      />

      {/* {makeUpdateAttachment(open, setOpen, updateItem, page.id)} */}
      {/* Update File Details */}
          
      <PageFileUpdate open={open} setOpen={setOpen} attData={updateItem} type={type} pageId={page.id}/>
    </>
  );
}
