import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPageList,
  setCurrentPage,
  PageListSelector,
  PageTypesSelector,
  getPageTypes,
  currentPageSelector
} from './PageSlice';
import PageTable from './PageTable';
import { Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { deletePage } from 'src/api/pages-api/v1';
import AlertDialog from './../../components/common/alertDialog';

export default function PageListView(props) {
  const { history } = props;
  const dispatch = useDispatch();
  const pageList = useSelector(PageListSelector);
  const types = useSelector(PageTypesSelector);
  const currPage = useSelector(currentPageSelector);
  const [breadcrumbs, setBreadcrumbs] = useState([null]);
  const [title, setTitle] = useState(<p>Pages</p>);

  React.useEffect(() => {
    dispatch(getPageList());
    dispatch(getPageTypes());
  }, []);

  // Create Breadcrumbs for Pages
  const handleBreadCrumbClick = (index, id) => {
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
    dispatch(getPageList(id));
  };
  React.useEffect(() => {
    let breadcrumbsAsTitle = breadcrumbs.map((page, k) => {
      if (page) {
        return (
          <span key={`bc-${k}`}>
            <button
              style={{
                backgroundColor: 'none',
                border: 'none',
                cursor: 'pointer'
              }}
              onClick={() => handleBreadCrumbClick(k, page.id)}
            >
              {' '}
              {page.title}
            </button>{' '}
            /{' '}
          </span>
        );
      } else {
        return (
          <span key={`bc-${k}`}>
            <button
              style={{
                backgroundColor: 'none',
                border: 'none',
                cursor: 'pointer'
              }}
              onClick={() => handleBreadCrumbClick(k, 'root')}
            >
              Site Pages{' '}
            </button>{' '}
            /{' '}
          </span>
        );
      }
    });
    setTitle(breadcrumbsAsTitle);
  }, [breadcrumbs]);

  const viewEditor = (id, index) => {
    history.push('/pages/' + id);
  };

  const handleShowChildren = (parent) => {
    let page = pageList.find((p) => p.id == parent);
    if (page) {
      setBreadcrumbs([...breadcrumbs, { id: page.id, title: page.title }]);
      dispatch(getPageList(parent));
    }
  };

  const [alertPrompt, setalertPrompt] = useState(false);
  const [deleteId, setdeleteId] = useState(null);
  const [success, setsuccess] = useState(false);

  const handleDeletePage = () => {
    let res = deletePage(deleteId);
    res.then((e) => {
        dispatch(getPageList());
        dispatch(getPageTypes());
        setsuccess(true);
    });
  };

  const setOpen = (e) => {
    setalertPrompt(e);
  };

  const handleOpenAlert = (id) => {
    console.log(id, 'setOpen');
    setdeleteId(id);
    setalertPrompt(true);
  };
  const handleClosesuccess = () =>{
    setsuccess(false);
  }

  return (
    <>
      <PageTable
        items={pageList}
        types={types}
        viewEditor={viewEditor}
        handleShowChildren={handleShowChildren}
        handleDeletePage={handleOpenAlert}
        title={title}
      />
      <AlertDialog
        handleAgree={handleDeletePage}
        open={alertPrompt}
        setOpen={setOpen}
        content="Warning: Page deleting will delete all sections and page attachments. You will not be able to recover any content!"
        title="Are you sure you want to delete ?"
      />
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClosesuccess}>
        <Alert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={handleClosesuccess}
        >
          Site Page was successfully deleted !
        </Alert>
      </Snackbar>
    </>
  );
}
