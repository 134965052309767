import React, { Component } from 'react';
import { Button } from '@material-ui/core';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const IndicatorComponent = ({object,onClick}) => {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();
  

  return (
    <Grid item>
      <Button style={{minWidth: '310px'}} variant="contained" color={"primary"} onClick={()=>onClick(object)}>
        {object.title}
      </Button>
    </Grid>
  );
};

export default IndicatorComponent;
