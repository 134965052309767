import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import AdminMaterialTextField from '../../components/common/AdminMaterialTextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';


export default function(props) {

  const { open, onCancel, onSubmit } = props;

  return (
    <Dialog open={open} onClose={onCancel} fullWidth maxWidth="lg">
      <Formik
        initialValues={{
          password: '',
          confirm: ''
        }}
        validationSchema={
          Yup.object().shape({
            password: Yup.string().max(255).min(8).required('password is required'),
            confirm: Yup.string().required().test('passwords-match', 'Passwords must match ya fool', function(value) {
              return this.parent.password === value;
            })
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await onSubmit(values);
          } catch (e) {
            console.log(e);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => {
          const formProps = {
            handleBlur,
            errors,
            handleChange,
            touched,
            values
          };
          return (
            <Form>
              <DialogTitle>Edit Profile</DialogTitle>
              <DialogContent>
                <AdminMaterialTextField fieldName="password" label="Password" fullWidth margin="normal"
                                        variant="outlined" type="password" {...formProps} />
                <AdminMaterialTextField fieldName="confirm" label="Confirm Password" fullWidth margin="normal"
                                        variant="outlined" type="password" {...formProps} />
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  Cancel
                </Button>
                <Button disabled={isSubmitting} onClick={handleSubmit} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );

}
