import {createAsyncThunk,createSlice} from '@reduxjs/toolkit';
import api from '../../api/posts-api'

const init = {
    loading: false,
    hasError: false,
    sliders: []
}


export const getAllSliders = createAsyncThunk(
    'sliders/getAllSliders',
    async () => {
        try {
            const resp = await api.getAllSliders()
            return resp;
        } catch (error) {
        }
    }
)

const heroSlidersSlice = createSlice({
    name:'heroSlidersSlice',
    initialState:init,
    extraReducers:{
        [getAllSliders.fulfilled] : (state, {payload}) => {
            state.sliders = payload;
        }
    }
})

// selectors
export const slidersSelector = store => {
    return store.sliders.sliders
}


export default heroSlidersSlice.reducer;